import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IoChevronUpOutline } from "react-icons/io5";

import {
  Container,
  TextCom,
  NewArrivalSlider,
  ProductCard,
  PaginationWrap,
  ProductSideBarFilter,
  InputCom,
  ButtonCom,
  ProductCardMap,
  BreadcrumbCom,
} from "components";
import { useHandleOther, useProduct, useHandleProduct, useOther } from "hook";
import { StyledSearchList } from "theme";
import Icon from "asset/icon/pladco";

type SearchListProps = {};

export const SearchList: React.FC<SearchListProps> = (props) => {
  let { translate, langStore } = useHandleOther();
  const { getMetaData_data } = useOther();
  let navigate = useNavigate();
  let { getProduct_data, ProductAction, dispatch } = useProduct();
  let param = useParams();
  let langCode = langStore?.code;
  const location = useLocation();

  let [productName, setProductName] = useState("");
  let [search_active, setSearch_active] = useState(false);
  const [current, setCurrent] = useState(1);
  const { sort_by, sorting, setSorting } = useHandleProduct();

  useEffect(() => {
    let req_obj = {
      lang: langCode,
      page_number: current,
      per_page: 12,
      keywords: param?.keyword,
      sort_by: sorting,
    };

    dispatch(ProductAction.getProduct(req_obj));
  }, [param?.keyword, current, langCode, sorting]);

  const handleSearch = (e: any) => {
    productName !== "" && navigate(`/search/${encodeURI(productName)}`);
    setSearch_active(!search_active);
  };

  const onChangePaginate = (pagenumber: any) => {
    setCurrent(pagenumber);
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, [current]);

  const handleSelectChange = (e: any) => {
    setSorting(e.target.value);
  };

  let breadcrumb_data = [
    {
      name: translate("home", "Home"),
      route: "/",
    },
    {
      name: translate(param?.keyword, param?.keyword),
      route: "#",
    },
  ];

  return (
    <>
      <BreadcrumbCom
        data={breadcrumb_data}
        isContainer={true}
        bgColor="primary_light3"
      />
      <StyledSearchList>
        <Container className="container product-search">
          {getProduct_data?.searching ? (
            <div
              className="d-flex gap-3 flex-column justify-content-center align-items-center"
              style={{ minHeight: "50vh" }}
            >
              <img src={Icon.SearchIconLarge} alt="search-icon" />
              <TextCom size="xxl" weight="xl" color="paragraph">
                {translate("searching-product", "Searching Product ...")}
              </TextCom>
            </div>
          ) : getProduct_data?.product_list?.length > 0 ? (
            <>
              <div className="search-result-con">
                <div className="d-flex title-con justify-content-between">
                  <TextCom size="xxl" weight="xl">
                    {translate("search-result-for", "Search Result for")} "
                    {translate(param?.keyword, param?.keyword)}"
                  </TextCom>
                  <div className="sort-by d-flex align-items-center">
                    <InputCom
                      isValidate={false}
                      input_as="select"
                      onChange={(e: any) => handleSelectChange(e)}
                      className="select-input"
                    >
                      <option value="">
                        {translate("sort-by", "Sort by")}
                      </option>
                      {sort_by?.map((item) => {
                        return (
                          <option value={item?.value}>{item?.name}</option>
                        );
                      })}
                    </InputCom>
                  </div>
                </div>
                <TextCom color="border">
                  Items : {getProduct_data?.total_products || "0"}
                </TextCom>
                <div className="product-con">
                  <div className="row">
                    {getProduct_data && (
                      <ProductCardMap
                        data={getProduct_data?.product_list}
                        className="col-lg-3 col-6"
                      />
                    )}
                  </div>
                </div>
                <div className="pagination-con">
                  <PaginationWrap
                    onChange={onChangePaginate}
                    current={current}
                    total={getProduct_data?.total_products}
                    defaultPageSize={12}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <TextCom size="xxl" weight="xl">
                {translate("search-result-for", "Search Result for")} "
                {translate(param?.keyword, param?.keyword)}"
              </TextCom>
              <TextCom color="border">
                {translate("items", "Items")} :{" "}
                {getProduct_data?.total_products || "0"}
              </TextCom>
              <div className="no-result-con">
                <img src={Icon.SearchIconLarge} alt="search-icon" />
                <TextCom size="xxxl" weight="xl" className="title">
                  {translate("no-result", "Search No Result")}
                </TextCom>
                {getMetaData_data && (
                  <TextCom className="desc">
                    {getMetaData_data["no-search-result"]?.text_one}
                  </TextCom>
                )}
              </div>
            </>
          )}

          {/* {location?.state && location?.state?.search === false ? (
          ) : (
            <TextCom size="xxl" weight="xl">
              {translate("searching-product", "Searching Product ...")} "
              {translate(param?.keyword, param?.keyword)}"
              
            </TextCom>

          )
          } */}
        </Container>
      </StyledSearchList>
    </>
  );
};
