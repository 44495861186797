import React, { useEffect } from "react";
import { configKey } from "../../controller/constant/config";
import { Container, TextCom } from "components";
import Icon from "asset/icon/pladco";
import { useHandleOther } from "hook";

export const NotFoundPage = () => {
  const { translate } = useHandleOther();
  if (window.location.pathname.toLowerCase() === "/admin") {
    window.location.href = configKey.apiUrl;
    return null;
  }
  return (
    <Container
      className="container not-found-page d-flex flex-column justify-content-center align-items-center"
      style={{ minHeight: "50vh", paddingBlock: "40px" }}
    >
      <img src={Icon.notfound} alt="" className="img-fluid" />
      <TextCom weight="lg" size="xxl">
        {translate("page-not-found", "Oops! That page can’t be found.")}
      </TextCom>
    </Container>
  );
};
