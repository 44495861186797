import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { TbCrown } from 'react-icons/tb'
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai'
import { BsExclamationCircle } from 'react-icons/bs'

import { useHandleOther, useAuth, useProduct, useCart, useOther } from 'hook'
import { Container, TextCom, CardCom, TableCom, ButtonCom, ModalCom, QtyButtonCom, PaginationWrap, InputCom } from 'components'
import { StyledGiftProduct } from 'theme'

type giftProductProps = {
  props?: any
}

export const GiftProduct: React.FC<giftProductProps> = props => {
  const { translate, langStore } = useHandleOther()
  const { getMetaData_data } = useOther()
  const [isModal, setIsModal] = useState(false)
  const navigate = useNavigate()
  const { getProfile_data } = useAuth()
  const { CartAction, getCart_data, createCart_data } = useCart()
  const { ProductAction, getGiftProduct_data, dispatch, setGiftQty } = useProduct()
  // const [page_no, setPage] = useState(1)
  const langCode = langStore?.code

  // let per_page = 12

  useEffect(() => {
    dispatch(ProductAction.getGiftProduct({ page_no: 1, per_page: 100, lang: langCode }))
  }, [langCode])

  const columns = [
    {
      key: 'name',
      width: 400,
      textAlign: 'left',
      render: (x: any, y: any) => (x?.quantity && x?.quantity > 0 ? <TextCom>{x?.name}</TextCom> : ''),
    },
    {
      key: 'amount',
      width: 300,
      textAlign: 'center',
      render: (x: any, y: any) => (x?.quantity && x?.quantity > 0 ? <TextCom>{x?.quantity < x?.stock ? x?.quantity : x?.stock}</TextCom> : ''),
    },
    {
      key: 'point_charge',
      width: 300,
      textAlign: 'center',
      render: (x: any, y: any) =>
        x?.quantity && x?.quantity > 0 ? (
          <TextCom textAlign="right">
            {x?.quantity * x?.point_value} {translate('points', 'Points')}
          </TextCom>
        ) : (
          ''
        ),
    },
  ]

  let total = 0
  getGiftProduct_data?.gift_item?.map((item: any) => {
    if (item?.quantity) {
      total += item?.quantity * item?.point_value
    }
  })

  let gift_lines: any = []
  if (getGiftProduct_data) {
    getGiftProduct_data?.gift_item?.map((item: any) => {
      if (item?.quantity && item?.quantity > 0) {
        gift_lines.push({
          gift_id: item?.id,
          gift_qty: item?.quantity,
        })
      }
    })
  }

  const handleClaim = async (from?: string) => {
    let pos_data: any = {
      order_id: createCart_data?.order_id,
    }
    if (from === 'claim') {
      pos_data.gift_lines = gift_lines
      await dispatch(CartAction.updateGift(pos_data))

    } else {
      if (getCart_data?.gifts.length > 0) {
        pos_data.gift_lines = []
        await dispatch(CartAction.updateGift(pos_data))
      }
    }
    await navigate('/order/checkout')
  }

  // const onChangePaginate = (pagenumber: any) => {
  //   setPage(pagenumber)
  // }

  const handleQty = (key: string, id: any, value?: any) => {
    let checkGiftStock = getGiftProduct_data?.gift_item[id]?.quantity || 0
    let num = checkGiftStock
    if (key === 'add') {
      if (total + getGiftProduct_data?.gift_item[id]?.point_value <= getProfile_data?.point + getCart_data?.discount_point) {
        num += 1
        if (num <= getGiftProduct_data?.gift_item[id]?.stock) dispatch(ProductAction.setGiftQty({ id, quantity: num, profile_point: getProfile_data?.point }))
      } else {
        setIsModal(true)
      }
    } else if (key === 'sub') {
      if ((checkGiftStock || 0) > 0) {
        num -= 1
        dispatch(ProductAction.setGiftQty({ id, quantity: num, profile_point: getProfile_data?.point }))
      }
    } else {
      num = Number(value)
      if (total + getGiftProduct_data?.gift_item[id].point_value * num <= getProfile_data?.point + getCart_data?.discount_point) {
        dispatch(ProductAction.setGiftQty({ id, quantity: num, profile_point: getProfile_data?.point }))
      } else {
        setIsModal(true)
        dispatch(ProductAction.setGiftQty({ id, quantity: 0, profile_point: getProfile_data?.point }))
      }
    }
  }

  return (
    <StyledGiftProduct>
      <Container>
        <Container className="container-fluid gift-product">
          <div className="d-flex justify-content-between mb-4 head-con">
            <div className="text">
              <TextCom size="xxxl" weight="xl">
                {translate('gift-product', 'Gift Product')}
              </TextCom>
              {getMetaData_data && getMetaData_data['gift-info'] && <TextCom>{getMetaData_data['gift-info'].text_one}</TextCom>}
            </div>
            <div className="point">
              <TbCrown size={30} color="#F27043" fill="#F27043" />
              <TextCom size="xl" weight="lg" className="title">
                {getProfile_data?.point + (getCart_data?.discount_point || 0)}
              </TextCom>
              <TextCom color="secondary">{translate('point', 'Point')}</TextCom>
            </div>
          </div>
          <Container as="section">
            <div className="row">
              {getGiftProduct_data?.gift_item?.map((item: any, id: number) => {
                return (
                  <div className="cu-card-width" key={id}>
                    <CardCom className="gift-card d-flex">
                      <img src={item.image} alt="" />
                      <TextCom as="h1" textAlign="center" size="lg" weight="xl">
                        {item.name}
                      </TextCom>
                      <TextCom as="h2" textAlign="center" size="xxxl">
                        {item.point_value} {translate('points', 'Points')}
                      </TextCom>
                      {(item.stock < 10 || item.stock === item.quantity || item.quantity > item.stock) && (
                        <TextCom as="em" textAlign="center">
                          {langCode === 'my' ? `${item.stock} ခုသာကျန်သည်` : `Only ${item.stock} left`}
                        </TextCom>
                      )}
                      <div className={`count ${item?.point_value > getProfile_data?.point ? 'disable' : ''}`}>
                        <div className={`disable-layer ${item?.point_value > getProfile_data?.point ? 'active' : ''}`}></div>

                        <div className="d-flex justify-content-center align-items-center qty-com">
                          <div onClick={() => handleQty('sub', id)} style={{ cursor: 'pointer' }}>
                            <AiOutlineMinus />
                          </div>
                          <InputCom className="count-input" value={item?.quantity || 0} onChange={(e: any) => handleQty('input', id, e.target.value)} isValidate={false} />
                          <div onClick={() => handleQty('add', id)} style={{ cursor: 'pointer' }}>
                            <AiOutlinePlus />
                          </div>
                        </div>
                      </div>
                    </CardCom>
                  </div>
                )
              })}
            </div>
          </Container>
          {/* <div className='pagination-con'>
            <PaginationWrap onChange={onChangePaginate} current={page_no} total={getGiftProduct_data?.total_count} defaultPageSize={per_page} />
          </div> */}
          <Container as="section" className="confirm-table">
            {total > 0 && (
              <div className="form">
                <TableCom dataSource={getGiftProduct_data?.gift_item} columns={columns} />
                <hr />
                <div className="d-flex justify-content-between align-items-center">
                  <TextCom weight="xl">{translate('total-redeem-points', 'Total Redeem Points')}</TextCom>
                  <TextCom weight="xl">
                    {total} {translate('points', 'Points')}
                  </TextCom>
                </div>
              </div>
            )}
            <div className="d-flex align-items-center w-100 mt-3">
              <ButtonCom btnBorderRadius="xxxs" bgColor="light" text={translate('i-wont-claim-this-time', 'I Wont Claim This Time')} onClick={() => handleClaim('cancel')} />
              <ButtonCom btnBorderRadius="xxxs" bgColor="dark" color="light" text={translate('claim', 'Claim')} onClick={() => handleClaim('claim')} disabled={total === 0} />
            </div>
          </Container>

          <ModalCom isModal={isModal} handleIsModal={setIsModal}>
            <div className="inner_modal_con d-flex flex-column justify-content-center align-items-center gap-3">
              <BsExclamationCircle size={40} />
              <TextCom className="desc_txt mb-3">{translate(`you-don't-have-enough-points`, `You don't have enough points`)}</TextCom>
              <div className="modal_btn_con d-flex justify-content-center">
                <ButtonCom bgColor="dark" color="light" btnBorderRadius="xxxs" text={translate('ok', 'OK')} onClick={() => setIsModal(false)} />
              </div>
            </div>
          </ModalCom>
        </Container>
      </Container>
    </StyledGiftProduct>
  )
}
