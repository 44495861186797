const setAuth = (data: any) => {
  localStorage.setItem('auth_store', JSON.stringify(data))
}

const getAuth = () => {
  let tmp: any = localStorage.getItem('auth_store')
  return JSON.parse(tmp)
}

const removeAuth = () => {
  localStorage.removeItem('auth_store')
  localStorage.removeItem('recent_view')
  // localStorage.clear()
}

const setRememberMe = (data: any) => {
  localStorage.setItem('remember_me', JSON.stringify(data))
}

const getRememberMe = () => {
  let tmp: any = localStorage.getItem('remember_me')
  return JSON.parse(tmp)
}

export default {
  setAuth,
  getAuth,
  removeAuth,
  setRememberMe,
  getRememberMe
}