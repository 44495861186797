export const configKey: any = {
  // url develop
  // apiUrl: 'http://http://54.179.160.96:8023/',
  // apiUrl: 'http://54.179.160.96:8026/',
  apiUrl: "http://demo-admin.innovixcommerce.com/",
  // apiUrl: 'http://admin-uat.chainpromm.com/',

  // apiUrl: 'http://admin-uat.chainproshop.com/',
  // apiUrl: 'http://std-api-v14.innovixcommerce.com/',
  // apiUrl: 'http://std-v14-co.innovixcommerce.com/',
  // apiUrl: 'localhost:${}'
  // url production
  // apiUrl: 'http://ok-dev-admin.innovixcommerce.com/',

  facebookAppId: "2755458538036395",
  googleclientId:
    "1017036782317-ei6bhobvnuq7alvtf98844ipp07m7fva.apps.googleusercontent.com",
  googleMapKey: "AIzaSyAC4zZkHZYUto-qvsVig7XvTAdz9qb2wNM",
  // googleMapKey: 'AIzaSyC5i0mt34lAKRc2J59PlC-M9T6TPyk817g', // mind

  onesignalAppIdKey: "ed5b95cc-9902-4943-8316-f18016315d49",
  onesignalRestApiKey: "ZDM1ZGYxZWYtZWMxMy00NGUyLWFlYzMtNzNlOWU3NDQ5YzQz",

  webClientId:
    "747520609251-ru039pff1gfhln347vk8fsd9hlls51cm.apps.googleusercontent.com",

  db: "acs_server_testing",
};
