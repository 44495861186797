import styled from "styled-components";

export const StyledDetail = styled.div`
  padding-bottom: 50px;
  @media (max-width: 991px) {
    padding-bottom: 10px;
  }
  .viber-con {
    text-decoration: none;
    .wrap {
      margin-bottom: 15px;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      padding: 10px;
      border-radius: 5px;
      cursor: pointer;
      img {
        width: 20%;
        /* height: fit-content; */
        @media (max-width: 991px) {
          max-width: 50px;
        }
        @media (max-width: 600px) {
          max-width: 30px;
        }
      }
      background: ${(props) => props?.theme?.color?.card?.viber || "#ddd"};
      @media (max-width: 991px) {
        padding: 7px;
        margin-top: 20px;
      }
    }
  }

  .deli-info {
    padding: 15px 10px;
    background: ${(props) =>
      props?.theme?.color?.card?.primary_light3 || "#fff"};
    border-radius: 5px;
    margin-bottom: 30px;
    gap: 0.5rem;
    border: 1px solid #6476f14f;
  }

  .no-item {
    position: relative;
    .disable-layer {
      background: ${(props) => props?.theme?.color?.card?.background || "#ddd"};
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
      opacity: 0.5;
    }
    .out-of-stock {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 10px;
      width: 120px;
      height: 120px;
      border-radius: 120px;
      background: ${(props) => props?.theme?.color?.typo?.gray};
      z-index: 3;
      text-transform: uppercase;
      padding-inline: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      @media (max-width: 1600px) {
        font-size: ${(props) => props?.theme?.fontSize?.sm}px;
        width: 100px;
        height: 100px;
        line-height: 22px;
        padding-inline: 8px;
      }
      @media (max-width: 768px) {
        font-size: ${(props) => props?.theme?.fontSize?.xs}px;
        line-height: 22px;
        width: 80px;
        height: 80px;
      }
    }
  }
  .title {
    margin-bottom: 20px;
    border-bottom: 1px solid
      ${(props) => props?.theme?.color?.typo?.line || "#ddd"};
    p {
      border-bottom: 2px solid ${(props) => props?.theme?.color?.typo?.primary};
      padding-bottom: 15px;
      width: fit-content;
      margin-bottom: -1px;
    }
  }
  margin-top: -22px;
  padding-top: 20px;
  background: ${(props) => props?.theme?.color?.typo?.light || "#eee"};
  .product-detail-wrap {
    background: ${(props) => props?.theme?.color?.typo?.light || "#fff"};
    border-radius: 5px;
    padding: 20px;
    padding-top: 2px;
    box-shadow: 0px 1px 10px #00000012;
  }
  .container {
    background: transparent;
  }
  .cu-card-width {
    width: calc(100% / 5);
    margin-bottom: 33px;
    @media (max-width: 991px) {
      width: calc(100% / 2);
    }
  }

  .qty-btn-wrap {
    .center {
      .qty-input {
        position: unset;
      }
    }
  }

  .wishlist {
    svg {
      cursor: pointer;
    }
    &.active {
      svg {
        fill: ${(props) => props?.theme?.color?.typo?.dark};
      }
    }
  }

  .our-support {
    border: 1.5px dotted ${(props) => props?.theme?.color?.typo?.line};
    border-radius: 5px;
    background: ${(props) => props?.theme?.color?.typo?.bg1};
    padding: 20px;
    @media (max-width: 991px) {
      margin-bottom: 30px;
      padding-inline: 15px;
    }
  }
  .card-con {
    margin-top: 20px;
    border-radius: 10px;
    border: none;
    padding: 20px;
    padding-top: 30px;
    .title {
      p {
        text-transform: uppercase;
      }
    }
    @media (max-width: 768px) {
      &.desc {
        padding-block: 0;
      }
    }
    &.desc {
      padding-inline: 0;
    }
  }
  .collapse-expand-icon {
    display: none;
  }
  .how-to-use {
    /* .tab-head{
    border-bottom: none;
  } */

    @media (max-width: 991px) {
      margin-bottom: 20px;
    }
    @media (max-width: 768px) {
      margin-top: 20px;
    }
    .collapse-con {
      margin-bottom: 30px;
      .collapse-item {
        border-bottom: 1px solid
          ${(props) => props?.theme?.color?.typo?.line || "#d9d9d9"};
      }
      .collapse-header-wrap {
        padding-block: 15px;
      }
      .collapse-content {
        /* padding-top: 0; */
        p {
          margin-bottom: 10px;
        }
      }
    }
  }

  .promo-wrap {
    img {
      width: 20%;
      /* height: fit-content; */
    }
    /* @media(max-width: ) */
  }

  .video-con {
    margin-top: 30px;
    max-height: fit-content;
    position: relative;
    cursor: pointer;
    margin-bottom: 50px;
    @media (max-width: 768px) {
      margin-bottom: 0;
    }
    iframe {
      width: 100%;
      height: 40vh;
      @media (max-width: 991px) {
        width: 100%;
        height: 250px;
      }
    }
    video {
      /* width: 80%; */
      height: auto;
      margin-bottom: 0;
      display: block;
      object-fit: cover;
      @media (max-width: 991px) {
        width: 100%;
      }
    }
    .poster {
      width: 80%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.9;
    }
    .play-icon {
      width: 70px;
      height: 70px;
      position: absolute;
      top: calc(50% - 35px);
      left: calc(50% - 35px);
      opacity: 90%;
      @media (max-width: 768px) {
        width: 54px;
        height: 54px;
      }
    }
  }

  .slick-slide {
    padding: 10px;
  }

  .custom-button {
    .slick-track {
      /* display: flex !important; */
    }

    .slick-slide {
      /* height: inherit !important; */
      div {
        /* height: 100% !important; */
      }
    }
    .slick-slider {
      .prev,
      .next {
        svg {
          position: absolute;
          background: ${(props) => props?.theme?.color?.typo?.border};
          width: 30px;
          height: 30px;
          border-radius: 50%;
          padding: 3px;
          fill: ${(props) => props?.theme?.color?.typo?.light};
          top: 32%;
          opacity: 0.8;
        }
        &:hover {
          opacity: 1;
        }
        &.disabled {
          opacity: 0.3;
          cursor: default;
        }
      }
      &.slick-initialized .slick-slide {
        padding: 8px;
      }
    }
  }
  .card-con {
    .title {
      p {
        position: relative;
        border: none;
      }
      hr {
        margin: 0;
        height: 5px;
        width: 20px;
        opacity: 1;
        position: absolute;
        bottom: -4px;
        left: 35%;
        color: ${(props) => props?.theme?.color?.typo?.primary || "#333"};
      }
    }
  }
  .accessory-product {
    .recent-view {
      .gift-text {
        @media (max-width: 990px) {
          bottom: 13px;
          // height: 35px !important;
        }
        @media (max-width: 780px) {
          bottom: 1px;
          // height: 30px !important;
        }
      }
    }
    .card-landscape {
      padding: 0;
      margin-bottom: 18px;
      .main-skeleton {
        z-index: 100003;
      }
      /* border-radius: 5px;
    border-color: ${(props) => props?.theme?.color?.typo?.line2 || "#ddd"}; */
      /* img{
      max-width: 40%;
      height: fit-content;
      margin-right: 10px;
      @media(max-width: 991px){
        max-width: 25%;
      }
    }
    @media(max-width: 991px){
      margin-bottom: 0;
      gap: .5rem;
    } */
      .product-card {
        flex-direction: row;
        border-radius: unset !important;
        max-height: 265px;
        .description {
          .dis-text {
            font-size: 13px !important;
            span {
              font-size: 9px;
            }
          }
          .skeleton-wrap {
            @media (min-width: 800px) {
              .price {
                span {
                  font-size: 13px !important;
                }
              }
            }
          }
        }
        .image-promo {
          width: 40%;
          border-radius: 5px;
          margin: 0;
          max-height: 265px;
          img {
            border-radius: 5px;
            width: 100%;
          }
          .free-gift {
            img {
              width: 40px;
            }
          }
          .no-item {
            border-radius: 5px;
            p {
              width: 60px;
              height: 60px;
              font-size: 12px;
            }
          }
          .percent-dis {
            padding: 5px;
            width: auto;
            height: 25px;
            left: 5px;
            h4 {
              font-size: ${(props) => props?.theme?.fontSize?.xxs || 12}px;
            }
            p {
              font-size: 14px !important;
              span {
                font-size: 11px !important;
              }
            }
            @media (max-width: 500px) {
              p {
                font-size: 12px;
              }
            }
          }
          .gift-text-1 {
            height: fit-content;
            padding-block: 2px;
            p {
              font-size: ${(props) => props?.theme?.fontSize?.xxs || 12}px;
            }
            @media (min-width: 769px) and (max-width: 991px) {
              // height: 40px;
              bottom: 13px;
            }
          }
          .gift-main-con {
            height: auto;
            @media (min-width: 769px) and (max-width: 991px) {
              bottom: 40px;
            }
          }
          .gift-text {
            padding-block: 2px;
            h6 {
              line-height: 18px;
              font-size: ${(props) => props?.theme?.fontSize?.xxs || 12}px;
            }
            .free-gift {
              bottom: -1px;
            }
          }
        }
        .description {
          width: 60%;
          margin: 0;
          max-height: 265px;
          div {
            margin: 0 !important;
          }
          p {
            font-size: ${(props) => props?.theme?.fontSize?.md || 16}px;
            &.price {
              color: ${(props) =>
                props?.theme?.color?.typo?.primary_light2 || "#333"};
            }
            &.dis-text {
              font-size: ${(props) => props?.theme?.fontSize?.sm || 14}px;
            }
          }
        }
      }
    }
    @media (max-width: 991px) {
      margin-top: 30px;
    }

    @media (max-width: 991px) {
      .viber-con,
      .deli-info {
        /* display: none!important; */
      }
    }
  }
  .related-con {
    background: white !important;
    border-radius: 8px;
    /* padding: 20px; */
    @media (min-width: 992px) {
      padding: 20px 30px;
    }
    .custom-button {
      padding-inline: 0;
    }
    .slick-slider {
      .prev {
        left: -30px;
      }
      .next {
        right: -30px;
      }
    }
    .product-card {
      .description {
        margin-bottom: 0;
      }
    }
  }
`;
