import React from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { useState } from "react";
import {
  StyledAddToCartBtnWrap,
  StyledAddToCartBtnLeft,
  StyledAddToCartBtnCenter,
  StyledAddToCartBtnRight,
} from "theme";
import { InputCom } from "../form";
import { useProduct, useCart } from "hook"

type QtyButtonComProps = {
  qty?: any;
  handleQtyFun?: any;
  setqty?: any;
  rightBtnRender?: any;
  leftBtnRender?: any;
  rightBtnStyle?: any;
  leftBtnStyle?: any;
  bgcolor?: any;
  color?: any;
  type?: any;
  w_index?: any;
  inputwidth?: any;
  btnbordercolor?: any;
  disabled?: any;
  className?: any;
  uid?: any;
};

export const QtyButtonCom: React.FC<QtyButtonComProps> = ({
  qty,
  handleQtyFun,
  setqty,
  className,
  rightBtnRender,
  leftBtnRender,
  rightBtnStyle,
  leftBtnStyle,
  type,
  w_index,
  disabled,
  uid,
  ...props
}) => {
  // const {
  //   qty,
  //   setqty,
  //   className,
  //   rightBtnRender,
  //   leftBtnRender,
  //   rightBtnStyle,
  //   leftBtnStyle,
  //   disabled,
  // } = props;
  const { getProductById_data, getWishlist_data } = useProduct();
  const { getCart_data } = useCart();
  const handleQty = (key: string) => {
    if(type == "wishlist"){
      if (key === "add") {
        let num = parseInt(qty);
        num += getWishlist_data?.[w_index]?.quantity_control_step || 1;
        setqty(num);
      } else {
        if (parseInt(qty) > 1) {
          let num = parseInt(qty);
          num -= getWishlist_data?.[w_index]?.quantity_control_step || 1;
          if(num == 0){
            num = getWishlist_data?.[w_index]?.quantity_control_step || 1;
          }
          setqty(num);
        }
      }
    }
    else if(type == "cartlist"){
      if (key === "add") {
        let num = parseInt(qty);
        num += getCart_data?.order_lines[w_index]?.quantity_control_step || 1;
        setqty(num);
      } else {
        if (parseInt(qty) > 1) {
          let num = parseInt(qty);
          // console.log(getCart_data?.quantity_control_step)
          // console.log(num)
          num -= getCart_data?.order_lines[w_index]?.quantity_control_step || 1;
          if(num == 0){
            num = getCart_data?.order_lines[w_index]?.quantity_control_step || 1;
          }
          setqty(num);
        }
      }
    }
    else{
      if (key === "add") {
        let num = parseInt(qty);
        num += getProductById_data?.quantity_control_step || 1;
        setqty(num);
      } else {
        if (parseInt(qty) > 1) {
          let num = parseInt(qty);
          // console.log(getProductById_data?.quantity_control_step)
          // console.log(num)
          num -= getProductById_data?.quantity_control_step || 1;
          if(num == 0){
            num = getProductById_data?.quantity_control_step || 1;
          }
          setqty(num);
        }
      }
    }
  };

  const [disable_btn, setdisable_btn] = useState(disabled);

  return (
    <StyledAddToCartBtnWrap
      {...props}
      className={`qty-btn-wrap ${className}`}
      disable={disabled || false}
    >
      <StyledAddToCartBtnLeft {...props}>
        {rightBtnRender ? (
          <div
            className={leftBtnStyle || "left_btn_wrap"}
            onClick={() => disabled !== true && handleQty("sub")}
            {...props}
          >
            {rightBtnRender}
          </div>
        ) : (
          <div
            className={leftBtnStyle || "left_btn_wrap"}
            onClick={() => disabled !== true && handleQty("sub")}
            {...props}
          >
            <AiOutlineMinus />
          </div>
        )}
      </StyledAddToCartBtnLeft>

      <StyledAddToCartBtnCenter {...props} className="center">
        <InputCom
          className="qty-input"
          tabIndex={ 0 }
          onBlur={(e: any) => { 
            {
            handleQtyFun(+qty, uid, e) }
            setdisable_btn(true)
            setTimeout(() => {
            setdisable_btn(false)
            }, 0.1);
            }
          }
          value={qty}
          onChange={(e: any) => {
            setqty(e.target.value, e)
            // console.log(e)
            }
          }
          isValidate={false}
          {...props}
        />
      </StyledAddToCartBtnCenter>

      <StyledAddToCartBtnRight {...props}>
        {leftBtnRender ? (
          <div
            className={rightBtnStyle || "right_btn_wrap"}
            onClick={() => disabled !== true && disable_btn != true && handleQty("add")}
            {...props}
          >
            {leftBtnRender}
          </div>
        ) : (
          <div
            className={rightBtnStyle || "right_btn_wrap"}
            onClick={() => disabled !== true && disable_btn != true && handleQty("add")}
            {...props}
          >
            <AiOutlinePlus />
          </div>
        )}
      </StyledAddToCartBtnRight>
    </StyledAddToCartBtnWrap>
  );
};

QtyButtonCom.defaultProps = {
  bgcolor: "primary",
  color: "white",
  type: "full",
  inputwidth: 60,
  qty: 1,
};
