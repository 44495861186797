import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";

import { BsExclamationCircle, BsArrowLeft } from "react-icons/bs";
import { MdOutlineChevronLeft } from "react-icons/md";
import { BsFillPatchCheckFill } from "react-icons/bs";

import {
  useHandleOther,
  useCart,
  useHandleCart,
  useAuth,
  useOther,
  showToast,
} from "hook";

import { TextCom, ModalCom, ButtonCom, TableCom, CardCom } from "components";
import { joinSpace, moneyFormat } from "utils";
import { StyledPayment } from "theme";
import moment from "moment";

type paymentProps = {};

let current_user: any = null;

export const Payment: React.FC<paymentProps> = (props) => {
  const { translate, langStore } = useHandleOther();
  const langCode = langStore?.code;
  const {
    getShippingAddress_data,
    getShippingAddressByCode_data,
    getPaymentList_data,
    getCart_data,
    createCart_data,
    getOrder_data,
    dispatch,
    CartAction,
    selectShippingAddress_data,
    createShippingAddress_data,
    getShippingMethod,
    getShippingMethod_data,
    getShippingAddressById_data,
    payNow_data,
  } = useCart();
  const {
    handleDeleteShippingAddress,
    handlePayNow,
    handleSelectShipping,
    handleEditShippingAddress,
    handleCreateShippingAddress,
  } = useHandleCart();
  const { getContactUsPage_data } = useOther();
  const { getProfile_data, signIn_data } = useAuth();
  const [specialReq, setspecialReq] = useState("");
  const [paymentState, setPaymentState]: any = useState({});
  const [successModal, setSuceessModal]: any = useState(null);
  const [orderfail, setorderFail] = useState(false);
  const [paymentLoading, setPaymentLoading]: any = useState(false);
  const navigate = useNavigate();
  let location = useLocation();
  let deli_address = location?.state?.address;
  const [delimethod, setdeliMethod] = useState(null);
  const [selectedPayment, setSelectedPayment]: any = useState(null);
  const [userChange, setuserChange] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");

  const [order, setOrder] = useState({
    order_id: getCart_data?.sale_order_name,
    date: getCart_data?.create_Date,
  });
  // console.log(getCart_data)
  const columns = [
    {
      // title: "PRODUCT",
      key: "name",
      width: "500",
      render: (x: any, y: any) => (
        <TextCom
          style={
            x?.is_delivery_line === true && delimethod === null
              ? { display: "none" }
              : { display: "block" }
          }
        >
          {joinSpace(x.name)}
          <br />
          {x?.note}
        </TextCom>
      ),
      promorender: (x: any, y: any, key: any) =>
        x?.promotion_lines && (
          <TextCom className="mt-2">
            {x?.promotion_lines[0]?.reward_type === "discount"
              ? x?.promotion_lines[0]?.remark
              : x?.promotion_lines[0]?.name}
          </TextCom>
        ),
    },
    {
      // title: "QUANTITY",
      key: "quantity",
      textAlign: "center",
      width: "100",
      render: (x: any, y: any) =>
        x?.reward_type === false && (
          <TextCom
            textAlign="center"
            style={
              x?.is_delivery_line === true && delimethod === null
                ? { display: "none" }
                : { display: "block" }
            }
          >
            {!x?.is_delivery_line && x.quantity}
          </TextCom>
        ),
      promorender: (x: any, y: any, key: any) =>
        x?.promotion_lines && (
          <TextCom
            textAlign="center"
            className="mt-2"
            style={
              x?.promotion_lines[0]?.reward_type === "discount"
                ? { opacity: 0, visibility: "hidden" }
                : { opacity: 1, visibility: "visible" }
            }
          >
            {x?.promotion_lines[0]?.quantity}
          </TextCom>
        ),
    },
    {
      // title: "SUB TOTAL",
      key: "price_subtotal",
      textAlign: "right",
      width: "300",
      render: (x: any, y: any) => (
        <TextCom
          textAlign="right"
          weight="xl"
          style={
            x?.is_delivery_line === true && delimethod === null
              ? { display: "none" }
              : { display: "block" }
          }
        >
          {moneyFormat(x.price_subtotal, langCode)} {translate("ks", "Ks")}
        </TextCom>
      ),
      promorender: (x: any, y: any, key: any) =>
        x?.promotion_lines && (
          <TextCom textAlign="right" weight="xl" className="mt-2">
            {x?.promotion_lines[0]?.price_subtotal === 0 ? (
              <TextCom as="span" weight="lg" color="primary_light2">
                {translate("foc", "FOC")}
              </TextCom>
            ) : (
              `${x?.promotion_lines[0]?.price_subtotal} ${translate(
                "ks",
                "Ks"
              )}`
            )}
          </TextCom>
        ),
    },
  ];

  useEffect(() => {
    if (location?.state?.type === "pickup") {
      handleJoinShipping(createShippingAddress_data?.ID || deli_address?.id);
    }

    if (location?.state?.type === "delivery") {
      dispatch(
        CartAction.getShippingAddressById(
          !getProfile_data
            ? createShippingAddress_data?.ID
            : location?.state?.address?.id
        )
      );
      //need to call available shipping methods api
      dispatch(
        CartAction.getShippingMethod({
          add: location?.state?.address?.id
            ? location?.state?.address?.id
            : createShippingAddress_data?.ID,
          o_id: createCart_data?.order_id,
        })
      );
    }
  }, [createShippingAddress_data?.ID]);

  //join shipping and order and set selected shipping method in redux state
  useEffect(() => {
    if (location?.state?.type === "delivery" && delimethod !== null) {
      handleJoinShipping(
        deli_address?.id ? deli_address?.id : createShippingAddress_data?.ID,
        delimethod
      );
    } else {
      if (getShippingAddressById_data && delimethod !== null) {
        handleJoinShipping(getShippingAddressById_data[0]?.id, delimethod);
      }
    }
    if (getShippingAddressById_data && delimethod !== null) {
      dispatch(CartAction.setShippingMethod({ id: delimethod }));
    }
  }, [delimethod]);

  //get shipping methods of the selected address
  useEffect(() => {
    if (getShippingAddressById_data) {
      dispatch(
        CartAction.getShippingMethod({
          add: getShippingAddressById_data[0]?.id,
          o_id: createCart_data?.order_id,
        })
      );
    }
  }, [getShippingAddressById_data]);

  //remeber the selected shipping method when user is being selected an address
  useEffect(() => {
    if (
      getShippingAddressById_data &&
      getShippingAddressById_data?.selected_shipping_method
    ) {
      setdeliMethod(getShippingAddressById_data?.selected_shipping_method);
    } else {
      setdeliMethod(null);
    }
  }, [getShippingAddressById_data]);

  const handlePaynow = async () => {
    let obj = {
      payment_id: Number(selectedPayment?.id),
      request: specialReq,
    };
    let res = await handlePayNow(obj);
    setPaymentState(res?.payload);
    let redirect: any = document.getElementById("payment-form");
    redirect?.submit();
    setTimeout('document.forms["payment-form"].submit()', 500);
    if (res == "success") {
      setSuceessModal(true);
    } else if (res === "fail") {
      setorderFail(true);
    } else if (res?.payload) {
      setPaymentLoading(true);
    }
  };

  const handleJoinShipping = async (id: any, shipid?: any) => {
    let data: any = {
      id: id,
      code: location?.state?.type === "pickup" ? "pickup" : "",
    };
    if (location?.state?.type === "pickup") {
      data.is_pickup = true;
    }
    if (shipid) {
      data.shipping_method_id = shipid;
    }
    let res = await handleSelectShipping(data);

    if (res?.payload?.status == "fail") {
      seterrorMsg(res?.payload?.message);
      showToast({
        title: res?.payload?.message,
        titleStyle: { textAlign: "center" },
        placement: "bottom",
        alway: true,
      });
    } else {
      seterrorMsg("");
    }
    // console.log(res, "dandemo")
  };

  useEffect(() => {
    if (
      signIn_data?.user_id !== undefined &&
      current_user !== null &&
      current_user !== signIn_data?.user_id
    ) {
      navigate("/order/checkout");
    }
    current_user = signIn_data?.user_id;
  }, [signIn_data]);

  let mobile = [];
  if (deli_address?.mobile) {
    if (deli_address?.mobile?.includes(",")) {
      mobile = deli_address?.mobile?.split(",");
    } else {
      mobile.push(deli_address?.mobile);
    }
  }

  return (
    <>
      <StyledPayment className="container">
        <div className="row justify-content-between">
          {/* Payment con */}
          <div className="col-lg-6">
            <TextCom
              as="a"
              size="lg"
              className="d-flex align-items-center mb-2"
              href="/order/checkout"
              style={{ width: "max-content" }}
            >
              <MdOutlineChevronLeft size={24} />
              {translate("back-to-edit", "Back to edit")}
            </TextCom>
            <div className="payment-con">
              <TextCom size="xxl" weight="lg">
                {translate("shipping-address", "Shipping Address")}
                {/* {location?.state?.type === "pickup"
                  ? translate("pickup-address", "Pickup Address")
                  : translate("shipping-address", "Shipping Address")} */}
              </TextCom>
              <div className="address-con">
                {location?.state?.type !== "pickup" &&
                getShippingAddressById_data ? (
                  <>
                    <TextCom weight="lg" className="mb-2">
                      {getShippingAddressById_data[0]?.name}
                    </TextCom>
                    <div className="mb-1">
                      {getShippingAddressById_data[0]?.address1 && (
                        <>
                          <TextCom as="span" weight="lg">
                            {translate("address", "Address")} -{" "}
                          </TextCom>
                          {getShippingAddressById_data[0]?.address1}
                        </>
                      )}
                      {getShippingAddressById_data[0]?.township?.name
                        ? `, ${getShippingAddressById_data[0]?.township?.name}`
                        : ""}
                      {getShippingAddressById_data[0]?.state?.name
                        ? `, ${getShippingAddressById_data[0]?.state?.name}`
                        : ""}
                      {getShippingAddressById_data[0]?.country?.name
                        ? `, ${getShippingAddressById_data[0]?.country?.name}`
                        : ""}
                    </div>
                    {getShippingAddressById_data[0]?.mobile && (
                      <TextCom>
                        <TextCom as="span" weight="lg">
                          {translate("phone", "Phone")} -{" "}
                        </TextCom>
                        <TextCom
                          as="a"
                          href={`tel:${getShippingAddressById_data[0]?.mobile}`}
                          className="ph"
                          // color="primary_light2"
                        >
                          {getShippingAddressById_data[0]?.mobile}
                        </TextCom>
                        {/* {mobile &&
                          mobile?.map((ph: any, key: any) => (
                          ))} */}
                      </TextCom>
                    )}
                  </>
                ) : (
                  <>
                    <TextCom weight="lg" className="mb-2">
                      {deli_address?.name}
                    </TextCom>
                    <div className="mb-1">
                      {deli_address?.address1 && (
                        <>
                          <TextCom as="span" weight="lg">
                            {translate("address", "Address")} -{" "}
                          </TextCom>
                          {deli_address?.address1}
                        </>
                      )}
                      {deli_address?.township?.name
                        ? `, ${deli_address?.township?.name}`
                        : ""}
                      {deli_address?.state?.name
                        ? `, ${deli_address?.state?.name}`
                        : ""}
                      {deli_address?.country?.name
                        ? `${deli_address?.country?.name}`
                        : ""}
                    </div>
                    {deli_address?.mobile && (
                      <TextCom>
                        <TextCom as="span" weight="lg">
                          {translate("phone", "Phone")} -{" "}
                        </TextCom>
                        {mobile &&
                          mobile?.map((ph: any, key: any) => (
                            <TextCom
                              as="a"
                              href={`tel:${ph}`}
                              className="ph"
                              key={key}
                              // color="primary_light2"
                            >
                              {ph}
                            </TextCom>
                          ))}
                      </TextCom>
                    )}
                  </>
                )}
              </div>

              {(location?.state?.type === "delivery" ||
                (location?.state?.type !== "pickup" &&
                  getShippingAddressById_data)) &&
                getShippingMethod_data?.data &&
                getShippingMethod_data?.data?.length > 0 && (
                  <div className="deli-method-con">
                    <TextCom size="xxl" weight="lg">
                      {translate(
                        "choose-your-delivery-option",
                        "choose-your-delivery-option"
                      )}
                    </TextCom>
                    <div className="d-flex flex-wrap justify-content-between mt-2">
                      {getShippingMethod_data?.data
                        ?.slice(0)
                        ?.reverse()
                        .map((data: any, key: any) => (
                          <div
                            className="method d-flex gap-3"
                            style={{
                              width: "48%",
                              marginBottom: "20px",
                              borderColor:
                                (delimethod ||
                                  getShippingAddressById_data?.selected_shipping_method) ===
                                data?.shipping_method_id
                                  ? "#5E71EC"
                                  : "",
                            }}
                            onClick={() => {
                              setdeliMethod(data?.shipping_method_id);
                            }}
                            key={key}
                          >
                            <TextCom
                              as="input"
                              type="radio"
                              value=""
                              checked={
                                delimethod === data?.shipping_method_id
                                  ? true
                                  : false
                              }
                            />
                            <div>
                              <TextCom
                                size="lg"
                                weight="xl"
                                color="paragraph"
                                style={{ textTransform: "capitalize" }}
                              >
                                {data?.service_name}
                              </TextCom>
                              {/* <TextCom>{data?.method_name}</TextCom> */}
                              {data?.delivery_fee && (
                                <TextCom>
                                  <TextCom as="span" weight="lg">
                                    Fees:{" "}
                                  </TextCom>
                                  {data?.delivery_fee} {translate("ks", "Ks")}
                                </TextCom>
                              )}
                              {data?.description && (
                                <TextCom>{data?.description}</TextCom>
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )}

              <div className="payment-method-con">
                <TextCom size="xxl" weight="lg" className="mb-2">
                  {translate("payment", "Payment")}
                </TextCom>
                <div>
                  <div className="mb-4 radio-con">
                    <div
                      role="group"
                      aria-labelledby="my-radio-group"
                      className="payment-list"
                    >
                      {getPaymentList_data?.data &&
                        getPaymentList_data?.data?.map(
                          (item: any, key: any) => (
                            <CardCom
                              key={key}
                              className={`p-card`}
                              onClick={() => {
                                setSelectedPayment(item);
                              }}
                            >
                              <TextCom as="label">
                                <div
                                  style={{
                                    height: "100%",
                                    minWidth: "20px",
                                    maxWidth: "20px",
                                    width: "20px",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <TextCom
                                    as="input"
                                    type="radio"
                                    value={item.id}
                                    name="payment"
                                    checked={
                                      selectedPayment?.id === item?.id
                                        ? true
                                        : false
                                    }
                                    style={{
                                      width: "12px",
                                      height: "12px",
                                    }}
                                  />
                                </div>

                                {/* {item.name} */}
                                {item?.icons?.length > 0 ? (
                                  <div className="icons d-flex">
                                    {item?.icons?.map((icon: any) => (
                                      <img src={icon?.image} alt="icon" />
                                    ))}
                                    <div className="info">
                                      <TextCom weight="600">
                                        {item?.name}
                                      </TextCom>
                                      <TextCom weight="600"> </TextCom>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="icons d-flex">
                                    <img src={item?.image} alt="" />
                                    <div className="info">
                                      <TextCom weight="600">
                                        {item?.account_no}
                                      </TextCom>
                                      <TextCom weight="600">
                                        {item?.account_name || item?.name}
                                      </TextCom>
                                    </div>
                                  </div>
                                )}
                              </TextCom>
                            </CardCom>
                          )
                        )}
                    </div>
                  </div>
                  <TextCom size="xxl" weight="lg">
                    {translate("special-request", "Special Request")}
                  </TextCom>
                  <TextCom className="mb-2" color="paragraph">
                    (
                    {translate(
                      "please-let-us-know-if-you-want-to-request",
                      "Please let us know if you want to request"
                    )}
                    )
                  </TextCom>
                  <TextCom
                    name="address"
                    type="text"
                    as="textarea"
                    rows="4"
                    style={{ width: "100%" }}
                    onChange={(e?: any) => setspecialReq(e.target.value)}
                  />
                  {errorMsg != "" && (
                    <TextCom
                      className="mb-2"
                      color="danger"
                      style={{ fontStyle: "italic" }}
                    >
                      {errorMsg}
                    </TextCom>
                  )}
                  <div className="d-flex w-100 mb-3">
                    <ButtonCom
                      disabled={
                        location?.state?.type === "pickup"
                          ? selectedPayment === null
                            ? true
                            : false
                          : selectedPayment === null || (getShippingMethod_data && delimethod === null)
                          ? true
                          : errorMsg != ""
                          ? true
                          : false
                      }
                      type="submit"
                      className={`w-100 place-order-btn`}
                      btnBorderRadius="xxxs"
                      bgcolor="primary_light2"
                      onClick={() => handlePaynow()}
                    >
                      <TextCom color="light">
                        {translate("place-order", "PLACE ORDER")}
                      </TextCom>
                    </ButtonCom>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Order Summary */}
          <div className="col-lg-5">
            <div className="order-summary">
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th style={{ minWidth: "150px" }}>
                      <TextCom size="xxxl" weight="xl">
                        {translate("order-summary", "Order Summary")}
                      </TextCom>
                    </th>
                    <th style={{ width: "50px" }}></th>
                    <th style={{}}></th>
                  </tr>
                </thead>
                <tbody className="summary-table">
                  {getCart_data?.order_lines.length > 0 &&
                    [...getCart_data?.order_lines]
                      ?.sort((x: any, y: any) =>
                        x?.is_delivery_line === y?.is_delivery_line
                          ? -1
                          : x?.is_delivery_line == true
                          ? 1
                          : -1
                      )
                      ?.map((product: any, index: number) => (
                        <>
                          <tr key={index}>
                            <td className=" d-flex">{product.name}</td>
                            <td className="" style={{ paddingLeft: "10px" }}>
                              <p>{product.quantity}</p>
                            </td>
                            <td className=" text-end text-nowrap">
                              <p>
                                {moneyFormat(
                                  Math.ceil(product.price_subtotal),
                                  langCode
                                )}{" "}
                                {translate("ks", "Ks")}
                              </p>
                            </td>
                          </tr>
                          {product?.promotion_lines?.length > 0 &&
                            product?.promotion_lines?.map(
                              (p: any, index: number) => (
                                <tr key={p.name} style={{ fontSize: "14px" }}>
                                  <td className=" d-flex">{p.remark}</td>
                                  <td
                                    className=""
                                    style={{ paddingLeft: "10px" }}
                                  >
                                    <p>
                                      {p.reward_type === "product" &&
                                        `${p.quantity}`}
                                    </p>
                                  </td>
                                  <td className=" text-end">
                                    <TextCom
                                      color="primary_light2"
                                      textAlign="right"
                                      weight="md"
                                      style={{ marginBottom: "1rem" }}
                                    >
                                      {p.price_subtotal === 0
                                        ? `${translate("foc", "FOC")}`
                                        : `${moneyFormat(
                                            Math.ceil(p.price_subtotal),
                                            langCode
                                          )}` +
                                          ` ` +
                                          `${translate("ks", "Ks")}`}
                                    </TextCom>
                                  </td>
                                </tr>
                              )
                            )}
                          <br />
                        </>
                      ))}
                </tbody>
              </table>
              {/* <TableCom
                dataSource={getCart_data?.order_lines}
                columns={columns}
              /> */}
              <hr />
              {getCart_data?.tax && getCart_data?.tax !== 0 ? (
                <>
                  <div className="d-flex justify-content-between w-100">
                    <TextCom className="title">
                      {translate("sub-total", "Sub Total")}
                    </TextCom>
                    <TextCom weight="xl">
                      {moneyFormat(getCart_data?.untax_amount, langCode)}{" "}
                      {translate("ks", "Ks")}
                    </TextCom>
                  </div>
                  <div className="d-flex justify-content-between w-100 tax">
                    <TextCom className="title">
                      {translate("tax", "Tax")}
                    </TextCom>
                    <TextCom weight="xl">
                      {moneyFormat(getCart_data?.tax, langCode)}{" "}
                      {translate("ks", "Ks")}
                    </TextCom>
                  </div>
                </>
              ) : (
                <></>
              )}
              {getCart_data?.discount_percent ? (
                <>
                  <div className="d-flex justify-content-between mb-2 w-100 discount-amount pb-4 mt-2">
                    <TextCom className="title">
                      {translate("discount-amount", "Discount Amount")} (
                      {moneyFormat(getCart_data?.discount_percent, langCode)}%)
                    </TextCom>
                    <TextCom weight="xl">
                      -{moneyFormat(getCart_data?.discount_amount, langCode)}{" "}
                      {translate("ks", "Ks")}
                    </TextCom>
                  </div>
                </>
              ) : (
                <></>
              )}
              <div className="d-flex justify-content-between align-items-center mt-2">
                <TextCom size="xl" weight="lg" className="title">
                  {translate("total", "Total")}
                </TextCom>
                <TextCom size="xl" weight="lg" color="primary_light2">
                  {moneyFormat(getCart_data?.total, langCode)}{" "}
                  {translate("ks", "Ks")}
                </TextCom>
              </div>
            </div>
          </div>
        </div>
        <ModalCom isModal={successModal} handleIsModal={setSuceessModal}>
          <BsFillPatchCheckFill
            size={45}
            color="#5E71EC"
            className="d-flex justify-content-center align-items-center m-auto"
          />
          <TextCom weight="xl" size="xxxl" className="mb-2">
            {translate("successful", "Successful")}
          </TextCom>
          <TextCom className="mb-2">
            {translate(
              "your-orders-have-been-placed-successfully",
              "Your orders have been placed successfully!"
            )}
          </TextCom>
          <div className="order d-flex flex-column mb-4">
            {/* should be order id */}
            <TextCom weight="lg">
              {/* <TextCom as="span" weight="sm" color="border"> */}
              {translate("order-id", "Order ID")} : {/* </TextCom> */}
              {order?.order_id}
              {/* {moment(getOrder_data?.order?.filter((order: any) => order?.id === Number(orderid))).format('DD/MM/YYYY')} */}
            </TextCom>

            <TextCom weight="lg">
              {/* <TextCom as="span" weight="sm" color="border"> */}
              {translate("date", "Date")} : {/* </TextCom> */}
              {order?.date && moment(order?.date)?.format("DD/MM/YYYY")}
            </TextCom>
          </div>
          {/* <Link to="/"> */}
          <ButtonCom
            text={translate("continue-shopping", "Continue Shopping")}
            bgcolor="primary_light2"
            color="light"
            btnBorderRadius="xxxs"
            // style={{ width: "100%" }}
            onClick={() => {
              navigate("/");
            }}
          />
          {/* </Link> */}
        </ModalCom>
        <ModalCom isModal={orderfail} handleIsModal={setorderFail}>
          <TextCom
            // size="sm"
            weight="lg"
            className="d-flex flex-column justify-content-center align-items-center gap-3"
          >
            <BsExclamationCircle size={40} color="#F75759" />
            {translate(
              "your-order-was-not-success-please-contact-to-site-admin",
              "your-order-was-not-success-please-contact-to-site-admin"
            )}
            <div>
              <TextCom style={{ textAlign: "left" }}>
                <TextCom as="span" weight="lg">
                  {translate("order-no", "order-no")} :{" "}
                </TextCom>
                {getCart_data?.sale_order_name}
              </TextCom>
              {getContactUsPage_data && (
                <TextCom style={{ textAlign: "left" }}>
                  <TextCom as="span" weight="lg">
                    {translate("phone", "phone")} :{" "}
                    {getContactUsPage_data?.hotline}
                  </TextCom>
                </TextCom>
              )}
            </div>
            <div className="mt-4 d-flex justify-content-center align-items-center w-100 gap-3">
              <ButtonCom
                className="d-flex align-items-center justify-content-center"
                btnBorderRadius="xxxs"
                bgcolor="primary_light2"
                color="light"
                text={translate("cancel", "Cancel")}
                onClick={() => {
                  setorderFail(false);
                  navigate("/");
                }}
              />
            </div>
          </TextCom>
        </ModalCom>
      </StyledPayment>
      {paymentState?.payload && (
        <form id="payment-form" action={paymentState?.url} method="POST">
          <input
            type="hidden"
            name="merchant_id"
            value={paymentState?.payload?.merchant_id}
          />
          <input
            type="hidden"
            name="service_name"
            value={paymentState?.payload?.service_name}
          />
          <input
            type="hidden"
            name="email"
            value={paymentState?.payload?.email}
          />
          <input
            type="hidden"
            name="password"
            value={paymentState?.payload?.password}
          />
          <input
            type="hidden"
            name="amount"
            value={paymentState?.payload?.amount}
          />
          <input
            type="hidden"
            name="order_id"
            value={paymentState?.payload?.order_id}
          />
          <input
            type="hidden"
            name="paymentMethods"
            value={paymentState?.payload?.payment_method}
          />
          <input
            type="hidden"
            name="hashValue"
            id="hash"
            value={paymentState?.payload?.hashValue}
          />
        </form>
      )}
    </>
  );
};
