import React, { useEffect } from "react";
import { configKey } from "../../controller/constant/config";
import { Container, TextCom } from "components";
import Icon from "asset/icon/pladco";
import { useHandleOther } from "hook";

export const UnderConstruction = () => {
  const { translate } = useHandleOther();

  return (
    <div
      className="under-construction-page d-flex justify-content-center align-items-center"
      style={{ width: "100vw", height: "100vh", background: "#f4f5ff" }}
    >
      <Container
        className="container d-flex flex-column justify-content-center align-items-center gap-3"
        style={{ background: "transparent" }}
      >
        <img src={Icon.Logo} alt="" className="img-fluid" />
        <TextCom
          weight="xl"
          size="xxxl"
          color="primary_light2"
          textAlign="center"
        >
          {translate("under-construction", "Website is Under Construction!")}
        </TextCom>
      </Container>
    </div>
  );
};
