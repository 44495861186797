import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FiUser, FiHeart, FiFileText, FiExternalLink } from "react-icons/fi";
import { TbCrown } from "react-icons/tb";
import { BsArrowLeft } from "react-icons/bs";
import Media from "react-media";
import {
  Container,
  TextCom,
  ButtonCom,
  NewTabCom,
  CardCom,
  TableCom,
  StaticSidebar,
  BreadcrumbCom,
} from "components";
import { useHandleOther, useOther, useCart } from "hook";
import { StyledOrderDetailCon } from "theme";
import Icon from "asset/icon/pladco";
import { splitedTime, moneyFormat, joinSpace, en2mm_num } from "utils";
import Image from "asset/icon/pladco";
import "./style.scss";
import { StyledTh, StyledTr } from "theme";
import { TableHeadCom } from "../../components/common/table/tablehead";

type OrderDetailProps = {};

export const OrderDetail: React.FC<OrderDetailProps> = (props) => {
  const { translate } = useHandleOther();
  const { getContactUsPage_data, dispatch, langStore } = useOther();
  const { getOrder_data, CartAction, getOrderById_data } = useCart();
  const { orderId } = useParams();
  const navigate = useNavigate();
  const langCode = langStore?.code;

  useEffect(() => {
    dispatch(CartAction.getOrderById(orderId));
  }, []);

  console.log(getOrderById_data?.order_lines, "dandemo");

  //---------order-line-table-------------
  const columns = [
    {
      title: translate("product", "Product"),
      key: "name",
      width: 400,
      textAlign: "center",
      render: (x: any, y: any) => (
        <div
          className="product_name_wrap d-flex"
          style={{ paddingLeft: "20px" }}
          onClick={() =>
            (x?.promotion_lines?.length > 0 && x?.product?.product_tmpl_id) &&
            navigate(`../product/${x?.product?.product_tmpl_id}`)
          }
        >
          {x?.product?.image !== false ? (
            <img src={x?.product?.image || Image.DefaultCard} />
          ) : (
            <>
              {x?.product?.reward_type === "discount" ? (
                <img src={Image.PromotionTag} />
              ) : x?.product?.is_delivery_line === true ? (
                <img src={Image.Delivery} />
              ) : (
                <img src={Image.DefaultCard} />
              )}
            </>
          )}
          <div>
            <TextCom weight="xl">
              {joinSpace(x?.product?.name) || "Product Name"}
            </TextCom>
            {x?.product?.code && x?.product?.is_delivery_line !== true && (
              <TextCom weight="xl">
                {translate("sku", "SKU")}: <span>{x?.product?.code}</span>
              </TextCom>
            )}
          </div>
        </div>
      ),
      promorender: (x: any, y: any, key: any) =>
        x?.promotion_lines && (
          <div
            className="product_name_wrap d-flex promo product"
            style={{ marginTop: "10px", marginBottom: "10px" }}
            onClick={() =>
              navigate(
                x?.product_type === "service"
                  ? `#`
                  : `../product/${x?.product_template_id}`
              )
            }
          >
            {x?.promotion_lines[0]?.product?.is_delivery_line === true ? (
              <img
                style={{ width: "60px", marginLeft: "86px" }}
                src={Image.Delivery}
              />
            ) : (
              <img
                src={
                  x?.promotion_lines[0]?.product?.reward_type === "product"
                    ? x?.promotion_lines[0]?.product?.image
                    : Image.PromotionTag
                }
                style={{ width: "60px", marginLeft: "86px" }}
              />
            )}
            <div>
              {x?.promotion_lines[0]?.product?.reward_type === "product" && (
                <TextCom weight="xl" className="mb-0">
                  {x?.promotion_lines[0]?.name}
                </TextCom>
              )}
              <TextCom>{x?.promotion_lines[0]?.remark}</TextCom>
            </div>
          </div>
        ),
    },
    {
      title: translate("price", "Price"),
      key: "price",
      width: 100,
      textAlign: "center",
      render: (x: any, y: any) => (
        <div className="price_wrap promo-price-wrap">
          {x?.product?.is_delivery_line === false && (
            <TextCom weight="xl" size="lg" textAlign="center">
              {moneyFormat(Math.ceil(x?.price_unit))}
              {translate("ks", "Ks")}
            </TextCom>
          )}
        </div>
      ),
      promorender: (x: any, y: any, key: any) =>
        x?.promotion_lines && (
          <div
            className={`promo price ${
              x?.promotion_lines[0]?.product?.reward_type === "product"
                ? "product-free"
                : ""
            }`}
            style={
              x?.promotion_lines[0]?.product?.reward_type === "product"
                ? { opacity: "1" }
                : { opacity: "0" }
            }
          >
            <TextCom
              weight="lg"
              size="md"
              textAlign="center"
              color="primary_light2"
              style={{
                position: "absolute",
                bottom: "18%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                border: "1px solid #5E71EC",
                background: "#C3CAF8",
                borderRadius: "26px",
                lineHeight: "20px",
                paddingInline: "8px",
              }}
            >
              {translate("free", "Free")}
            </TextCom>
          </div>
        ),
    },
    {
      title: translate("quantity", "Quantity"),
      key: "qty",
      width: 100,
      textAlign: "center",
      render: (x: any, y: any) => (
        <div className="qty_wrap promo-qty-wrap">
          {x?.product?.is_delivery_line === false && (
            <TextCom color="border" textAlign="center" size={"xl"} weight="lg">
              {moneyFormat(x?.quantity, langCode)}
            </TextCom>
          )}
        </div>
      ),
      promorender: (x: any, y: any, key: any) =>
        x?.promotion_lines && (
          <div
            className="promo qty"
            style={
              x?.promotion_lines[0]?.product?.reward_type === "product"
                ? { opacity: "1", bottom: "23%" }
                : { opacity: "0" }
            }
          >
            <TextCom
              style={{
                position: "absolute",
                bottom: "18%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              {moneyFormat(x?.promotion_lines[0]?.quantity, langCode)}
            </TextCom>
          </div>
        ),
    },
    {
      title: translate("amount", "Amount"),
      key: "total",
      width: 100,
      textAlign: "center",
      render: (x: any, y: any) => (
        <div className="total_price_wrap promo-total-price-wrap">
          <TextCom weight="lg" size="xl" color="primary" textAlign="center">
            {moneyFormat(Math.ceil(x?.total_price), langCode)}
            <TextCom
              color="primary"
              as="span"
              size={langCode === "en" ? "xl" : "md"}
              weight="lg"
              style={{ marginLeft: "5px" }}
            >
              {translate("ks", "Ks")}
            </TextCom>
          </TextCom>
        </div>
      ),
      promorender: (x: any, y: any, key: any) =>
        x?.promotion_lines && (
          <div className="promo subtotal">
            {x?.promotion_lines[0]?.total_price !== 0 ? (
              <TextCom
                weight="xl"
                size="lg"
                textAlign="center"
                color="primary_light2"
                style={{
                  position: "absolute",
                  bottom: "18%",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, 50%)",
                  whiteSpace: "nowrap",
                }}
              >
                {moneyFormat(
                  Math.ceil(x?.promotion_lines[0]?.total_price),
                  langCode
                )}{" "}
                {translate("ks", "Ks")}
              </TextCom>
            ) : (
              ""
            )}
          </div>
        ),
    },
  ];

  const mb_columns = [
    {
      title: translate("product", "Product"),
      key: "name",
      width: 200,
      textAlign: "center",
      render: (x: any, y: any) => (
        <div className="product_name_wrap" style={{ paddingLeft: "15px" }}>
          <TextCom weight="xl">
            {joinSpace(x?.product?.name) || "Product Name"}
          </TextCom>
          {x?.product?.code && x?.product?.is_delivery_line !== true && (
            <TextCom weight="xl">
              {translate("sku", "SKU")} : <span>{x?.product?.code}</span>
            </TextCom>
          )}
        </div>
      ),
      promorender: (x: any, y: any) =>
        x?.promotion_lines && (
          <div className="product_name_wrap" style={{ paddingLeft: "15px" }}>
            <TextCom weight="xl">
              {joinSpace(x?.promotion_lines[0]?.product?.name) ||
                "Product Name"}
            </TextCom>
          </div>
        ),
    },
    {
      title: translate("quantity", "Quantity"),
      key: "qty",
      width: 100,
      textAlign: "center",
      render: (x: any, y: any) => (
        <div
          className="qty_wrap"
          style={{
            position: "absolute",
            top: "25%",
            left: "50%",
            transform: "translate(-50%, -25%)",
          }}
        >
          {x?.product?.is_delivery_line === false && (
            <TextCom color="border" textAlign="center" size={"xl"} weight="lg">
              {moneyFormat(x?.quantity, langCode)}
            </TextCom>
          )}
        </div>
      ),
      promorender: (x: any, y: any) =>
        x?.promotion_lines && (
          <div
            className="qty_wrap"
            style={{
              position: "absolute",
              bottom: "25%",
              left: "50%",
              transform: "translate(-50%, 25%)",
            }}
          >
            {x?.product?.is_delivery_line === false && (
              <TextCom
                color="border"
                textAlign="center"
                size={"xl"}
                weight="lg"
              >
                {moneyFormat(x?.promotion_lines[0]?.quantity, langCode)}
              </TextCom>
            )}
          </div>
        ),
    },
    {
      title: translate("total", "Total"),
      key: "total",
      width: 100,
      textAlign: "center",
      render: (x: any, y: any) => (
        <div
          className="total_price_wrap"
          style={{
            position: "absolute",
            top: "25%",
            left: "50%",
            transform: "translate(-50%, -25%)",
          }}
        >
          <TextCom weight="lg" color="primary" size="lg" textAlign="center">
            {moneyFormat(Math.ceil(x?.total_price), langCode)}
            <TextCom
              as="span"
              weight="lg"
              color="primary"
              size={langCode === "en" ? "lg" : "sm"}
              style={{ marginLeft: "3px" }}
            >
              {translate("ks", "Ks")}
            </TextCom>
          </TextCom>
        </div>
      ),
      promorender: (x: any, y: any) =>
        x?.promotion_lines && (
          <div
            className="total_price_wrap"
            style={{
              position: "absolute",
              bottom: "25%",
              left: "50%",
              transform: "translate(-50%, 25%)",
            }}
          >
            {x?.promotion_lines[0]?.total_price != 0 ? (
              <TextCom weight="lg" color="primary" size="lg" textAlign="center">
                {moneyFormat(
                  Math.ceil(x?.promotion_lines[0]?.total_price),
                  langCode
                )}
                <TextCom
                  as="span"
                  weight="lg"
                  color="primary"
                  size={langCode === "en" ? "lg" : "sm"}
                  style={{ marginLeft: "3px" }}
                >
                  {translate("ks", "Ks")}
                </TextCom>
              </TextCom>
            ) : (
              <TextCom
                weight="lg"
                size="lg"
                textAlign="center"
                color="primary_light2"
                style={{
                  border: "1px solid #5E71EC",
                  background: "#C3CAF8",
                  borderRadius: "26px",
                  lineHeight: "26px",
                  paddingInline: "8px",
                  minWidth: "80px",
                  maxWidth: "100px",
                  marginRight: "auto",
                  marginLeft: "auto",
                }}
              >
                {translate("free", "Free")}
              </TextCom>
            )}
          </div>
        ),
    },
  ];

  //---------gift-table-------------------
  const giftcolumns = [
    {
      key: "name",
      width: 300,
      render: (x: any, y: any) => (
        <div className="product_name_wrap d-flex">
          <img src={x?.image || Image.DefaultCard} />
          <div>
            <TextCom weight="xl">{x?.gift_name || "Product Name"}</TextCom>
          </div>
        </div>
      ),
    },
    {
      key: "price",
      width: 200,
      render: (x: any, y: any) => (
        <div className="price_wrap">
          <TextCom weight="xl" color="secondary" size="lg" textAlign="right">
            {x?.value_point || "0"} {translate("points", "points")}
          </TextCom>
        </div>
      ),
    },
    {
      key: "qty",
      width: 200,
      render: (x: any, y: any) => (
        <div className="qty_wrap">
          <TextCom color="border" textAlign="center">
            {translate("qty", "Qty")} : {x?.quantity || "1"}
          </TextCom>
        </div>
      ),
    },
    {
      key: "total",
      width: 100,
      render: (x: any, y: any) => (
        <div className="total_price_wrap">
          <TextCom weight="xl" size="xl" textAlign="right">
            {x?.value_point * x?.quantity || "0"}{" "}
            {translate("points", "points")}
          </TextCom>
        </div>
      ),
    },
  ];

  const giftmb_columns = [
    {
      key: "name",
      width: 200,
      render: (x: any, y: any) => (
        <div className="product_name_wrap">
          <TextCom weight="xl">{x?.gift_name || "Product Name"}</TextCom>
        </div>
      ),
    },
    {
      key: "qty",
      width: 100,
      render: (x: any, y: any) => (
        <div className="qty_wrap">
          <TextCom color="border" textAlign="right">
            {translate("qty", "Qty")} : {x?.quantity || "1"}
          </TextCom>
        </div>
      ),
    },
    {
      key: "total",
      width: 100,
      render: (x: any, y: any) => (
        <div className="total_price_wrap">
          <TextCom weight="xl" size="xl" textAlign="right">
            {x?.value_point * x?.quantity || "0"}
            {translate("points", "points")}
          </TextCom>
        </div>
      ),
    },
  ];

  const sidebar_data = [
    {
      name: translate("my-profile", "My Profile"),
      link: "/user/profile",
    },
    {
      name: translate("my-order", "My Order"),
      link: "/user/my_orders",
    },
    {
      name: translate("my-wishlist", "My Wishlist"),
      link: "/user/wishlist",
    },
    {
      name: translate("change-password", "My Wishlist"),
      link: "/user/change_password",
    },
    {
      name: translate("logout", "Logout"),
      link: "logout",
    },
  ];

  let breadcrumb_data = [
    {
      name: translate("home", "Home"),
      route: "/",
    },
    {
      name: translate("order-detail", "Order Detail"),
      route: "#",
    },
  ];

  // let item_con = document.querySelectorAll(".item_con");
  // if (item_con) item_con[0]?.classList.add("active");

  return (
    <>
      <BreadcrumbCom
        data={breadcrumb_data}
        isContainer={true}
        bgColor="primary_light3"
      />
      <StyledOrderDetailCon>
        <Container className="orderDetail-con container">
          <div className="row orderlist-row">
            <div className="col-md-12 col-lg-3">
              <StaticSidebar
                data={sidebar_data}
                route=""
                classname="side-bar"
              />
            </div>
            <div className="col-md-12 col-lg-9 order-detail-content">
              <div className="title">
                <TextCom size="xxl" weight="lg">
                  {translate("order-detail", "Order Detail")}
                </TextCom>
              </div>
              {getOrderById_data && (
                <CardCom className="order-detail-card">
                  <div className="order-title">
                    <TextCom weight="lg">
                      {translate("order-no", "Order No")} -{" "}
                      {getOrderById_data?.order_id}
                    </TextCom>
                    <TextCom>
                      {translate("delivered-to", "Delivered To")} :{" "}
                      {getOrderById_data?.shipping_info?.customer_name
                        ? `${getOrderById_data?.shipping_info?.customer_name}, `
                        : ""}
                      {getOrderById_data?.shipping_info?.mobile
                        ? `${getOrderById_data?.shipping_info?.mobile}, `
                        : ""}
                      {getOrderById_data?.shipping_info?.street
                        ? `${getOrderById_data?.shipping_info?.street}, `
                        : ""}
                      {getOrderById_data?.shipping_info?.township?.name
                        ? `${getOrderById_data?.shipping_info?.township?.name}, `
                        : ""}
                      {getOrderById_data?.shipping_info?.state?.name
                        ? `${getOrderById_data?.shipping_info?.state?.name}, `
                        : ""}
                      {getOrderById_data?.shipping_info?.country?.name
                        ? `${getOrderById_data?.shipping_info?.country?.name} `
                        : ""}
                    </TextCom>
                    {getOrderById_data?.payment_status && (
                      <TextCom>
                        {translate("payment-status", "payment-status")} :{" "}
                        {translate(
                          getOrderById_data?.payment_status,
                          getOrderById_data?.payment_status
                        )}
                      </TextCom>
                    )}
                  </div>
                  <div className="order-table">
                    <Media query={{ minWidth: 992 }}>
                      {(matches) =>
                        matches ? (
                          <TableCom
                            dataSource={getOrderById_data?.order_lines}
                            columns={columns}
                          />
                        ) : (
                          <>
                            <table className="w-100">
                              <StyledTr>
                                {mb_columns.map((title: any, i: any) => (
                                  <StyledTh key={i} {...title}>
                                    {i == 0 ? (
                                      <TextCom
                                        weight="xl"
                                        {...title}
                                        style={{
                                          textAlign: "left",
                                          paddingLeft: "15px",
                                        }}
                                      >
                                        {title?.title}
                                      </TextCom>
                                    ) : (
                                      <TextCom weight="xl" {...title}>
                                        {title?.title}
                                      </TextCom>
                                    )}
                                  </StyledTh>
                                ))}
                              </StyledTr>
                              {getOrderById_data?.order_lines?.map(
                                (item: any, key: any) => (
                                  <>
                                    <tr>
                                      <td>
                                        <div
                                          className="product_name_wrap product-name-for-product"
                                          style={{ paddingLeft: "15px" }}
                                        >
                                          <TextCom weight="xl">
                                            {joinSpace(item?.product?.name) ||
                                              "Product Name"}
                                          </TextCom>
                                          {item?.product?.code &&
                                            item?.product?.is_delivery_line !==
                                              true && (
                                              <TextCom weight="xl">
                                                {translate("sku", "SKU")} :{" "}
                                                <span>
                                                  {item?.product?.code}
                                                </span>
                                              </TextCom>
                                            )}
                                        </div>
                                      </td>
                                      <td className="qty-for-product">
                                        <div className="qty_wrap">
                                          {item?.product?.is_delivery_line ===
                                            false && (
                                            <TextCom
                                              color="border"
                                              textAlign="center"
                                              size={"xl"}
                                              weight="lg"
                                              style={{ marginBottom: "10px" }}
                                            >
                                              {moneyFormat(
                                                item?.quantity,
                                                langCode
                                              )}
                                            </TextCom>
                                          )}
                                        </div>
                                      </td>
                                      <td className="total_price_wrap total-price-for-product">
                                        <TextCom
                                          weight="lg"
                                          color="primary"
                                          size="lg"
                                          textAlign="center"
                                          style={{
                                            marginBottom: "10px",
                                          }}
                                        >
                                          {moneyFormat(
                                            Math.ceil(item?.total_price),
                                            langCode
                                          )}
                                          <TextCom
                                            as="span"
                                            weight="lg"
                                            color="primary"
                                            size={
                                              langCode === "en" ? "lg" : "sm"
                                            }
                                            style={{ marginLeft: "3px" }}
                                          >
                                            {translate("ks", "Ks")}
                                          </TextCom>
                                        </TextCom>
                                      </td>
                                    </tr>
                                    {item?.promotion_lines &&
                                      item?.promotion_lines?.map(
                                        (promo: any, pkey: any) => (
                                          <tr>
                                            <td
                                              className="product_name_wrap product-name-for-promo"
                                              style={{ paddingLeft: "15px" }}
                                            >
                                              <TextCom weight="xl">
                                                {joinSpace(
                                                  promo.product?.name
                                                ) || "Product Name"}
                                              </TextCom>
                                            </td>
                                            <td className="qty_wrap qty-for-promo">
                                              {promo?.product
                                                ?.is_delivery_line ===
                                                false && (
                                                <TextCom
                                                  color="border"
                                                  textAlign="center"
                                                  size={"xl"}
                                                  weight="lg"
                                                  style={{
                                                    marginBottom: "10px",
                                                  }}
                                                >
                                                  {moneyFormat(
                                                    promo?.quantity,
                                                    langCode
                                                  )}
                                                </TextCom>
                                              )}
                                            </td>
                                            <td className="total_price_wrap total-price-for-promo">
                                              {promo?.total_price != 0 ? (
                                                <TextCom
                                                  weight="lg"
                                                  color="primary"
                                                  size="lg"
                                                  textAlign="center"
                                                  style={{
                                                    marginBottom: "10px",
                                                  }}
                                                >
                                                  {moneyFormat(
                                                    Math.ceil(
                                                      promo?.total_price
                                                    ),
                                                    langCode
                                                  )}
                                                  <TextCom
                                                    as="span"
                                                    weight="lg"
                                                    color="primary"
                                                    size={
                                                      langCode === "en"
                                                        ? "lg"
                                                        : "sm"
                                                    }
                                                    style={{
                                                      marginLeft: "3px",
                                                    }}
                                                  >
                                                    {translate("ks", "Ks")}
                                                  </TextCom>
                                                </TextCom>
                                              ) : (
                                                <TextCom
                                                  weight="lg"
                                                  size="lg"
                                                  textAlign="center"
                                                  color="primary_light2"
                                                  style={{
                                                    border: "1px solid #5E71EC",
                                                    background: "#C3CAF8",
                                                    borderRadius: "26px",
                                                    lineHeight: "26px",
                                                    paddingInline: "8px",
                                                    minWidth: "80px",
                                                    maxWidth: "100px",
                                                    marginRight: "auto",
                                                    marginLeft: "auto",
                                                  }}
                                                >
                                                  {translate("free", "Free")}
                                                </TextCom>
                                              )}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                  </>
                                )
                              )}
                            </table>
                          </>
                        )
                      }
                    </Media>
                  </div>
                  {getOrderById_data?.tax > 0 && (
                    <div className="sub-total d-flex">
                      <div>
                        <TextCom size="xl" weight="xl">
                          {translate("subtotal", "Subtotal")}
                        </TextCom>
                        {getOrderById_data?.tax > 0 && (
                          <TextCom size="xl" weight="xl">
                            {translate("tax", "Tax")}
                          </TextCom>
                        )}
                        {getOrderById_data?.use_point !== 0 && (
                          <TextCom size="xl" weight="xl">
                            {translate("point-redeemed", "Point redeemed")}
                          </TextCom>
                        )}
                        {getOrderById_data?.discount_percent > 0 && (
                          <TextCom size="xl" weight="xl">
                            {translate("discount-amount", "Discount Amount")} (
                            {getOrderById_data?.discount_percent}%)
                          </TextCom>
                        )}
                      </div>
                      <div>
                        <TextCom size="xl" weight="xl" textAlign="right">
                          {moneyFormat(
                            Math.ceil(getOrderById_data?.subtotal),
                            langCode
                          )}{" "}
                          <TextCom
                            as="span"
                            size={langCode === "en" ? "xl" : "md"}
                            weight={"xl"}
                            style={{ marginLeft: "5px" }}
                          >
                            {translate("ks", "Ks")}
                          </TextCom>
                        </TextCom>
                        {getOrderById_data?.tax > 0 && (
                          <TextCom size="xl" weight="xl" textAlign="right">
                            {moneyFormat(Math.ceil(getOrderById_data?.tax))}{" "}
                            {translate("ks", "Ks")}
                          </TextCom>
                        )}
                        {getOrderById_data?.use_point !== 0 && (
                          <TextCom size="xl" weight="xl" textAlign="right">
                            {getOrderById_data?.use_point}
                          </TextCom>
                        )}
                        {getOrderById_data?.discount_percent > 0 && (
                          <TextCom size="xl" weight="xl" textAlign="right">
                            -{getOrderById_data?.discount_amount}
                          </TextCom>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="total d-flex">
                    <div>
                      <TextCom size="xxl" weight="xl">
                        {translate("total", "Total")}
                      </TextCom>
                      {/* <div className="d-flex point">
                        <TbCrown size={30} color="#F27043" fill="#F27043" />
                        <TextCom color="secondary">
                          {translate("points-earned", "Points earned")}
                        </TextCom>
                      </div> */}
                    </div>
                    <div>
                      <TextCom size="xxxl" weight="xl" textAlign="right">
                        {moneyFormat(
                          Math.ceil(getOrderById_data?.total),
                          langCode
                        )}{" "}
                        <TextCom
                          as="span"
                          size={langCode === "en" ? "xxxl" : "xl"}
                          weight={"xl"}
                          style={{ marginLeft: "5px" }}
                        >
                          {translate("ks", "Ks")}
                        </TextCom>
                      </TextCom>
                      {/* <TextCom
                        textAlign="right"
                        className="point-earn"
                        color="light"
                      >
                        {getOrderById_data?.reward_point}
                      </TextCom> */}
                    </div>
                  </div>
                </CardCom>
              )}
              {/* {getOrderById_data?.gifts?.length > 0 && (
                <>
                  <TextCom size="xxxl" weight="lg">
                    {translate("gift", "Gift")}
                  </TextCom>
                  <CardCom className="gift-card">
                    <div className="gift-table">
                      <Media query={{ minWidth: 992 }}>
                        {(matches) => (
                          <TableCom
                            dataSource={getOrderById_data?.gifts}
                            columns={matches ? giftcolumns : giftmb_columns}
                          />
                        )}
                      </Media>
                    </div>
                    <div className="total d-flex">
                      <TextCom size="xxxl" weight="xl">
                        {translate(
                          "total-exchange-points",
                          "Total Exchange Points"
                        )}
                      </TextCom>
                      <TextCom size="xxxl" weight="xl">
                        {getOrderById_data?.use_point}{" "}
                        {translate("points", "points")}
                      </TextCom>
                    </div>
                  </CardCom>
                </>
              )} */}
              <ButtonCom
                type="outline"
                bgcolor="background"
                className="back-btn"
                btnBorderRadius="xxs"
                onClick={() => {
                  navigate(`../user/my_orders`);
                }}
                style={{ paddingInline: "10px", textTransform: "unset" }}
              >
                <BsArrowLeft />{" "}
                {translate("back-to-my-order", "Back to my order")}
              </ButtonCom>
            </div>
          </div>
        </Container>
      </StyledOrderDetailCon>
    </>
  );
};
