import styled from "styled-components";

export const StyledOrderDetailCon = styled.div`
  padding-bottom: 50px;      
  padding-top: 30px;
   @media(max-width: 991px){
    padding-top: 0;
  }
  .title {
            /* margin-bottom: 20px; */
            border-bottom: 1px solid ${props => props?.theme?.color?.typo?.line || '#ddd'};
            p{
                border-bottom: 2px solid ${props => props?.theme?.color?.typo?.primary};
                padding-bottom: 10px;
                width: fit-content;
                margin-bottom: -1px;
            }
            }

    .title{
        line-height: 46px; 
        /* margin-bottom: 30px!important; */
        @media(max-width: 768px){
          font-size: 22px;
          margin-bottom: 20px!important;
        }
    }
 
    /* content */
    .order-detail-content{
      .order-detail-card{
        padding: 0;
        flex-direction: column;
        border: none;
        .order-title{
          position: relative;
          width: 100%;
          /* background: ${props => props?.theme?.color?.typo?.bg1 || '#f9f9f9'}; */
          padding-block: 15px;
      
          p{ margin-bottom: 8px;}
          .status{
            border-radius: 15px;
            padding-inline: 10px;
            font-style: italic;
            background: ${props => props?.theme?.color?.typo?.status || '#FF4200'};
            /* line-height: 50px; */
            display: flex;
            justify-content: center;
            align-items: center;
            width: fit-content;
            height: 25px;
            position: absolute;
            top: 15px;
            right: 10px;
          }
        }
        .order-table{
          border: 1px solid ${props => props?.theme?.color?.typo?.line || '#d9d9d9'};
          border-radius: 10px 10px 0 0;
          overflow: hidden;
          table{
          }
          .product_name_wrap{
            align-items:center;
            img{
              max-width: 130px;
              margin-right: 20px;
            }
            p{margin-bottom: 10px;}
            @media(max-width: 425px){
              padding-left: 5px!important;
            }
          }
          .price_wrap,.qty_wrap,.total_price_wrap{
            @media(min-width: 992px){
              padding-block: 25px;
            }
            @media(min-width: 1440px){
              padding-block: 50px;
            }
          }
          .promo-price-wrap,.promo-qty-wrap,.promo-total-price-wrap{
            position: absolute;
             top: 9%;
             left: 0;
             right: 0;
             @media(max-width: 1439px){
              top: 15%;
             }
          }
        }
        margin-bottom: 30px;
      }
      .sub-total,.total{
        padding: 15px;
        padding-inline: 26px;
        justify-content: flex-end;
        gap: 150px;
        border: 1px solid ${props => props?.theme?.color?.typo?.line || '#d9d9d9'};
        p:first-child{
          margin-bottom: 10px;
        }
        @media(max-width: 768px){
          justify-content: space-between;
        }
      }
      .sub-total{
        @media(max-width: 425px){
          padding-inline: 13px!important;  
        }
      }
      .total{
        /* background: ${props => props?.theme?.color?.typo?.bg1 || '#d9d9d9'}; */
        border-radius: 0 0 10px 10px;
        padding: 15px;
        padding-inline: 26px;
        gap: 145px;
        .point{
          align-items: flex-end;
          gap: 5px;
        }
        .point-earn{
          float: right;
          background: ${props => props?.theme?.color?.typo?.secondary || '#F27043'};
          border-radius: 50%;
          width: fit-content;
          padding-inline: 5px;
          padding-block: 2px;
        }
      }
      .gift-card{
        padding: 0;
        flex-direction: column;
        .gift-table{
          width: 100%;
          padding: 20px;
          table{
            thead{ display: none;}
          }
        }
        .product_name_wrap{
          align-items: center;
          img{
              max-width: 130px;
              margin-right: 20px;
              @media(max-width: 1200px){
                max-width: 130px;
              }
            }
        }
        .total{
          padding: 25px;
          padding-right: 20px;
          gap: 50px;
          @media(max-width: 768px){
            padding: 20px;
          }
        }
        @media(max-width: 991px){
          margin-bottom: 40px;
        }
      }
    }
    .back-btn{
      border: none;
      margin-top: 40px;
      svg{ margin-right: 10px;}
      padding: 0;
      &:hover{
        color: ${props => props?.theme?.color?.typo?.primary};
      }
      @media(max-width: 991px){
        display: none;
      }
    }

  
  .order-title,.order-table,.gift-table,.sub-total,.total,.side-bar{
    @media(max-width: 425px){
      padding-inline: 8px!important;
    }
  }
  .order-detail-card{
    .sub-total,.total{
      @media(max-width: 768px){
        gap: unset!important;
      }
    }
  }

  .order-table table td{
    position: relative;
  }
`