import ActiveHeartSvg from "./active_heart.svg";
import HeartSvg from "./heart.svg";
import Active_wishlistheart from "./heart.png";
import Wishlistheart from "./wishlistheart.svg";
import PDF_Icon from "./file-pdf.svg";
import DefaultCard from "./default_card.jpg";
import DefaultDetailCard from "./default_card.jpg";
import Loading from "./loading.gif";
import Background from "./background.jpg";
import BG from "./bg-large.png";
import NewDefaultImg from "./new_default.png";
import FilterIcon from "./filter.png";
import EmptyCart from "./empty.png";
import WishlistInfo from "./wishlist.png";
import ArrowDown from "./arrow-down.svg";
import PromotionTag from "./discount.png";
import Delivery from "./delivery.png";
import LeftArrowBlack from "./long-arrow-pointing-to-left.png";
import RightArrowBlack from "./long-arrow-pointing-to-the-right.png";
import Facebook from "./facebook.svg";
import userWhite from "./user-white.png";
import userIconFill from "./user-fill.svg";
import heartIconOutline from "./heart-empty.png";
import fileIcon from "./document.png";
import keyIcon from "./key.png";
import logoutIcon from "./logout.png";
import BagIconMobile from "./shopping-bag-mobile.png";
import hotlineBlack from "./headphone-black.png";
import userIcon from "./user-outline.png";
import LocationSvg from "./location.svg";
import Mail from "./mail.svg";
import Telephone from "./telephone.svg";
import hotLineIcon from "./headphone.png";
import engImage from "./eng.svg";
import myImage from "./my.svg";
import help from "./help.svg";
import MaskGpSvg from "./mask_gp.svg";
import IconTickSvg from "./icon_tick.svg";
import phone from "./feather-phone.svg";
import mail from "./feather-mail.svg";
import SearchIconLarge from "./search-large.svg";
import map from "./feather-map-pin.svg";
import Playstore from "./playstore.png";
import Appstore from "./appstore.png";
import Promo from "./promotionbanner.jpg";
import Promo1 from "./promotionbanner_2.jpg";
import Logo from "./logo.png";
// import Logofooter from './logo-footer.png'
import notfound from "./notfound.png";
import Category from "./cate.png";
import Category2 from "./cate2.png";
import Category3 from "./cate3.png";
import Category4 from "./cate4.png";
import Category5 from "./cate5.png";
import Category6 from "./cate6.png";
import LogoDef from "./logo-default.png";
import ComingSoon from "./coming-soon.png";
//pladco
import Search from "./Search.png";
import Cart from "./cart.png";
import User from "./user.png";
import Alert from "./alert.png";
import Loc from "./loc.svg";
import Ph from "./ph.svg";
import Mailicon from "./mail.svg";
import NoWishlist from "./no-wishlist.png";
import LanguageImg from "./language.png";
import NoProduct from "./package.png";
import ViberLarge from "./viber-large.png";
import ArrowUp from "./arrow-up.png";
import LoadingGif from "../loading.png";
import Globe from "./globe.svg";
import Login from "./login.png";

export default {
  Logo,
  LogoDef,
  LoadingGif,
  // Logofooter,
  userWhite,
  userIconFill,
  heartIconOutline,
  fileIcon,
  keyIcon,
  logoutIcon,
  BagIconMobile,
  hotlineBlack,
  userIcon,
  LocationSvg,
  Mail,
  Telephone,
  hotLineIcon,
  engImage,
  myImage,
  help,
  MaskGpSvg,
  IconTickSvg,
  phone,
  mail,
  SearchIconLarge,
  map,
  Facebook,
  LeftArrowBlack,
  RightArrowBlack,
  Delivery,
  PromotionTag,
  Loading,
  ActiveHeartSvg,
  HeartSvg,
  Active_wishlistheart,
  Wishlistheart,
  PDF_Icon,
  DefaultCard,
  DefaultDetailCard,
  Background,
  BG,
  NewDefaultImg,
  FilterIcon,
  EmptyCart,
  WishlistInfo,
  ArrowDown,
  Playstore,
  Appstore,
  Promo,
  Promo1,
  notfound,
  Category,
  Category2,
  Category3,
  Category4,
  Category5,
  Category6,
  ComingSoon,
  //pladco
  Search,
  Cart,
  User,
  Alert,
  Loc,
  Mailicon,
  Ph,
  NoWishlist,
  LanguageImg,
  NoProduct,
  ViberLarge,
  ArrowUp,
  Globe,
  Login,
};
