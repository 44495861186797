import React, { useState, useEffect } from "react";

import { RSSliderCom } from "../../common";
import { ProductCard, ProductCardMap } from "./productcard";
import { StyledNewArrivalSlider } from "theme";
type NewArrivalSliderProps = {
  data?: any;
  slidesToShow?: any;
  dots?: boolean;
  large_img?: boolean;
  no_border?: boolean;
  card_data?: any;
  leftArrowSvg?: any;
  rightArrowSvg?: any;
  type?: any;
  route?: any;
  style?: any;
};

export const NewArrivalSlider: React.FC<NewArrivalSliderProps> = ({
  style,
  dots,
  data,
  slidesToShow,
  card_data,
  large_img,
  no_border,
  type,
  route,
  ...props
}) => {
  let rs_props = {
    dots: dots || false,
    arrows: true,
    // adaptiveHeight: true,
    infinite: false,
    slidesToShow: slidesToShow || 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          // infinite: true
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };
  return (
    <StyledNewArrivalSlider
      className={`${large_img ? "large-card-slider" : "aa"}`}
      style={style}
    >
      <RSSliderCom rsProps={rs_props} {...props}>
        {data &&
          data?.map((x: any, i: any) => (
            <ProductCard data={x} no_border={no_border || true} key={i} />
          ))}
        {card_data &&
          card_data?.map((item: any, i: any) => (
            <ProductCard
              data={{
                item_name: item.name,
                main_img: item.product_image ? item.product_image : item.image,

                item_price: item.list_price || item?.price[0]?.price,
                discount_price: item.promo_price,
                discount_percent: item?.promotion
                  ? item?.promotion[0]?.discount_percent
                  : item.discount_percent || "",
                route: item.id
                  ? `/product/${item.id}`
                  : `/product/${item.product_id}`,
                replace: false,
                item,
              }}
              has_item={item?.in_stock === false ? "false" : "true"}
              no_border={!no_border ? no_border : true}
              key={i}
            />
          ))}
      </RSSliderCom>
    </StyledNewArrivalSlider>
  );
};
