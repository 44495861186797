import styled from "styled-components";


export const StyledPromoList = styled.div`
   /* .title {
          margin-bottom: 20px;
          border-bottom: 1px solid ${props => props?.theme?.color?.typo?.line || '#ddd'};
          h1{
              border-bottom: 2px solid ${props => props?.theme?.color?.typo?.primary};
              padding-bottom: 10px;
              width: fit-content;
              margin-bottom: -1px;
          }
          } */
          /* padding-block: 30px; */
  
  .cu-card-width{
    width: calc(100% / 5);
    margin-bottom: 33px;
    @media(max-width:991px) {
        width: calc(100% / 2);
    }
  }

  .promo-section{
    margin-bottom: 50px;
    .promotion-banner{
      img{ width: 100%!important;  }
      }
    .promotion-desc{
       margin-block: 40px;
       @media(max-width: 991px){
        margin-block: 10px;}
       @media(max-width: 475px){
        h1{
          line-height: unset;
        }
      }
      @media(max-width:320px) {
        h1,h2{
          font-size:13px;
        }
      }
      @media(max-width: 425px){
        margin-top: 15px;
        margin-bottom: 30px;
        .title{
          gap: 10px;
          hr{
            width: 10%;
          }
          h1{
            margin-bottom: 0;
          }
        }
      }
    }
  }
  
  

.padding-container{
  padding-top: 60px;
}
`