import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { TbCrown } from "react-icons/tb";
import { AiFillCloseCircle } from "react-icons/ai";
import { RiDeleteBinLine } from "react-icons/ri";
import { BsExclamationCircle } from "react-icons/bs";
import { FaQuestion } from "react-icons/fa";
import Media from "react-media";

import {
  TextCom,
  ButtonCom,
  Container,
  NewTabCom,
  TableCom,
  ModalCom,
  QtyButtonMod,
  FormContainerCom,
  InputCom,
  StockVariantMsgMod,
  CardCom,
  BreadcrumbCom,
} from "components";
import { useHandleOther, useProduct, useAuth, useCart, useOther } from "hook";
import { moneyFormat, joinSpace } from "utils";
import { StyledCartCon } from "theme";
import Image from "asset/icon/pladco";

type IShoppingCartProps = {};

export const ShoppingCart: React.FC<IShoppingCartProps> = (props) => {
  const navigate = useNavigate();
  const { translate } = useHandleOther();
  const { getProfile_data } = useAuth();
  const {
    getCart_data,
    createCart_data,
    getPointRate_data,
    postRedeemPointToOrder_data,
    getShippingAddressById_data,
    dispatch,
    CartAction,
  } = useCart();
  const { langStore } = useOther();
  let langCode = langStore.code;
  const [isexceedPoint, setIsexceedPoint] = useState(false);
  const [isModal, setIsModal]: any = useState({
    visible: false,
    type: "",
  });
  const columns = [
    {
      title: translate("product", "Product"),
      key: "name",
      width: 400,
      render: (x: any, y: any) => (
        <div className="product_name_wrap d-flex">
          <div
            onClick={() =>
              navigate(
                x?.product_type === "service"
                  ? `#`
                  : `../product/${x?.product_template_id}`
              )
            }
          >
            {x?.is_delivery_line === true ? (
              <img src={Image.Delivery} className="deli-image" />
            ) : (
              <img
                src={
                  x?.product_image
                    ? x?.product_image
                    : Image.DefaultCard ||
                      (x?.reward_type === "product"
                        ? x?.product_image
                        : Image.PromotionTag)
                }
              />
            )}
          </div>

          <div>
            <TextCom
              className="name"
              weight="xl"
              onClick={() =>
                navigate(
                  x?.product_type === "service"
                    ? `#`
                    : `../product/${x?.product_template_id}`
                )
              }
            >
              {joinSpace(x?.name)}
            </TextCom>
            <TextCom weight="xl">{joinSpace(x?.note)}</TextCom>
            {x?.code && x?.is_delivery_line !== true && (
              <TextCom weight="xl">
                SKU: <span>{x?.code}</span>
              </TextCom>
            )}
            {x?.package_items?.length > 0 &&
              x?.package_items?.map((item: any, key: any) => (
                <TextCom weight="">{joinSpace(item?.name)}</TextCom>
              ))}
          </div>
        </div>
      ),
      promorender: (x: any, y: any, key: any) =>
        x?.promotion_lines && (
          <div
            className="product_name_wrap d-flex promo product"
            style={{ marginTop: "0px", marginBottom: "10px" }}
            onClick={() =>
              navigate(
                x?.product_type === "service"
                  ? `#`
                  : `../product/${x?.product_template_id}`
              )
            }
          >
            {x?.promotion_lines[0]?.is_delivery_line === true ? (
              <img src={Image.Delivery} />
            ) : (
              <img
                src={
                  x?.promotion_lines[0]?.reward_type === "product"
                    ? x?.promotion_lines[0]?.product_image
                    : Image.PromotionTag
                }
              />
            )}
            <div>
              {x?.promotion_lines[0]?.reward_type === "product" && (
                <TextCom weight="xl" className="mb-0">
                  {x?.promotion_lines[0]?.name}
                </TextCom>
              )}
              <TextCom>{x?.promotion_lines[0]?.remark}</TextCom>
            </div>
          </div>
        ),
    },
    {
      title: translate("price", "Price"),
      key: "price",
      width: 150,
      render: (x: any, y: any) => (
        <div
          className={`price-wrap  ${
            x?.promotion_lines ? "promo-price-wrap" : ""
          }`}
        >
          {x?.product_type !== "service" && (
            <TextCom size="lg" textAlign="center">
              {moneyFormat(Math.ceil(x?.price_unit), langCode)}{" "}
              {translate("ks", "Ks")}
            </TextCom>
          )}
        </div>
      ),
      promorender: (x: any, y: any, key: any) =>
        x?.promotion_lines && (
          <div
            className={`promo price ${
              x?.promotion_lines[0]?.reward_type === "product"
                ? "product-free"
                : ""
            }`}
            style={
              x?.promotion_lines[0]?.reward_type === "product"
                ? { opacity: "1" }
                : { opacity: "0" }
            }
          >
            <TextCom
              weight="lg"
              size="md"
              textAlign="center"
              color="primary_light2"
              style={{ position: "absolute", bottom: "23%" }}
            >
              {translate("free", "Free")}
            </TextCom>
          </div>
        ),
    },
    {
      title: translate("quantity", "Quantity"),
      key: "qty",
      width: 150,
      render: (x: any, y: any) =>
        x?.product_type !== "service" && x?.is_delivery_line !== "true" ? (
          <div
            className={`qty-wrap d-flex justify-content-center align-items-center ${
              x?.promotion_lines ? "promo-qty-wrap" : ""
            }`}
            style={{ marginTop: "auto", marginBottom: "auto" }}
          >
            <QtyButtonMod
              type="cartlist"
              c_index={getCart_data?.order_lines?.indexOf(x)}
              item={x}
            />
            <br />
            <StockVariantMsgMod
              from="other"
              item={
                getCart_data?.order_lines[getCart_data?.order_lines?.indexOf(x)]
              }
            />
          </div>
        ) : (
          x?.reward_type === "discount" && (
            <TextCom textAlign="center">{x?.quantity}</TextCom>
          )
        ),
      promorender: (x: any, y: any, key: any) =>
        x?.promotion_lines && (
          <div className="promo qty">
            <TextCom
              style={
                x?.promotion_lines[0]?.reward_type === "product"
                  ? { opacity: "1", position: "absolute", bottom: "23%" }
                  : { opacity: "0" }
              }
            >
              {x?.promotion_lines[0]?.quantity}
            </TextCom>
          </div>
        ),
    },
    {
      title: translate("uom", "UOM"),
      key: "uom",
      width: 150,
      render: (x: any, y: any) => (
        <div
          className={`price-wrap d-flex flex-column justify-content-center align-items-center promo-price-wrap`}
        >
          {x.is_delivery_line === true ? (
            ""
          ) : (
            <TextCom textAlign="center">{x?.uom ? x?.uom : ""}</TextCom>
          )}
        </div>
      ),
      promorender: (x: any, y: any, key: any) =>
        x?.promotion_lines && (
          <div className="d-flex justify-content-center" style={{paddingBlock: "25px", marginTop: "10px"}}>
            {x?.promotion_lines[0]?.uom ? (
              <TextCom
                textAlign="center"
                style={{ position: "absolute", bottom: "23%" }}
              >
                {x?.promotion_lines[0]?.uom}
              </TextCom>
            ) : (
              ""
            )}
          </div>
        ),
    },
    {
      title: translate("subtotal", "Subtotal"),
      key: "sub-total",
      width: 150,
      placement: "center",
      render: (x: any, y: any) =>
        x?.price_subtotal && (
          <TextCom
            weight="xl"
            size="lg"
            textAlign="center"
            color="primary_light2"
            className={`subtotal-wrap ${
              x?.promotion_lines ? "promo-subtotal-wrap" : ""
            }`}
          >
            {moneyFormat(Math.ceil(x?.price_subtotal), langCode)}{" "}
            {translate("ks", "Ks")}
          </TextCom>
        ),
      promorender: (x: any, y: any, key: any) =>
        x?.promotion_lines && (
          <div className="promo subtotal">
            {x?.promotion_lines[0]?.price_subtotal !== 0 ? (
              <TextCom
                weight="xl"
                size="lg"
                textAlign="center"
                color="primary_light2"
                style={{ position: "absolute", bottom: "18%" }}
              >
                {moneyFormat(
                  Math.ceil(x?.promotion_lines[0]?.price_subtotal),
                  langCode
                )}{" "}
                {translate("ks", "Ks")}
              </TextCom>
            ) : (
              ""
            )}
          </div>
        ),
    },
    {
      key: "action",
      width: 50,
      placement: "right",
      render: (x: any, y: any) =>
        x?.product_type !== "service" &&
        x?.is_delivery_line !== "true" && (
          <div
            className="cancel_btn"
            onClick={() =>
              handleDelete("one", true, x?.product_id, x?.package_info)
            }
          >
            <AiFillCloseCircle color="#707070" size={25} />
          </div>
        ),
      promorender: (x: any, y: any, key: any) =>
        x?.promotion_lines && (
          <div className="promo delete">
            <TextCom weight="xl" size="lg" textAlign="center" color="dark">
              {""}
            </TextCom>
          </div>
        ),
    },
  ];

  const mb_columns = [
    {
      key: "name",
      textAlign: "left",
      render: (x: any, y: any) => (
        <>
          <div className="product_name_wrap d-flex mb">
            <div
              className="img-wrap"
              onClick={() =>
                navigate(
                  x?.product_type === "service"
                    ? `#`
                    : `../product/${x?.product_template_id}`
                )
              }
            >
              {x?.is_delivery_line === true ? (
                <img src={Image.Delivery} />
              ) : (
                <img
                  src={
                    x?.product_image
                      ? x?.product_image
                      : Image.DefaultCard ||
                        (x?.reward_type === "product"
                          ? x?.product_image
                          : Image.PromotionTag)
                  }
                />
              )}
            </div>
            <div>
              <TextCom
                weight="xl"
                onClick={() =>
                  navigate(
                    x?.product_type === "service"
                      ? `#`
                      : `../product/${x?.product_template_id}`
                  )
                }
                className="mb-1"
              >
                {joinSpace(x?.name)} {joinSpace(x?.note)}
              </TextCom>
              {x?.code && x?.is_delivery_line !== true && (
                <TextCom weight="xl">
                  SKU: <span>{x?.code}</span>
                </TextCom>
              )}
              {x?.uom && x?.is_delivery_line !== true && (
                <TextCom weight="xl">
                  UOM: <span>{x?.uom}</span>
                </TextCom>
              )}
              {x?.package_items?.length > 0 &&
                x?.package_items?.map((item: any, key: any) => (
                  <TextCom weight="">
                    {key + 1} : {item?.name}
                  </TextCom>
                ))}
              {x?.product_type !== "service" &&
                x?.is_delivery_line !== "true" && (
                  <div
                    className="cancel_btn"
                    onClick={() => handleDelete("one", true, x?.product_id)}
                  >
                    <AiFillCloseCircle color="#707070" size={25} />
                  </div>
                )}
            </div>
          </div>
          <div
            className="btn-con d-flex"
            style={{ marginBottom: "0.25em !important" }}
          >
            <TextCom
              weight="xl"
              size="lg"
              color="primary_light2"
              style={{ marginLeft: "10px" }}
            >
              {moneyFormat(Math.ceil(x?.price_subtotal), langCode)}{" "}
              {translate("ks", "Ks")}
            </TextCom>
            {/* <TextCom color='paragraph'><del>1,500{translate('ks', 'Ks')}</del></TextCom> */}

            <div
              className="qty-wrap"
              style={
                x?.product_type !== "service" && x?.is_delivery_line !== "true"
                  ? { visibility: "visible" }
                  : { visibility: "hidden" }
              }
            >
              <QtyButtonMod
                type="cartlist"
                c_index={getCart_data?.order_lines?.indexOf(x)}
                item={x}
              />
            </div>
          </div>
          <div className="mt-0">
            <StockVariantMsgMod
              from="other"
              item={
                getCart_data?.order_lines[getCart_data?.order_lines?.indexOf(x)]
              }
            />
          </div>

          <br />
          {x?.promotion_lines && (
            <div className="hello">
              <div className="product_name_wrap d-flex mobile-promo">
                {x?.promotion_lines[0]?.is_delivery_line === true ? (
                  <img src={Image.Delivery} />
                ) : (
                  <img
                    src={
                      x?.promotion_lines[0]?.reward_type === "product"
                        ? x?.promotion_lines[0]?.product_image
                        : Image.PromotionTag
                    }
                    className={
                      x?.promotion_lines[0]?.reward_type === "product"
                        ? ""
                        : "promo-tag"
                    }
                  />
                )}

                <div className="mt-2 d-flex flex-column gap-1">
                  <div style={{ width: "80%" }}>
                    <TextCom weight="xl">{x?.name}</TextCom>
                    {x?.promotion_lines[0]?.code && (
                      <TextCom weight="xl">
                        SKU: <span>{x?.promotion_lines[0]?.code}</span>
                      </TextCom>
                    )}
                    <TextCom>{x?.promotion_lines[0]?.remark}</TextCom>
                    {x?.promotion_lines[0]?.package_items?.length > 0 &&
                      x?.promotion_lines[0]?.package_items?.map(
                        (item: any, key: any) => (
                          <TextCom weight="">
                            {key + 1} : {item?.name}
                          </TextCom>
                        )
                      )}
                  </div>
                  <TextCom
                    weight="lg"
                    size="sm"
                    textAlign="center"
                    color="primary_light2"
                    className={
                      x?.promotion_lines[0]?.reward_type === "product"
                        ? "product-free"
                        : ""
                    }
                    style={
                      x?.promotion_lines[0]?.reward_type === "product"
                        ? {
                            opacity: "1",
                            width: "fit-content",
                            marginLeft: "10px",
                          }
                        : { opacity: "0" }
                    }
                  >
                    {translate("free", "Free")}
                  </TextCom>
                  {/* </div> */}
                </div>
              </div>
              <div className="btn-con hello d-flex">
                {x?.promotion_lines[0]?.price_subtotal !== 0 ? (
                  <TextCom
                    weight="xl"
                    size="lg"
                    color="primary_light2"
                    style={{ marginLeft: "10px" }}
                  >
                    {moneyFormat(
                      Math.ceil(x?.promotion_lines[0]?.price_subtotal),
                      langCode
                    )}{" "}
                    {translate("ks", "Ks")}
                  </TextCom>
                ) : (
                  <TextCom weight="xl" size="lg" color="secondary">
                    {" "}
                  </TextCom>
                )}

                <div className="position-relative">
                  <div
                    className="qty-wrap mt-2 position-absolute"
                    style={
                      x?.promotion_lines[0]?.reward_type === "product"
                        ? {
                            opacity: "1",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -70%)",
                          }
                        : {
                            opacity: "",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -70%)",
                          }
                    }
                  >
                    <TextCom>{x?.promotion_lines[0]?.quantity}</TextCom>
                  </div>
                  <div className="qty-wrap" style={{ visibility: "hidden" }}>
                    <QtyButtonMod
                      type="cartlist"
                      c_index={getCart_data?.order_lines?.indexOf(x)}
                      item={x}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ),
    },
  ];

  const handleDelete: any = async (
    type?: string,
    handle?: boolean,
    id?: number,
    package_type?: any
  ) => {
    if (type === "delete-one-item") {
      await dispatch(
        CartAction.deleteCart({
          order_id: createCart_data?.order_id,
          product_id: isModal?.id,
          type: isModal?.package_type ? isModal?.package_type : "",
        })
      );
    }
    if (type === "delete-all-item") {
      await dispatch(
        CartAction.deleteAllInCart({ order_id: createCart_data?.order_id })
      );
    }
    setIsModal({
      ...isModal,
      visible: handle,
      type: `delete-${type}-item`,
      id,
      package_type,
    });
  };

  //point
  let pointbalance = getProfile_data?.point;
  let [redeempoint, setRedeemPoint] = useState(
    getCart_data?.discount_point || 0
  );

  useEffect(() => {
    dispatch(CartAction.getCart());
    dispatch(CartAction.getPointRate());
  }, []);

  const onHandlePointSubmit = async (value: any) => {
    let pos_obj = {
      redeem_point: value?.point,
    };
    let res = await dispatch(CartAction.postRedeemPointToOrder(pos_obj));
    if (res?.payload?.status === "success") {
      setRedeemPoint(-value?.point);
    } else {
      let error_msg = document.querySelector(".error");
      setRedeemPoint(0);
      if (error_msg) {
        error_msg?.setAttribute(
          "style",
          `
        display:block;
      `
        );
        setTimeout(() => {
          error_msg?.setAttribute(
            "style",
            `
            display:none;
          `
          );
        }, 4000);
      }
    }
  };
  const initialValues = {
    point: "",
  };

  const Checkout = () => {
    if (getCart_data?.total < -getCart_data?.discount_point) {
      setIsexceedPoint(true);
    } else {
      setIsexceedPoint(false);
      if (
        getShippingAddressById_data &&
        getShippingAddressById_data !== null &&
        getShippingAddressById_data[0]
      ) {
        navigate(`../order/payment`);
      } else {
        navigate(`../order/checkout`);
      }
    }
  };
  let discount_amount: any = Math.ceil(getCart_data?.discount_amount);

  let breadcrumb_data = [
    {
      name: translate("home", "Home"),
      route: "/",
    },
    {
      name: translate("shopping-cart", "Shopping Cart"),
      route: "#",
    },
  ];

  const cart_state = useSelector((state: any) => state.cart);

  return (
    <>
      <BreadcrumbCom
        data={breadcrumb_data}
        isContainer={true}
        bgColor="primary_light3"
      />
      <StyledCartCon>
        <Container className="shopping-cart-con container">
          <div className="cart-con">
            {getCart_data?.order_lines?.length > 0 ? (
              <>
                <div className="cart-table">
                  <TextCom size="xxxl" weight="xl" className="title">
                    {translate("shopping-cart", "Shopping Cart")}
                  </TextCom>
                  <Media query={{ minWidth: 992 }}>
                    {(matches) => (
                      <TableCom
                        dataSource={getCart_data?.order_lines}
                        columns={matches ? columns : mb_columns}
                      />
                    )}
                  </Media>
                  {getCart_data?.order_lines?.length > 0 && (
                    <ButtonCom
                      text={translate("clear-cart", "Clear Cart")}
                      type="outline"
                      bgcolor="transparent"
                      btnBorderRadius="xxs"
                      onClick={() => handleDelete("all", true)}
                      SVGL={<RiDeleteBinLine size="23" color="#F41A1D" />}
                      className="clear-btn"
                    />
                  )}
                </div>
                {/* <div className="cart-total">
                    {getProfile_data?.name ? (
                      <>
                        <TextCom weight="xl" className="title">
                          {translate(
                            "redeem-reward-points-here",
                            "Redeem reward points here"
                          )}
                        </TextCom>
                        <TextCom weight="xl">
                          {translate("point-balance", "Point Balance")} :{" "}
                          {pointbalance} {translate("pts", "Pts")} ({pointbalance}{" "}
                          {translate("ks", "Ks")})
                        </TextCom>

                        <TextCom size="sm" color="secondary">
                          {translate(
                            "maximum-points-redeemable",
                            "Maximum points redeemable"
                          )}{" "}
                          :{" "}
                          {pointbalance <
                          getCart_data?.untax_amount - (discount_amount || 0)
                            ? pointbalance
                            : getCart_data?.untax_amount - (discount_amount || 0)}
                        </TextCom>
                        {redeempoint === 0 && (
                          <div className="point-input">
                            <FormContainerCom
                              initialValues={initialValues}
                              validationSchema={(yup: any) =>
                                yup.object({
                                  point: yup
                                    .number()
                                    .max(
                                      getCart_data?.untax_amount -
                                        (discount_amount || 0),
                                      "your redemmable limit is exceed."
                                    )
                                    .min(0, "Redeem point must greater than 0")
                                    .integer("Redeem point must be Integer"),
                                })
                              }
                              onSubmit={(value: any) =>
                                value?.point > 0 && onHandlePointSubmit(value)
                              }
                            >
                              {(formikProps: any) => {
                                return (
                                  <div className="d-flex">
                                    <InputCom
                                      placeholder={translate(
                                        "point-to-redeem",
                                        "point to redeem"
                                      )}
                                      name="point"
                                      type="number"
                                    />
                                    <ButtonCom
                                      type="submit"
                                      text={translate("redeem", "Redeem")}
                                      bgColor="dark"
                                      color="light"
                                      btnBorderRadius="xxxs"
                                    />
                                  </div>
                                );
                              }}
                            </FormContainerCom>
                            <TextCom className="error" size="xs" color="danger">
                              {postRedeemPointToOrder_data?.message}
                            </TextCom>
                          </div>
                        )}
                        <div className="sub-total d-flex">
                          <TextCom size="xl" weight="xl">
                            {translate("subtotal", "Sub Total")}
                          </TextCom>
                          <TextCom size="xl" weight="xl">
                            {moneyFormat(Math.ceil(getCart_data?.untax_amount))}
                            {translate("ks", "Ks")}
                            <AiFillCloseCircle
                              color="#707070"
                              size={35}
                              style={{ paddingLeft: "8px", opacity: "0" }}
                            />
                          </TextCom>
                        </div>
                        <div className="tax d-flex justify-content-between">
                          <TextCom size="xl" weight="xl">
                            {translate("tax", "Tax")}
                          </TextCom>
                          <TextCom size="xl" weight="xl">
                            {moneyFormat(Math.ceil(getCart_data?.tax))}
                            {translate("ks", "Ks")}
                            <AiFillCloseCircle
                              color="#707070"
                              size={35}
                              style={{ paddingLeft: "8px", opacity: "0" }}
                            />
                          </TextCom>
                        </div>
                        {redeempoint < 0 && (
                          <div className="redeem d-flex align-items-center">
                            <TextCom size="xl" weight="xl">
                              {translate("redeem-discount", "Redeem Discount")}
                            </TextCom>
                            <TextCom
                              size="xl"
                              weight="xl"
                              style={{ flexWrap: "wrap" }}
                            >
                              {redeempoint}
                              {translate("ks", "Ks")}
                              <AiFillCloseCircle
                                color="#707070"
                                size={35}
                                style={{ paddingLeft: "8px", cursor: "pointer" }}
                                onClick={() => onHandlePointSubmit({ point: 0 })}
                              />
                            </TextCom>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="total d-flex">
                          <TextCom size="xxl" weight="xl">
                            {translate("subtotal", "Subtotal")}
                          </TextCom>
                          <TextCom size="xxl" weight="xl">
                            {moneyFormat(Math.ceil(getCart_data?.untax_amount))}
                            {translate("ks", "Ks")}
                          </TextCom>
                        </div>
                        <div className="tax d-flex justify-content-between">
                          <TextCom size="xl" weight="xl">
                            {translate("tax", "Tax")}
                          </TextCom>
                          <TextCom size="xl" weight="xl">
                            {moneyFormat(Math.ceil(getCart_data?.tax))}
                            {translate("ks", "Ks")}
                          </TextCom>
                        </div>
                      </>
                    )}
                    {getCart_data?.discount_percent > 0 && (
                      <div className="tax d-flex justify-content-between">
                        <TextCom size="xl" weight="xl">
                          {translate("discount-amount", "Discount Amount")}(
                          {getCart_data?.discount_percent}%)
                        </TextCom>
                        <TextCom size="xl" weight="xl">
                          -{moneyFormat(Math.ceil(getCart_data?.discount_amount))}
                          {translate("ks", "Ks")}
                          {getProfile_data && (
                            <AiFillCloseCircle
                              color="#707070"
                              size={35}
                              style={{ paddingLeft: "8px", opacity: "0" }}
                            />
                          )}
                        </TextCom>
                      </div>
                    )}
                    <hr />
                    <div className="total d-flex mb-3">
                      <TextCom size="xxxl" weight="xl">
                        {translate("total", "Total")}
                      </TextCom>
                      <TextCom size="xxxl" weight="xl">
                        {moneyFormat(Math.ceil(getCart_data?.total))}
                        {translate("ks", "Ks")}
                      </TextCom>
                    </div>
                    {getProfile_data?.name && (
                      <div className="point-earned d-flex">
                        <div className="d-flex align-items-center">
                          <TbCrown size={30} color="#F27043" fill="#F27043" />
                          <TextCom color="secondary" weight="xl">
                            {translate("points-earned", "Points earned")}
                          </TextCom>
                        </div>
                        <TextCom color="light" weight="xl" className="point">
                          {getCart_data?.reward_point}
                        </TextCom>
                      </div>
                    )}
                    {getProfile_data && langCode === "en" ? (
                      <TextCom size="xs">
                        1 point will be awarded for every transaction of{" "}
                        {getPointRate_data?.ks_per_point} kyats
                      </TextCom>
                    ) : (
                      getProfile_data && (
                        <TextCom size="xs">
                          Transaction အားလုံးကို ၁ပွိုင့်လျှင်{" "}
                          {getPointRate_data?.ks_per_point}ကျပ် ဖြင့်သတ်မှတ်ပါသည်။
                        </TextCom>
                      )
                    )}

                    <ButtonCom
                      color="light"
                      bgColor="dark"
                      text={translate("proceed-to-checkout", "PROCEED TO CHECKOUT")}
                      btnBorderRadius="xxxs"
                      onClick={() => Checkout()}
                    />
                  </div> */}
                <div className="total">
                  <CardCom className="total-card flex-column">
                    <div className="d-flex justify-content-between mb-3">
                      <TextCom size="xxl" weight="xl">
                        {translate("total", "Total")}
                      </TextCom>
                      <TextCom size="xxl" weight="xl" color="primary_light2">
                        {moneyFormat(Math.ceil(getCart_data?.total), langCode)}
                        {translate("ks", "Ks")}
                      </TextCom>
                    </div>
                    {/* <hr /> */}
                    <ButtonCom
                      color="light"
                      bgcolor="primary_light2"
                      text={translate(
                        "proceed-to-checkout",
                        "PROCEED TO CHECKOUT"
                      )}
                      btnBorderRadius="xxxs"
                      onClick={() => Checkout()}
                    />
                  </CardCom>
                </div>
              </>
            ) : (
              <div className="no-item">
                <TextCom size="xxxl" weight="xl" className="title">
                  {translate("shopping-bag", "Shopping Bag")}
                </TextCom>
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src={Image.EmptyCart}
                    alt="empty-bag"
                    className="img-fluid"
                  />
                  <TextCom size="xl" weight="xl">
                    {translate("sorry", `Sorry`)}!
                  </TextCom>
                  <TextCom>
                    {translate("cart-empty", `Your cart is empty.`)}
                  </TextCom>
                  <ButtonCom
                    text={translate("continue-shopping", "Continue Shopping")}
                    bgColor="dark"
                    color="light"
                    onClick={() => navigate("/")}
                    btnBorderRadius="xxxs"
                  />
                </div>
              </div>
            )}
            <ModalCom isModal={isModal.visible} handleIsModal={setIsModal}>
              <div className="inner_modal_con">
                <FaQuestion size={30} />
                <TextCom className="desc_txt">
                  {isModal?.type === "delete-all-item" ? (
                    <>
                      {translate(
                        "delete-all-items",
                        "Are you sure you want to clear all cart items"
                      )}
                      ?
                    </>
                  ) : (
                    <>
                      {" "}
                      {translate(
                        "delete-this-item",
                        "Are you sure you want to delete this item"
                      )}
                      ?
                    </>
                  )}
                </TextCom>
                <div className="modal_btn_con d-flex">
                  <ButtonCom
                    text={translate("cancel", "Cancel")}
                    bgcolor="primary_light2"
                    color="light"
                    type="outline"
                    onClick={() => setIsModal(false)}
                    btnBorderRadius="xxxs"
                  />
                  <ButtonCom
                    text={translate("delete", "Delete")}
                    color="light"
                    bgcolor="primary_light2"
                    btnBorderRadius="xxxs"
                    onClick={() => handleDelete(`${isModal?.type}`, false)}
                  />
                </div>
              </div>
            </ModalCom>
            <ModalCom
              isModal={isexceedPoint}
              handleIsModal={setIsexceedPoint}
              isCloseBtn={true}
            >
              <div className="d-flex flex-column justify-content-center align-items-center gap-3">
                <BsExclamationCircle
                  size={40}
                  style={{ marginBottom: "10px" }}
                />
                <TextCom>
                  {translate(
                    "redeem-point-must-not-greater-than-total-amount",
                    "Redeem point must not greater than Total amount"
                  )}
                </TextCom>
                <ButtonCom
                  className="d-flex align-items-center justify-content-center"
                  btnBorderRadius="xxxs"
                  bgcolor="dark"
                  color="light"
                  text="Ok"
                  onClick={() => setIsexceedPoint(false)}
                />
              </div>
            </ModalCom>
          </div>
        </Container>
      </StyledCartCon>
    </>
  );
};
