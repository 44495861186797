import styled from 'styled-components'

type StyledProductListingProps = {}

export const StyledProductListing = styled.div < StyledProductListingProps > `
  .title-section,.title {
    margin-bottom: 20px;
    border-bottom: 1px solid ${props => props?.theme?.color?.typo?.line || '#ddd'};
    h1{
        border-bottom: 2px solid ${props => props?.theme?.color?.typo?.primary_light2};
        padding-bottom: 15px;
        width: fit-content;
        margin-bottom: -1px;
    }
  }
  .title-section{
    @media(max-width: 600px){
      h1{
        width: 50%;
        line-height: 26px;
      }
    }
  }
 padding-bottom: 70px;
 .p-card{
  @media(max-width: 425px){
    padding-inline: 8px;
  }
 }
     
 .row{
  .filter-btn{
    display: none;
  }
  @media(max-width: 991px){
    position: relative;
    .filter-btn{
      display: flex;
      margin: 0;
      min-width: fit-content;
      min-height: fit-content;
    }
    button.filter-btn{
      margin-bottom: 15px;
      padding-inline: 20px;
      /* padding: 4px 5px; */
      border: 1px solid ${props => props?.theme?.color?.button?.gray1 || '#ddd'};
    }
    .filter-wrap{
      display: none;
      &.open{
        display: block;
      }
    }
  }
 }

 .slider{
   padding-block: 70px;
   .large-card-slider{
      .slider-card{
        padding-inline: 10px;
        .product-card{
          gap: 20px;
          .description{
            /* p{ height: auto;} */
          }
        }
      }
   }
   .prev,.next{
    top:-7%;
   }
   @media(max-width: 768px){
    padding-top: 30px;
    padding-bottom: 10px;
    .prev,.next{
      top: -40px;
      img{ 
        width: 30px;
        height: 30px;
      }
    }
   }
 }
 .product{
    .row{
      padding-top: 30px;
      /* padding-block: 70px;
      position: relative; */
      @media(max-width: 991px){
        padding: 0;
      }
    .filter-con{
      position: relative;
      @media(max-width: 991px){
        margin-top: 12px;
      }
      .filter-btns{
          button{
            min-width: fit-content;
            border-color: ${props => props?.theme?.color?.typo?.border};
            @media(min-width: 992px){
              display: none;
            }
            img{
              width: 27px;
              height: 27px;
            }
            svg{ margin-right: 10px;}
            border-color: ${props => props?.theme?.color?.typo?.line || '#d9d9d9'};
            min-height: 45px;
            p{
              text-transform: uppercase;
              @media(max-width: 425px){
                font-size: ${props => props?.theme?.fontSize?.md}px;
              }
            }
            @media(max-width: 425px){
              padding: 8px;
            }
          }
          @media(max-width: 991px){
            position: relative;
            margin-bottom: 0px;
            button{
              border: 1px solid ${props => props?.theme?.color?.typo?.line || '#d9d9d9'};
            }
          }
      }

      .clear-btn{
        position: absolute;
        top: 2px;
        right: 12px;
        min-width: fit-content;
        min-height: fit-content;
        text-transform: unset!important;
        padding: 0;
        color: ${props => props?.theme?.color?.border || '#707070'};
        @media(max-width: 991px){
          top: 70px;
          display: none;
        }
        @media(max-width: 1400px){
          font-size: ${props => props?.theme?.fontSize?.sm}px;
        }
      }

      .filter-by{
        justify-content: space-between;
        .d-flex{
          align-items: center;
          svg{ margin-right: 10px;}
          p{ 
            text-transform: uppercase;
          }
          img{ 
              width: 27px;
              height: 27px;
            }
          @media(max-width: 1400px){
            svg{ 
              margin-right: 5px;
            }
            p{
              font-size: ${props => props?.theme?.fontSize?.sm + 1}px;}
          }
        }
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid ${props => props?.theme?.color?.typo?.line || '#d9d9d9'};
        @media(max-width: 991px){
          display: none!important;
          margin-top: 20px;
        }
        button{
          border: none;
          min-width: fit-content;
          padding: 0;
          color: ${props => props?.theme?.color?.typo?.border || '#d9d9d9'};
        }
      }

      .collapse-con{
        hr{
          width: 100%;
          height: 1px;
          color: ${props => props?.theme?.color?.typo?.border || '#d9d9d9'};
        }
        .collapse-item{
          padding-left: 10px;
          .collapse-content{
            padding: 0!important;
          }
        }
        .collapse-header{
          padding-block: 5px ;
          .collapse-header-wrap{
            a{ 
              color: ${props => props?.theme?.color?.typo?.text || '#333'};
              text-decoration: none;
            };
            .collapse-expand-icon .arrow{
              /* border-top: 2px solid #262626;
              border-left: 2px solid #262626; */
            }
          }
        }

        .multi-collapse{
          margin-block: 5px;
          button{
            padding-inline: 10px;
            padding-block: 8px;
            min-width: fit-content;
            min-height: 35px;
            display: inline-block;
            border: 1px solid ${props => props?.theme?.color?.typo?.line || '#d9d9d9'};
            margin-right: 10px;
            margin-bottom: 10px;
            p{ line-height: 0;}
            &.selected{
              border-color: ${props => props?.theme?.color?.typo?.primary || '#0491A4'};
            }
          }
        }
        .price-collapse{
          margin-block: 5px;
        }

        @media(max-width: 991px){
          display: none;
        }
      }
      &.open{
        .collapse-con{
          display: block!important;
        }
        .filter-by{
          display: flex!important;
        }
        .clear-btn{
          display: block;
        }
      }
    }
    .product-list-con{
      @media(min-width: 992px){
        position: relative;
      }
      /* @media(min-width: 1200px){
        padding-left: 50px;
      } */
      .prev,.next{
        img{
          box-shadow: none!important;
          border-radius: 0px!important;
        }
      }
      .title-section{
        min-height: 50px;
        @media(max-width: 600px){
          min-height: 43px;
        }
        .sort-by{
          /* position: relative; */
          @media(max-width: 991px){
            margin-right: 12px;
          }
          .select-input{
            position: relative;
            width: 150px;
          }
          select {
            padding-right: 20px;
            -webkit-appearance: none;
            -moz-appearance: none;
            text-indent: 1px;
            text-overflow: '';
            background: transparent;
            position: absolute!important;
            top: -20px;
            z-index: 2;
            cursor: pointer;
            border: 1px solid ${props => props?.theme?.color?.button?.gray1 || '#ddd'};
          }
          .d-arrow{
            position: absolute;
            right: 5px;
            z-index: 1;
            transform: ${props => !props?.['aria-expanded'] ? 'rotate(180deg)' : 'rotate(0)'};.sort-by
            transition: all .2s ease-in-out;
          }
          @media(max-width: 991px){
            top: 20px;
          }
          @media(max-width: 359px){
            top: 10px;
          }
        }
        &.brand{
          position: relative;
          .title{
            /* border: none; */
          }
          .sort-by{
            @media(max-width: 991px){
              top: 10px;
            }
          }
        }
      }
      .title{
        @media(min-width: 992px){
          margin-top: -12px;
        }
      }
      .sort-by{
            position: absolute;
            top: 10px;
            right: 12px;
            gap: 8px;
            p{ 
              min-width: fit-content;
              @media(max-width: 425px){
                margin-right: -30px;
              }
              @media(max-width: 768px){
                opacity: 0;
                visibility: hidden;
              }
            }
            select{
              border-radius: 5px;
              min-height: 45px;
              @media(max-width: 425px){
                max-width: 120px;
                margin-left: 30px;
              }
            }
            @media(max-width: 359px){
              flex-direction: column;
              margin-top: -10px;
              align-items: flex-end!important;
            }
            svg{
              top: 8px;
              @media(max-width: 359px){
                top: 27px!important;
              }
            }
      }
      &.brand{
        h1{
          @media(max-width: 425px){
            font-size: 16px;
            padding-bottom: 10px;
          }
        }
        section.brand{
          margin-bottom: 30px;
          /* @media(min-width: 1024px){
            margin-bottom: 50px;
          } */
          .slick-slide{
            .active{
              border: 1px solid ${props => props?.theme?.color?.card?.primary_light2 || '#ddd'};
              border-radius: 9px;
            }
          }
          img{
              box-shadow: 0px 0px 5px ${props => props?.theme?.color?.typo?.line || '#eee'};
              border-radius: 5px;
          }
          .slick-slider.slick-initialized .slick-slide{
            padding: 8px;
          }
          @media(max-width: 425px){
            .prev,.next{
              svg{
                width: 25px;
                height: 25px;
              }
            }
            .prev{
              left: -14px!important;
            }
            .next{
              right: -12px!important;
            }
          }
        }
        .title-section{
          position: relative;
          .d-arrow{
              position: absolute;
              top: 18px;
              right: 10px;
              transform: ${props => !props?.['aria-expanded'] ? 'rotate(180deg)' : 'rotate(0)'};
              transition: all .2s ease-in-out;
              @media(max-width: 991px){
                right: 25px;
              }
              @media(max-width: 359px){
                top: 40%;
              }
            } 
          .sort-by{
            width: fit-content;
            right: 150px;
            select{
              width: 150px;
              background: transparent;
            }
          }
        }
        @media(min-width: 992px){
          .prev{
          left: -30px;
          }
          .next{
            right: -30px;
          }
        }
        @media(max-width: 991px){
          .prev,.next{
            img{
              width: 30px;
              height: 30px;
            }
          }
        }
      }
      .product-con{
        margin-top: 20px;
        .row{
          row-gap: 20px;
          padding-block: 0;
        }
      }
      .pagination-con{
        /* border: 1px solid red;
        position: absolute;
        bottom: -80px; */
        @media(max-width: 991px){
          // display: none;
        }
      }
    }
    }
 }

 .hero-slider{
  .prev{
    left: 30px;
    opacity: 0.5;
  }
  .next{
    right: 30px;
    opacity: 0.5;
  }
 }

 .filter-card{
  border: 1px solid ${props => props?.theme?.color?.typo?.border2 || '#eee'};
  border-radius: 5px;
  margin-bottom: 40px;
  @media(max-width: 768px){
    margin-bottom: 20px;
  }
  display: block;
  .title{
    width: 100%;
  }
  .check-item-wrap{
    margin-bottom: 15px;
    p{
      margin-top: 4px;
      margin-left: 4px;
    }
  }
  .filter-item-wrap{
    button{
      padding-block: 0;
      padding-inline: 10px;
      div{
        margin: 0;
      }
      min-width: fit-content;
      min-height: 35px!important;
      p{display: none;}
    }
    .field-wrapper{
      width: 100%;
    }
  }
 }

`

type StyledCategoryListProps = {}
export const StyledCategoryList = styled.div < StyledCategoryListProps > `
  .title {
            margin-bottom: 20px;
            border-bottom: 1px solid ${props => props?.theme?.color?.typo?.line || '#ddd'};
            h1{
                border-bottom: 2px solid ${props => props?.theme?.color?.typo?.primary};
                padding-bottom: 15px;
                width: fit-content;
                margin-bottom: -1px;
            }
            margin-bottom: 50px;
            }
  .category-list{
    padding-bottom: 50px;
    .row{
      row-gap: 30px;
      .product-card{
        p{
          font-size: ${props => props?.theme?.fontSize?.lg}px;
        }
        .eye-con{
          display: none;
        }
        .image-promo{
          border-radius: 0;
        }
      }
    }

    .hero-slider{
      .prev{
        left: 30px;
        opacity: 0.5;
      }
      .next{
        right: 30px;
        opacity: 0.5;
      }
    }
  }
`