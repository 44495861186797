import React from "react";

import { QtyButtonCom, TextCom } from "../../common";
import { useHandleQty } from "hook";

type QtyButtonModProps = {
  type?: string;
  item?: any;
  w_index?: number;
  c_index?: number;
  disabled?: boolean;
  btnbordercolor?: any;
  className?: any;
  uid?: any;
};
export const QtyButtonMod: React.FC<QtyButtonModProps> = ({
  type = "productbyid",
  item,
  w_index = 0,
  c_index = 0,
  uid,
  ...props
}) => {
  const [handleQty, handleQtyFun] = useHandleQty({
    type,
    item,
    w_index,
    c_index,
    uid,
  });
  return (
    <>
      <QtyButtonCom
        qty={handleQty}
        handleQtyFun={handleQtyFun}
        setqty={(qty: any, e:any) => {
          handleQtyFun(+qty, uid)
          }
        }
        type={type}
        w_index={w_index}
        {...props}
        uid={uid}
      />
      {/* {handleQty?.error && (
        <TextCom size="xs" color="danger">
          {handleQty?.error}
        </TextCom>
      )} */}
    </>
  );
};
