import React, { useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import {
  Container,
  TextCom,
  ProductCard,
  ProductCardMap,
  BreadcrumbCom,
  ButtonCom,
} from "components";
import "./style.scss";
import { useProduct, useHandleOther } from "hook";
import { StyledProductGroupDetail } from "theme";
import { Title } from "components";

export type ProductGroupDetailProps = {};

export const ProductGroupDetail: React.FC<ProductGroupDetailProps> = (
  props
) => {
  const {
    getP,
    ProductAction,
    getPromotionById_data,
    dispatch,
    getProductGroup_data,
  } = useProduct();
  const { translate, langStore } = useHandleOther();
  const param = useParams();
  const langCode = langStore?.code;
  const navigate = useNavigate();
  const loc = useLocation();

  let name: any = null;
  let item: any = null;
  if (getProductGroup_data && param?.code) {
    name = getProductGroup_data?.data?.filter(
      (item: any) => item?.code === param?.code
    )[0].name;
    item = getProductGroup_data?.data?.filter(
      (item: any) => item?.code === param?.code
    )[0].item;
  }

  let breadcrumb_data = [
    {
      name: translate("home", "Home"),
      route: "/",
    },
    {
      name: loc?.state?.name ? loc?.state?.name : name,
      route: "#",
    },
  ];

  return (
    <>
      <BreadcrumbCom
        data={breadcrumb_data}
        isContainer={true}
        bgColor="primary_light3"
      />
      <StyledProductGroupDetail className="container group-detail">
        {loc?.state ? (
          <div className="row">
            <div className="title-section">
              <div className="wrap">
                <TextCom as="h1" size="xxxl" weight="xl" className="title">
                  {loc?.state?.name}
                </TextCom>
              </div>
            </div>
            <ProductCardMap
              data={loc?.state?.item}
              className="col-lg-3 col-sm-4 col-6 p-card"
            />
          </div>
        ) : (
          getProductGroup_data && (
            <div className="row">
              <div className="title-section">
                <TextCom as="h1" size="xxxl" weight="xl" className="title">
                  {name}
                </TextCom>
              </div>
              <ProductCardMap
                data={item}
                className="col-lg-3 col-sm-4 col-6 p-card"
              />
            </div>
          )
        )}
      </StyledProductGroupDetail>
    </>
  );
};
