import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Media from "react-media";
import moment from "moment";
import {
  FiUser,
  FiHeart,
  FiFileText,
  FiExternalLink,
  FiInfo,
} from "react-icons/fi";

import {
  TextCom,
  ButtonCom,
  Container,
  NewTabCom,
  CardCom,
  PaginationWrap,
  StaticSidebar,
  BreadcrumbCom,
  TableCom,
  InputCom,
} from "components";
import { useOther, useHandleOther, useCart } from "hook";
import { StyledOrderlistCon } from "theme";
import Icon from "asset/icon/pladco";
import { moneyFormat, splitedTime } from "utils";
import "./style.scss";

type IOrderListProps = {};

export const OrderList: React.FC<IOrderListProps> = (props) => {
  const { translate, langStore } = useHandleOther();
  const { getContactUsPage_data, getMetaData_data } = useOther();
  const { getOrder_data, CartAction, dispatch, getOrderStatus_data } =
    useCart();
  const navigate = useNavigate();
  let [page, setPage] = useState({
    offset: 1,
    limit: 10,
  });

  const langCode = langStore?.code;

  useEffect(() => {
    dispatch(CartAction.getOrderStatus());
    dispatch(
      CartAction.getOrder({
        offset: page.offset,
        limit: page.limit,
        lang: langStore?.code,
      })
    );
  }, [page]);

  const onChangePaginate = (pagenumber: any) => {
    setPage({ ...page, offset: pagenumber });
    window.scroll(0, 0);
  };

  const sidebar_data = [
    {
      name: translate("my-profile", "My Profile"),
      link: "/user/profile",
    },
    {
      name: translate("my-order", "My Order"),
      link: "/user/my_orders",
    },
    {
      name: translate("my-wishlist", "My Wishlist"),
      link: "/user/wishlist",
    },
    {
      name: translate("change-password", "My Wishlist"),
      link: "/user/change_password",
    },
    {
      name: translate("logout", "Logout"),
      link: "logout",
    },
  ];

  let breadcrumb_data = [
    {
      name: translate("home", "Home"),
      route: "/",
    },
    {
      name: translate("my-orders", "My Orders"),
      route: "#",
    },
  ];

  const columns = [
    {
      title: translate("order-no", "Order No."),
      key: "order-no",
      width: 200,
      textAlign: "center",
      render: (x: any, y: any) => (
        <div className="product_name_wrap d-flex justify-content-center">
          <TextCom>{x?.name}</TextCom>
        </div>
      ),
    },
    {
      title: translate("order-date", "Order Date"),
      key: "order-no",
      width: 300,
      textAlign: "center",
      render: (x: any, y: any) => (
        <div className="product_name_wrap d-flex justify-content-center">
          <TextCom>{moment(x?.date).format("DD/MM/YYYY")}</TextCom>
        </div>
      ),
    },
    {
      title: translate("total-amount", "Total Amount"),
      key: "order-no",
      width: 300,
      textAlign: "center",
      render: (x: any, y: any) => (
        <div className="product_name_wrap d-flex justify-content-center">
          <TextCom size={'lg'}>
            {moneyFormat(x?.amount, langCode)} 
            <TextCom as='span' size={langCode === 'en' ? 'lg' : 'md'} style={{ marginLeft:'5px'}}>{translate("ks", "Ks")}</TextCom>
          </TextCom>
        </div>
      ),
    },
    {
      title: translate("status", "Status"),
      key: "order-no",
      width: 200,
      textAlign: "center",
      render: (x: any, y: any) => (
        <div className="product_name_wrap d-flex justify-content-center">
          <TextCom color="primary_light2">
            {translate(x?.status, x?.status)}
          </TextCom>
        </div>
      ),
    },
    {
      key: "detail",
      width: 100,
      textAlign: "center",
      render: (x: any, y: any) => (
        <div className="product_name_wrap d-flex justify-content-center">
          <ButtonCom
            bgcolor="primary_light2"
            // color=''
            onClick={() => navigate(`../user/my_orders/${x.id}`)}
            style={{
              minWidth: "fit-content",
              paddingBlock: "5px",
              minHeight: "fit-content",
              textTransform: "unset",
            }}
          >
            {translate("detail", "Detail")}
          </ButtonCom>
        </div>
      ),
    },
  ];

  const mb_columns = [
    {
      title: translate("order", "Order"),
      width: 300,
      render: (x: any, y: any) => (
        <div
          className="product_name_wrap mb-order"
          style={{ paddingLeft: "10px" }}
        >
          <TextCom>{x?.name}</TextCom>
          <TextCom>{moment(x?.date).format('DD/MM/YYYY')}</TextCom>
          <TextCom weight="xl" size={'lg'}>
            {translate("total", "Total")} : {moneyFormat(x?.amount,langCode)} <TextCom as='span' weight={'xl'} size={langCode==='en'?'lg':'sm'}>{translate("ks", "Ks")}</TextCom>
          </TextCom>
        </div>
      ),
    },
    {
      title: translate("status", "Status"),
      key: "status",
      width: 200,
      textAlign: "center",
      render: (x: any, y: any) => (
        <div className="product_name_wrap d-flex justify-content-center status">
          <TextCom color="primary_light2">
            {translate(x?.status, x?.status)}
          </TextCom>
        </div>
      ),
    },
    {
      key: "detail",
      width: 100,
      textAlign: "center",
      render: (x: any, y: any) => (
        <div className="product_name_wrap d-flex justify-content-center">
          <ButtonCom
            bgcolor="primary_light2"
            // color=''
            onClick={() => navigate(`../user/my_orders/${x.id}`)}
            style={{
              minWidth: "fit-content",
              paddingBlock: "5px",
              minHeight: "fit-content",
              textTransform: "unset",
            }}
          >
            {translate("detail", "Detail")}
          </ButtonCom>
        </div>
      ),
    },
  ];

  const handleSelectChange = (e: any) => {
    dispatch(
      CartAction.getOrder({
        offset: page.offset,
        limit: page.limit,
        status: Number(e.target.value),
        lang: langStore?.code,
      })
    );
  };

  return (
    <>
      <BreadcrumbCom
        data={breadcrumb_data}
        isContainer={true}
        bgColor="primary_light3"
      />
      <StyledOrderlistCon>
        <Container className="orderList-con container">
          <div className="row orderlist-row">
            <div className="col-md-12 col-lg-3">
              <StaticSidebar
                data={sidebar_data}
                route=""
                classname="side-bar"
              />
            </div>
            <div className="col-md-12 col-lg-9 order-history-content">
              <div className="title">
                <TextCom size="xxl" weight="lg">
                  {translate("order-history", "Order History")}
                </TextCom>
              </div>
              {getOrderStatus_data && (
                <div className="status-filter d-flex align-items-center gap-3 mb-3">
                  <TextCom weight="lg">
                    {translate("order-status", "Order Status")}
                  </TextCom>
                  <InputCom
                    isValidate={false}
                    input_as="select"
                    onChange={(e: any) => handleSelectChange(e)}
                    className="select-input"
                  >
                    <option value="">{translate("all", "All")}</option>
                    {getOrderStatus_data?.map((item: any, key: any) => {
                      return (
                        <option value={item?.id} key={key}>
                          {item?.name}
                        </option>
                      );
                    })}
                  </InputCom>
                </div>
              )}
              {getOrder_data && getOrder_data?.order?.length > 0 ? (
                <div className="table-con">
                  <Media query={{ minWidth: 992 }}>
                    {(matches) => (
                      <TableCom
                        dataSource={getOrder_data?.order}
                        columns={matches ? columns : columns}
                      />
                    )}
                  </Media>
                </div>
              ) : (
                <div className="no-item">
                  <div className="d-flex justify-content-center align-items-center flex-column gap-3">
                    <FiInfo size="60" color="#CBCBCB" />
                    <TextCom>
                      {translate(
                        "empty-order-information",
                        `There's no Order History`
                      )}{" "}
                      !
                    </TextCom>
                    <TextCom
                      as="a"
                      href="/"
                      className="shopping"
                      color="primary"
                    >
                      {translate("continue-shopping", "Continue Shopping")}
                    </TextCom>
                  </div>
                </div>
              )}
              {getOrder_data && (
                <div className="pagination-con">
                  <PaginationWrap
                    onChange={onChangePaginate}
                    current={page.offset}
                    total={getOrder_data?.total_count}
                    defaultPageSize={page?.limit}
                  />
                </div>
              )}
            </div>
          </div>
        </Container>
      </StyledOrderlistCon>
    </>
  );
};
