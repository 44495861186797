import styled from 'styled-components'

export const StyledCheckout = styled.div`
  iframe{
    @media(max-width: 991px){
      width: 100%!important;
    }
  }
  .modaldialog{
    min-width: 40vw;
    background-color: red;
    @media(max-width: 1024px){
      min-width: 60vw;
    }
    @media(max-width: 768px){
      min-width: 90vw;
      .modal-content{
        padding-inline: 20px;
      }
    }
    .modal-close-btn{
        right: 2%;
      }
    .wrap{
      @media(max-width: 991px){
        height: 70vh!important;
      }
    }
  }
.info{
  input,select{
    border-radius: 5px;
  }
  input,select{
    margin-bottom: 15px;
  }
}
.contact-con{
  .wrap{
    a:not(:last-child){
      &:after{
        content: ', ';
      }
    }
    a:not(:first-child){
      margin-left: 5px;
    }
  }
}
textarea{
  border: 1px solid ${props => props?.theme?.color?.form?.borderColor};
  border-radius: 5px;
}
padding-block:30px;
  .fdBWns.vlc_con{
      margin-bottom: 24px;
    }
  .checkout{
    a{cursor:pointer}
  .info-text{
    p{
      line-height: 32px;
    }
  }
  .order-summary{
    border: 1px solid ${props => props?.theme?.color?.form?.borderColor};
    border-radius: 10px;
    padding: 30px;
    height: fit-content;
    thead{
      display: none;
    }
    .tax{
      border-bottom: 1px solid ${props => props?.theme?.color?.form?.borderColor};
    }
    .tax{
      padding: 10px 0;
    }
    @media(max-width: 768px){
      margin-top: 30px;
    }
  }
  a{
     svg{
      margin-right: 8px;
    
     } 
    }
  .shipping-address-con{
    .address-con{
      border: 1px solid ${props => props?.theme?.color?.form?.borderColor};
      border-radius: 5px;
      justify-content: space-between;
      position: relative;
      padding: 15px;
      gap: 20px;
      @media(max-width: 991px){
        padding: 12px;
        gap: 12px;
      }
      align-items: center;
      /* .name{ margin-bottom: 12px;} */
      svg{ margin-right: 8px;}
      /* .ph{ margin-left: 5px;} */
      /* p{margin-bottom: 15px;} */
      p:last-child{margin-bottom: 0;}
      &.active{
        border:1px solid ${props => props?.theme?.color?.typo?.primary_light2 || '#ddd'};
      }
      .info{
        /* padding: 20px; */
        width: 100%;
        /* border: 1px solid red; */
        cursor: pointer;
      }
      .btn{
      padding: 0;
      border-left: 1px solid gray;
      border: none; 
      /* border-left: 1px solid ${props => props?.theme?.color?.form?.light_gray};  */
      border-radius: 0;
      width: fit-content;
      position: absolute;
      top: 0;
      right: 0;
      }
      button{ 
      border: none;
      min-width: 50%;
      padding: 10px;
      svg{ margin:0;}
      height: 50%;
      &:nth-child(2){
        /* border-top: 1px solid ${props => props?.theme?.color?.form?.light_gray};  */
        border-radius: 0;
    }
      }
      margin-bottom: 20px;
    }
    .add-btn{
      border-radius: 5px;
      text-transform: uppercase;
      svg{ margin-right: 8px;}
    }
    .edit-modal{
        .inner_modal_con{
          p.modal-title{ 
            text-align: unset;
            margin-bottom: 30px;
            }
          .label_txt{
            font-weight: 700;
          }
          button{ 
            text-transform: uppercase; 
            border-radius: 5px;
          }
          button:first-child{ border: none;}

          }
    }
      @media(max-width: 992px){
        margin-top: 25px;
      }
      .no-address{
        background:${props => props?.theme?.color?.container?.background};
        border: 1px solid ${props => props?.theme?.color?.typo?.text};
        border-radius: 5px;
        border-style: dashed;
        height: 130px;
      }
  }
  .disable{
    opacity: 0.6;
  }
  .select-info{
    opacity: 0;
    visibility: hidden;
  }
  input[type="radio"] {
      width: 15px;
      height: 15px;
      accent-color: ${props => props?.theme?.color?.typo?.primary};
    }
  .radio-con{
    accent-color: ${props => props?.theme?.color?.container?.primary};
    border-radius: 5px;
    label{
      width: 100%;
      cursor: pointer;
    }

    
    .payment-list{
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .p-card{
        border: 1px solid ${props => props?.theme?.color?.typo?.gray || '#d9d9d9'};
        border-radius: 5px;
        @media(max-width: 991px){
          padding: 12px;
        }
        &.active{
          border: 1px solid ${props => props?.theme?.color?.typo?.primary_light2 || '#d9d9d9'};
        }
        label{
          display: flex;
          /* flex-direction: row-reverse; */
          /* justify-content: space-between; */
          gap: 20px;
          align-items: center;
          @media(max-width: 991px){
            gap: 12px;
          }
          .icons{
            align-items: center;
            img{
              width: 63px;
              height: fit-content;
            }
            .info{
              margin-left: 30px;
              padding-left: 30px;
              border-left: 1px solid ${props => props?.theme?.color?.typo?.gray1 || '#ddd'};
              @media(max-width: 991px){
                margin-left: 15px;
                padding-left: 15px;
              }
            }
          }
        }
      }

    }
  }
}
.sign-in-user{
  .modaldialog{
            /* min-width: 40vw !important; */
          }
}
.inner_modal_con{
  &.order_success{
    .close_outside{
      pointer-events: none;
    }
  }

  .order{
    width: fit-content;
    margin: auto;
    p{text-align: left;}
  }
  a{ text-decoration: none;}
  button{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    min-width: auto;
    max-width: 235px;
    width: 100%;
  }
}
textarea{
  background: ${props => props?.theme?.color?.container?.background || '#eee'};
}
.modaldialog{
  .wrap{
    height: 80vh;
    overflow-y: scroll;
    ::-webkit-scrollbar {
    display: none;
    }
    .buttons{
      /* gap: 15px; */
      align-items: center;
      margin-top: 15px;
      /* justify-content: flex-end!important; */
      button{
        min-width: fit-content;
        width: 40%;
        margin: 0!important;
        /* width: 50%; */
      }
      @media(max-width: 425px){
        button{
          /* width: 40%; */
        }
        justify-content: flex-end!important;
      }
    }
  }
  }
`

export const StyleCheckout1 = styled.div`
  button{
    width: fit-content!important;
  }
  .wrapper{
    padding-top: 60px;
    padding-bottom: 100px;
    position: relative;
    @media(max-width: 768px){
      padding-top: 30px;
    }
    .modaldialog{
      min-width: 50vw;
      @media(max-width: 991px){
        min-width: 60vw;
      }
      .info{
        .field-wrapper{
          margin-bottom: 20px;
        }
        @media(max-width: 425px){
          .buttons{
            button{
              min-width: 40%;
            }
          }
        }
      }
    }
  }
  .info-text{
    margin-bottom: 20px;
  }
  .title-wrap{
    /* background: ${props => props?.theme?.color?.typo?.background}; */
    padding: 20px;
    border-radius: 8px 8px 0 0;
    @media(max-width: 991px){
      padding-bottom: 0;
    }
    input[type='radio']{
      width: 20px;
      height: 20px;
      accent-color: ${props => props?.theme?.color?.container?.primary};
    }
    .myradio{
      width: 20px;
      height: 20px;
      border: 1px solid #33333996;
      border-radius: 20px;
      &.active{
        border-color: ${props => props?.theme?.color?.container?.primary};
        display: flex;
        justify-content: center;
        align-items: center;
        .inner{
          width: 12px;
          height: 12px;
          border-radius: 12px;
          background: ${props => props?.theme?.color?.container?.primary};
        }
      }
    }
  }
  
  .disable-layer{
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${props => props?.theme?.color?.card?.disabled};
    opacity: .3;
    z-index: 10;
    display: none;
  }
  .submit-btn{
    @media(max-width: 375px){
      min-width: 100px;
    }
  }
  .buymore{
    width: fit-content;
    position: absolute;
    left: 12px;
    bottom: 40px;
    color: ${props => props?.theme?.color?.typo?.light};
    @media(max-width: 991px){
      bottom: 20px;
    }
    @media(max-width: 375px){
      min-width: 100px;
    }
  }
  .next-btn{
    position: absolute;
    bottom: 40px;
    right: 12px;
    color: ${props => props?.theme?.color?.typo?.light};
    @media(max-width: 991px){
      bottom: 20px;
    }
    @media(max-width: 375px){
      min-width: 100px;
    }
  }


  .pickup-con, .delivery-con{
    cursor: pointer;
    height: 95%;
    border: 1px solid ${props => props?.theme?.color?.card?.gray1};
    border-radius: 8px;
    margin-top: 22px;
    .content-wrap{
      padding: 20px;
      padding-bottom: 0;
      @media(max-width: 425px){
        padding-inline: 10px;
      }
      iframe{
        border: 1px solid #0000001F;
      }
      .mobile{
        a{
          &:not(:last-child):after{
            content: ',';
          }
        }
      }
    }
    position: relative;
    &.active{
      box-shadow: 0px 5px 10px #00000029;
      border-color: ${props => props?.theme?.color?.card?.primary_light2};
    }
  }

  .pickup-con{
    position: relative;
  }


  .delivery-con{
    padding-bottom: 50px;
    .content-title{
      margin-bottom: 20px;
      @media(max-width: 991px){
        margin-bottom: 0;
      }
    }
    .field-wrapper{
      margin-bottom: 15px;
      .label_txt{
        font-weight: ${props => props?.theme?.fontWeight?.lg};
      }
    }
    .buttons{
      width: fit-content;
      margin: 0;
    }
    .submit-btn,.next-btn{
      position: absolute;
      right: -1px;
      bottom: -75px;
      width: fit-content!important;
      /* border-radius: 15px; */
    }
    .submit-btn{
      @media(max-width: 991px){
        bottom: -94px;
      }
    }
    .next-btn{
      bottom: 0;
    }
    margin-bottom: 20px;
    .no-address{
        background:${props => props?.theme?.color?.container?.background};
        border: 1px solid ${props => props?.theme?.color?.typo?.text};
        border-radius: 5px;
        border-style: dashed;
        height: 55px;
        svg{
          width: 18px;
          height: 18px;
          padding: 2px;
          border: 1px solid ${props => props?.theme?.color?.typo?.dark};
          border-radius: 18px;
        }
    }
    .address-con{
      border-bottom: 1px solid ${props => props?.theme?.color?.form?.borderColor};
      justify-content: space-between;
      position: relative;
      padding: 15px;
      padding-left: 0;
      gap: 16px;
      
      @media(max-width: 991px){
        padding: 12px;
        gap: 12px;
      }
      @media(max-width: 425px){
        padding-inline: 0;
      }
      align-items: center;
      /* .name{ margin-bottom: 12px;} */
      svg{ margin-right: 8px;}
      /* .ph{ margin-left: 5px;} */
      /* p{margin-bottom: 15px;} */
      p:last-child{margin-bottom: 0;}
      &.active{
        border:1px solid ${props => props?.theme?.color?.typo?.primary_light2 || '#ddd'};
      }
      .info{
        /* padding: 20px; */
        width: 100%;
        /* border: 1px solid red; */
        cursor: pointer;
      }
      .btn{
        padding: 0;
        border-left: 1px solid gray;
        border: none; 
        /* border-left: 1px solid ${props => props?.theme?.color?.form?.light_gray};  */
        border-radius: 0;
        width: fit-content;
        position: absolute;
        top: 3px;
        right: 0;
      }
      button{ 
      border: none;
      min-width: 50%;
      padding: 5px;
      svg{ margin:0;}
      height: 50%;
        &:nth-child(2){
          border-radius: 0;
          padding-right: 10px;
        }
      }
      /* margin-bottom: 20px; */
      &.last{
        border: none;
        margin-bottom: 20px;
      }
      input[type='radio']{
        width: 17px;
        height: 17px;
        accent-color: ${props => props?.theme?.color?.container?.primary};
      }
    }
  }
`

export const StyledPayment = styled.div`
  padding-block: 50px;
  @media(max-width: 991px){
    padding-block: 30px;
  }
  .payment-con{
    @media(min-width: 992px){
      padding-left: 10px;
    }
    .address-con{
      margin-top: 15px;
      margin-bottom: 20px;
      a{
        &:not(:last-child):after{
          content: ',';
        }
      }
    }
    .deli-method-con{
      .method{
        border: 1px solid ${props => props?.theme?.color?.typo?.border2};
        padding: 15px;
        border-radius: 8px;
        cursor: pointer;
        input[type='radio']{
          width: 15px;
          height: 15px;
          margin-top: 7px;
          accent-color: ${props => props?.theme?.color?.typo?.primary};
        }
        @media(max-width: 425px){
          padding: 10px;
          gap: 10px!important;
        }
      }
    }
    .payment-method-con{
      textarea{
       border-color:  ${props => props?.theme?.color?.typo?.border2};
       border-radius: 3px;
       margin-bottom: 30px;
      }
      input[type='radio']{
        accent-color: ${props => props?.theme?.color?.typo?.primary};
      }
      .payment-list{
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .p-card{
        border: 1px solid ${props => props?.theme?.color?.typo?.gray || '#d9d9d9'};
        border-radius: 5px;
        cursor: pointer;
        @media(max-width: 991px){
          padding: 12px;
        }
        &.active{
          border: 1px solid ${props => props?.theme?.color?.typo?.primary_light2 || '#d9d9d9'};
        }
        label{
          display: flex;
          gap: 20px;
          align-items: center;
          @media(max-width: 991px){
            gap: 12px;
          }
          .icons{
            align-items: center;
            img{
              width: 40px;
            }
            .info{
              margin-left: 30px;
              padding-left: 30px;
              border-left: 1px solid ${props => props?.theme?.color?.typo?.gray1 || '#ddd'};
              @media(max-width: 991px){
                margin-left: 15px;
                padding-left: 15px;
              }
            }
          }
        }
      }
      
    }
    }
  }
  .order-summary{
    padding: 12px;
    padding-bottom: 30px;
    border: 1px solid ${props => props?.theme?.color?.card?.border2};
    border-radius: 10px;
    @media(max-width: 991px){
      margin-top: 30px;
    }
    table{
      tbody{
        tr{
          &:first-child{
            // td{
            //   padding-block: 10px;
            // }
          }
        }
      }
      td{
        padding: 0;
      }
    }
    hr{
      margin-block: 10px;
    }
    .custom-table{
      width: 100%;
      td{
        &.name{
          border: 1px solid red;
          width: 50%;
        }
        &.qty{
          border: 1px solid red;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &.price{
          border: 1px solid red;
          width: 30%;
        }
      }
    }
  }
`