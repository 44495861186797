import React from "react";
import { useNavigate } from "react-router-dom";

import {
  FormContainerCom,
  CardCom,
  ButtonCom,
  InputCom,
  TextCom,
} from "components";
import { useHandleAuth, useHandleOther, useAuth } from "hook";
import { StyledAuthCon } from "theme";

type ForgotPassProps = {};

export const ForgotPass: React.FC<ForgotPassProps> = (props) => {
  const navigate = useNavigate();
  const { translate } = useHandleOther();
  const { handleIsExistUser } = useHandleAuth(props);
  const { signUp_data } = useAuth();

  let initialValues: any = {
    phone: "",
  };

  const validationSchema = (yup: any) =>
    yup.object({
      phone: yup
        .string()
        .required(translate("phone-is-required", "Phone is Required")),
    });

  const onDataSubmit = (values?: any, actions?: any) => {
    actions.setSubmitting(true);
    setTimeout(async () => {
      let post_data = {
        mobile: values?.phone,
        from: "forgot",
      };
      handleIsExistUser(post_data);
      // navigate('/user/change_password')
      actions.setSubmitting(false);
      if (!signUp_data?.exitUser_data?.exist) {
        actions.resetForm(initialValues);
      }
    }, 1000);
  };

  const handleRouteChange = (route?: any, params?: any) => {
    navigate(route);
  };

  return (
    <StyledAuthCon>
      <div className="container">
        <div className="auth_con">
          <div className="row">
            <div className="col-lg-6 form_wrap forgot-con">
              <div className="form_card">
                <TextCom
                  size="xxxl"
                  textAlign="center"
                  weight="xl"
                  className="mb-4"
                >
                  {" "}
                  {translate("forgot-password", "Forgot Password")}
                </TextCom>
                <TextCom textAlign="left" className="desc_txt mb-5">
                  {translate(
                    "enter-phone-number-associated-with-your-account",
                    "Enter phone number associated with your account"
                  )}{" "}
                </TextCom>
                <FormContainerCom
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values?: any, actions?: any) =>
                    onDataSubmit(values, actions)
                  }
                >
                  {(formikProps?: any) => (
                    <>
                      <InputCom
                        label={translate("phone no", "Phone No")}
                        name="phone"
                        type="tel"
                        required
                      />
                      <div className="form_foot_sec mt-4">
                        <ButtonCom
                          color="light"
                          bgcolor="primary_light2"
                          btnBorderRadius="xxxs"
                          text={
                            formikProps.isSubmitting
                              ? translate("submit...", "Submit...")
                              : translate("submit", "Submit")
                          }
                        />
                      </div>
                    </>
                  )}
                </FormContainerCom>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledAuthCon>
  );
};
