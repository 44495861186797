import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import {
  FormContainerCom,
  ButtonCom,
  InputCom,
  TextCom,
  ModalCom,
} from "components";
import {
  useInterval,
  useHandleAuth,
  useHandleOther,
  useAuth,
  showToast,
} from "hook";
import Icon from "asset/icon/pladco";
import { StyledAuthCon } from "theme";

type OtpProps = {};

export const Otp: React.FC<OtpProps> = (props) => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const { translate } = useHandleOther();
  const { handleOtpVerify, handleIsExistUser } = useHandleAuth(props);
  const [timer, setTimer] = useState(60);
  const [type, setType]: any = useState(null);
  const { otpVerify_data } = useAuth();

  const [visable, isVisable] = useState(false);
  const handleModal = () => {
    isVisable(!visable);
  };

  useInterval(() => {
    timer !== 0 && setTimer(timer - 1);
  }, 1000);

  let initialValues: any = {
    code: "",
  };

  const validationSchema = (yup: any) =>
    yup.object({
      code: yup
        .string()
        .required(translate("verify-code-required", "Verify Code Required")),
    });

  const onDataSubmit = (values?: any, actions?: any) => {
    // actions.setSubmitting(true)
    // setType('signup_success')
    // navigate('/user/signup/success', { replace: true })
    setTimeout(async () => {
      let post_data = {
        mobile: location?.state?.mobile,
        otp: values.code,
      };
      let res = await handleOtpVerify(post_data);
      if (res?.payload?.status === "success") {
        isVisable(true);
        // if (location?.state?.from === "signup") {
        //   setType("signup_success");
        // }
      }
      actions.setSubmitting(false);
      if (otpVerify_data?.status === "fail") {
        actions.resetForm(initialValues);
      }
    }, 1000);
  };

  const handleResend = () => {
    setTimer(60);
    let postData = { mobile: location?.state?.mobile };
    handleIsExistUser(postData);
  };

  const handleRouteChange = (route?: any, params?: any) => {
    navigate(route);
  };

  return (
    <StyledAuthCon>
      <div className="container">
        <div className="auth_con otp-con">
          <div className="row">
            <div className="col-lg-6 form_wrap m-auto d-flex justify-content-center align-items-center">
              <div className="form_card">
                <TextCom
                  size="xxxl"
                  textAlign="center"
                  weight="xl"
                  className="title_txt"
                >
                  {translate("verification", "Verification")}
                </TextCom>
                <TextCom
                  textAlign="center"
                  className="desc_txt mb-5"
                  color="paragraph"
                >
                  {translate(
                    "enter-the-otp-code-we-just-sent-you-on-your-phone-number",
                    "Enter the OTP code we just sent you on your phone number"
                  )}
                  {", "}
                  <TextCom as="span" weight="md">
                    {location?.state?.mobile}
                  </TextCom>
                </TextCom>
                <FormContainerCom
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values?: any, actions?: any) =>
                    onDataSubmit(values, actions)
                  }
                >
                  {(formikProps: any) => (
                    <>
                      <InputCom
                        label={translate("verify-code", "Verify Code")}
                        name="code"
                        type="text"
                      />
                      <div className="form_foot_sec">
                        {timer === 0 ? (
                          <ButtonCom
                            text={translate(
                              "send-code-again",
                              "Send code again"
                            )}
                            color="text"
                            className="send_btn"
                            link
                            onClick={handleResend}
                            className="m-auto mb-3 send-code-btn"
                          />
                        ) : (
                          <TextCom
                            weight="lg"
                            className="timer_txt"
                            textAlign="center"
                          >
                            {timer} s
                          </TextCom>
                        )}
                        <ButtonCom
                          color="light"
                          bgcolor="primary_light2"
                          btnBorderRadius="xxxs"
                          text={
                            formikProps.isSubmitting
                              ? translate("verifying", "Verifying")
                              : translate("verify", "verify")
                          }
                          className="m-auto"
                        />
                      </div>
                    </>
                  )}
                </FormContainerCom>
              </div>
              {/* {type === "signup_success" ||
              location?.state?.from === "reset" ? (
                <div className="signup_success_con">
                  <TextCom
                    size="xl"
                    weight="xl"
                    textAlign="center"
                    className="wel_txt"
                  >
                    {translate("successful", "Successful")}
                  </TextCom>
                  <TextCom textAlign="center" className="desc_txt">
                    {location?.state?.title ||
                      translate(
                        "your-registration-has-been-successful!",
                        "Your registration has been successful!"
                      )}{" "}
                  </TextCom>
                  <div className="btn_wrap">
                    <ButtonCom
                      color="light"
                      bgColor="primary"
                      btnBorderRadius="xxxs"
                      text={translate("login", "Login")}
                      onClick={() => handleRouteChange("/user/signin")}
                    />
                  </div>
                </div>
              ) : (
                <div className="form_card">
                  <TextCom
                    size="xxxl"
                    textAlign="center"
                    weight="xl"
                    className="title_txt"
                  >
                    {translate("verification", "Verification")}
                  </TextCom>
                  <TextCom
                    textAlign="center"
                    className="desc_txt mb-5"
                    color="paragraph"
                  >
                    {translate(
                      "enter-the-otp-code-we-just-sent-you-on-your-phone-number",
                      "Enter the OTP code we just sent you on your phone-number"
                    )}
                  </TextCom>
                  <FormContainerCom
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values?: any, actions?: any) =>
                      onDataSubmit(values, actions)
                    }
                  >
                    {(formikProps: any) => (
                      <>
                        <InputCom
                          label={translate("verify-code", "Verify Code")}
                          name="code"
                          type="text"
                        />
                        <div className="form_foot_sec">
                          {timer === 0 ? (
                            <ButtonCom
                              text={translate(
                                "send-code-again",
                                "Send code again"
                              )}
                              bgColor="light"
                              className="send_btn"
                              link
                              onClick={handleResend}
                              className="m-auto"
                            />
                          ) : (
                            <TextCom
                              weight="lg"
                              className="timer_txt"
                              textAlign="center"
                            >
                              {timer} s
                            </TextCom>
                          )}
                          <ButtonCom
                            color="light"
                            bgColor="primary"
                            btnBorderRadius="xxxs"
                            text={
                              formikProps.isSubmitting
                                ? translate("verifying", "Verifying")
                                : translate("verify", "verify")
                            }
                            className="m-auto"
                          />
                        </div>
                      </>
                    )}
                  </FormContainerCom>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
      <ModalCom isModal={visable} handleIsModal={() => handleModal()}>
        <div className="signup_success_con">
          <TextCom
            size="xl"
            weight="xl"
            textAlign="center"
            className="wel_txt mb-2"
          >
            {translate("successful", "Successful")}
          </TextCom>
          <TextCom textAlign="center" className="desc_txt mb-3">
            {location?.state?.title ||
              translate(
                "your-registration-has-been-successful!",
                "Your registration has been successful!"
              )}{" "}
          </TextCom>
          <div className="btn_wrap">
            <ButtonCom
              color="light"
              bgColor="primary"
              btnBorderRadius="xxxs"
              text={translate("login", "Login")}
              onClick={() => handleRouteChange("/user/signin")}
              className="m-auto"
            />
          </div>
        </div>
      </ModalCom>
    </StyledAuthCon>
  );
};
