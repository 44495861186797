import React from "react";
import { BrowserRouter } from "react-router-dom";

import { HeaderCom, FooterCom, Main } from "components";
import { GlobalStyle } from "theme";
import AppRoute from "./router";
import { UnderConstruction } from "screen/content";
import { under_construction } from "utils";
import  ChatBot  from "../components/common/messenger"

type AppLayoutProps = {};
const AppLayout: React.FC<AppLayoutProps> = (props) => {
  return (
    <BrowserRouter>
      <GlobalStyle />
      {under_construction === true ? (
        <UnderConstruction />
      ) : (
        <>
          <HeaderCom />
          <Main>
            <AppRoute />
          </Main>
          <FooterCom />
        </>
      )}
    </BrowserRouter>
  );
};

export default AppLayout;
