import styled from 'styled-components'
import SegoUI from "../../../asset/font/SEGOEUI.TTF"
import { fontSize } from 'theme/attribute'
export const lineHeight = (fontSize: any, props: any) => {
  // const multiplier = (fontSize > 20) ? (props.theme?.langStore?.code === 'en' ? 1 : 1.88) : (props.theme?.langStore?.code === 'en' ? 0.5 : 0.88);
  const multiplier = fontSize > 20 ? 1 : 0.5
  return parseInt(fontSize + fontSize * multiplier, 10)
}

export const handleFontFamily = (props: any) => {
  let lang_data: any = {
    my: SegoUI,
    en: SegoUI,
  }
  let tmp = lang_data[props.theme.langStore.code || 'en']
  return tmp
}

type StyledTextProps = {
  size?: any,
  weight?: any,
  color?: any,
  textAlign?: any,
  textDecoration?: any,
  link?: any,
  props?: any,
  family?: any
}
export const StyledText = styled.p < StyledTextProps > `
  /* line-height: ${props => (props.theme.fontSize[props?.size] || props.theme.fontSize.md) * 1.7}px; */
  line-height: ${props => lineHeight(props.theme.fontSize[props?.size] || props?.size || props.theme.fontSize.md, props)}px;
  /* font-size: ${props => {
    let size = props.theme.fontSize[props?.size] || props.size || props.theme.fontSize.md
    return size
    return size
    return Dimensions.get('window').width < 550 ? size / 1.1 : size
  }}px; */
  @media(min-width: 320px) and (max-width: 1023px){
    font-size: calc(${props => props.theme.fontSize.sm}px + (${props => props.theme.fontSize[props?.size] || props.size || props.theme.fontSize.md} - ${props => props.theme.fontSize.sm}) * ((100vw - 100px) / (1023 - 100)));
  }

  @media(min-width: 1024px) {
    font-size: ${props => props.theme.fontSize[props?.size] || props.size || props.theme.fontSize.md}px;
  }
  font-weight: ${props => props.theme.fontWeight[props?.weight] || props?.weight || props.theme.fontWeight.sm};
  color: ${props => props.theme.color.typo[props?.color] || props?.color || props.theme.color.typo.text};
  text-align: ${props => props.textAlign || 'left'};
  /* font-family: ${props => {
    let res = handleFontFamily(props)
    return res
  }}; */
  font-family: ${props => props?.theme?.fontFamily[props?.family] || props?.theme?.fontFamily?.initial};
  margin: 0;
  text-decoration: ${props => props?.textDecoration || (props?.link ? 'underline' : 'auto')};
  text-decoration-color: ${props => (props?.link ? props.theme.color.typo[props?.color] || props?.color || props.theme.color.typo.text : 'auto')};

  &.middle_txt {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid ${props => props?.theme?.color?.typo?.gray};
    line-height: 0.1em;
    margin: 20px 0;

    span {
      background: #fff;
      padding: 0 30px;
    }
  }
`

export const StyledTitle = styled.div`
    margin-bottom: 30px;
    gap: 3rem;
    hr{
        width: 20%;
        height: 3px;
        background: ${props => props?.theme?.color?.typo?.primary_light2 || '#ddd'};
        opacity: 1;
    }
    @media(max-width: 991px){
        hr{
            width: 15%;
        }
        gap: 1.5rem;
        margin-bottom: 15px;
    }
    &.verticle{
    flex-direction: column;
    /* justify-content: flex-end; */
    gap: 5rem;
    width: 5%;
    margin-bottom: 0;
    @media(max-width: 1200px){
      gap: 3rem;
    }
    @media(max-width: 991px){
    width: 9%;
    gap: 1rem;
    }
    @media(max-width: 768px){
    width: 9%;
    gap: 10px;
    }
    h1{ 
        text-transform: uppercase;
        transform: rotate(270deg);
        font-size: ${props => props?.theme?.fontSize?.xxxxl}px;
        @media(max-width: 1200px){
          font-size: ${props => props?.theme?.fontSize?.xxxl}px;
        }
        @media(max-width: 991px){
          font-size: ${props => props?.theme?.fontSize?.xl}px;
        }
        @media(max-width: 768px){
          font-size: ${props => props?.theme?.fontSize?.sm}px;
        }
        @media(max-width: 425px){
          font-size: ${props => props?.theme?.fontSize?.xxs}px;
          line-height: 50px;
        }
    }
    hr{
        width: 3px;
        height: 40%;
        margin: 0;
    }
}
h1{
  @media(max-width: 991px){
    line-height: 40px;
    margin-bottom: 20px;
  }
}
`