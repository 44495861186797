import React, { useEffect, useState } from "react";

import { TextCom, InputCom } from "../../common";
import { moneyFormat, en2mm_num } from "utils";
import { useProduct, useHandleOther } from "hook";
import { BiChevronDown } from "react-icons/bi";
import { useHandleQty } from "hook";
import { cap_price_calculate } from "../../../hook/custom/product/pricehandler";
type PricehandleModProps = {
  style?: any;
  setid?: any;
  set_UOM_type?: any;
  UOM_type?: any;
  calculated_price?: any;
  uid?: any;
};
export const PriceHandleMod: React.FC<PricehandleModProps> = (props) => {
  const { langStore, translate } = useHandleOther();
  const { getProductById_data } = useProduct();
  const [handleQty, handleQtyFun] = useHandleQty({
    type: "productbyid",
    item: null,
    w_index: 0,
    c_index: 0,
    uid: null,
  });
  let langCode = langStore.code;

  // --------- begin uom price ---------
  let [uomprice, setUomPrice] = useState({
    id: 0,
    price: 0,
  });
  let [showuom, setShowUom] = useState(false);

  useEffect(() => {
    if (getProductById_data?.uom) {
      let tmp = getProductById_data?.uom?.filter(
        (item: any) => item?.display !== false
      );
      if (tmp?.length > 0) {
        let price = getProductById_data?.price?.filter(
          (price: any) => price?.quantity === tmp[0]?.ratio
        );
        setShowUom(true);
        setUomPrice({
          id: tmp[0]?.id,
          price: price[0]?.price * tmp[0]?.ratio,
        });
      }
    }
  }, []);

  let qty = getProductById_data?.quantity;

  const handleUom = (value: any, type: any) => {
    let uom_type_array = value.split(",");
    if (typeof uom_type_array[0] == "string") {
      props.set_UOM_type(Number(uom_type_array[0]));
    } else {
      props.set_UOM_type(uom_type_array[0]);
    }
    if (type === "uom") {
      let uom_data = {
        u_id: Number(value.split(",")[0]),
        u_quantity: Number(value.split(",")[1]),
        u_name: value.split(",")[2],
      };
      if (getProductById_data?.uom && uom_data) {
        let uom_price = getProductById_data?.price?.filter(
          (price: any) =>
            price?.uom === uom_data?.u_name
        )[0];
        setUomPrice({
          id: uom_data?.u_id,
          price: uom_price?.price * uom_data?.u_quantity,
        });
        // console.log(uom_data, "checking why string instead of number")
      }
    } else {
      // let pricebyqty = getProductById_data?.price?.filter(
      //   (price: any) => Number(price?.quantity) === Number(value)
      // )[0];
    }
  };

  useEffect(() => {
    console.log(uomprice)
  }, [uomprice]);

  props?.setid(uomprice?.id); //for add to cart
  // --------- end uom price ---------

  // --------- arg for uom_price => product, uom, quantity

  return getProductById_data?.promo_price ? (
    <>
      <div className="d-flex flex-column flex-sm-row">
        <div className="d-flex flex-column">
          <div
            style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
          >
            {Number(getProductById_data?.promo_price) > 0 && (
              <TextCom
                weight="lg"
                color="primary"
                size="xxxl"
                style={{ marginRight: 10, padding: "5px 0" }}
              >
                {moneyFormat(props.calculated_price?.price, langCode)}
                {/* {moneyFormat(Math.ceil(getProductById_data?.promo_price), langCode)}{" "} */}
                <TextCom
                  as="span"
                  weight="lg"
                  color="primary"
                  size={langCode === "en" ? "xxxl" : "xl"}
                  style={{ marginLeft: "5px" }}
                >
                  {translate("ks", "Ks")}
                </TextCom>
              </TextCom>
            )}
            <TextCom
              color="desc"
              style={{ textDecorationLine: "line-through", marginRight: 10 }}
            >
              {/* {console.log(uomprice,"ghost")} */}
              {moneyFormat(
                Math.ceil(
                  // getProductById_data?.extra_price_for_variant ||
                  getProductById_data?.uom?.length == 1
                    ? getProductById_data?.price?.find(
                        (u: any) => u?.uom == getProductById_data?.uom[0].name
                      )?.price
                    : uomprice.id == ""
                    ? getProductById_data?.uom?.some(
                        (uom: any) => uom.default == true
                      )
                      ? getProductById_data?.price?.find(
                          (u: any) =>
                            u?.uom ==
                            getProductById_data?.uom?.find(
                              (x: any) => x.default == true
                            )?.name
                        )?.price
                      : getProductById_data?.uom?.some(
                          (uom: any) => uom.display == true
                        )
                      ? getProductById_data?.price?.find(
                          (u: any) =>
                            u?.uom ==
                            getProductById_data?.uom?.find(
                              (x: any) => x.display == true
                            )?.name
                        )?.price
                      : getProductById_data?.price?.find(
                          (u: any) =>
                            u?.uom ==
                            getProductById_data?.uom?.find(
                              (g: any) => g?.id == uomprice?.id
                            )?.name
                        )?.price
                    : getProductById_data?.price?.find(
                        (u: any) =>
                          u?.uom ==
                          getProductById_data?.uom?.find(
                            (g: any) => g?.id == uomprice?.id
                          )?.name
                      )?.price

                  // getProductById_data?.uom?.some((uom:any) => uom.default == true) &&
                  // getProductById_data?.price?.find((u:any)=> u?.uom == getProductById_data?.uom?.find((x:any)=> x.default == true)?.name)?.price

                  // getProductById_data?.price[0].price
                ),
                langCode
              )}{" "}
              <TextCom as="span" size={langCode !== "en" && "sm"}>
                {translate("ks", "Ks")}

                {/* {console.log(getProductById_data?.uom?.find((g:any)=> g?.id == uomprice?.id)?.name, "fkyou")}
            {console.log(getProductById_data?.price)} */}
              </TextCom>
            </TextCom>
            <div
              style={{
                paddingTop: 2,
                paddingRight: 5,
                paddingBottom: 2,
                paddingLeft: 5,
                borderRadius: 5,
              }}
            >
              {/* <div className="save-money">
          <TextCom size="sm" color="light">
            Save {moneyFormat(Math.ceil(getProductById_data?.price - getProductById_data?.promo_price), langCode)} Ks
          </TextCom>
        </div> */}
              {/* <TextCom size='xxs' weight='lg'>-{en2mm_num(data?.cal_discount_percent, langCode)}%</TextCom> */}
            </div>
          </div>
          {getProductById_data?.cap_data?.selected_variant_ids?.length == 2 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <TextCom color="primary_light2">
                {"+"}
                {getProductById_data?.cap_data?.selected_variant_ids?.length ==
                  2 &&
                  moneyFormat(
                    Math.ceil(
                      // getProductById_data?.extra_price_for_variant ||
                      getProductById_data?.cap_data[0]
                        ?.find(
                          (x: any) =>
                            x.id ===
                            getProductById_data?.cap_data
                              ?.selected_variant_ids[0]
                        )
                        ?.color_info.find(
                          (y: any) =>
                            y.id ===
                            getProductById_data?.cap_data
                              ?.selected_variant_ids[1]
                        )?.price -
                        (getProductById_data?.cap_data[0]
                          ?.find(
                            (x: any) =>
                              x.id ===
                              getProductById_data?.cap_data
                                ?.selected_variant_ids[0]
                          )
                          ?.color_info.find(
                            (y: any) =>
                              y.id ===
                              getProductById_data?.cap_data
                                ?.selected_variant_ids[1]
                          )?.price *
                          getProductById_data?.cal_discount_percent) /
                          100
                    ),
                    langCode
                  )}{" "}
                {getProductById_data?.cap_data?.selected_variant_ids?.length ==
                2
                  ? translate("ks", "Ks") + " "
                  : ""}
              </TextCom>
              {"\u00A0"}
              <TextCom
                color="primary_light2"
                style={{ textDecorationLine: "line-through" }}
              >
                {getProductById_data?.cap_data?.selected_variant_ids?.length ==
                  2 &&
                  moneyFormat(
                    Math.ceil(
                      // getProductById_data?.extra_price_for_variant ||
                      getProductById_data?.cap_data[0]
                        ?.find(
                          (x: any) =>
                            x.id ===
                            getProductById_data?.cap_data
                              ?.selected_variant_ids[0]
                        )
                        ?.color_info.find(
                          (y: any) =>
                            y.id ===
                            getProductById_data?.cap_data
                              ?.selected_variant_ids[1]
                        )?.price
                    ),
                    langCode
                  ) +
                    " " +
                    translate("ks", "Ks")}
              </TextCom>
              {"\u00A0"}
              <TextCom color="primary_light2">
                {getProductById_data?.cap_data?.selected_variant_ids?.length ==
                2
                  ? " " +
                    "(" +
                    getProductById_data?.cap_data[0]
                      ?.find(
                        (x: any) =>
                          x.id ===
                          getProductById_data?.cap_data?.selected_variant_ids[0]
                      )
                      ?.color_info.find(
                        (y: any) =>
                          y.id ===
                          getProductById_data?.cap_data?.selected_variant_ids[1]
                      )?.color +
                    " " +
                    getProductById_data?.cap_data[0]?.find(
                      (x: any) =>
                        x.id ===
                        getProductById_data?.cap_data?.selected_variant_ids[0]
                    )?.name +
                    ")"
                  : ""}
              </TextCom>
            </div>
          )}
        </div>
        {showuom && getProductById_data?.uom && (
          <div className="select-wrap uom">
            <BiChevronDown className="arrow" size={20} />
            <InputCom
              isValidate={false}
              input_as="select"
              onChange={(e: any) => {
                handleUom(e.target.value, "uom");
                handleQtyFun(1);
              }}
              className="select-uom"
              style={{ marginTop: "12px" }}
            >
              {getProductById_data?.uom?.map(
                (item: any, key: any) =>
                  item?.display === true && (
                    <option value={[item?.id, item?.ratio]} key={key}>
                      {item?.name}
                    </option>
                  )
              )}
            </InputCom>
          </div>
        )}
      </div>
    </>
  ) : (
    <>
      {Number(getProductById_data?.price[0].price) > 0 && (
        <div className="d-flex wrapper">
          <div
            className="d-flex gap-2"
            style={{ marginRight: "10px", marginBottom: "10px" }}
          >
            <TextCom
              weight="lg"
              color="primary"
              size="xxxl"
              style={props.style}
            >
              {moneyFormat(props.calculated_price?.price, langCode)}{" "}
              <TextCom
                as="span"
                weight="lg"
                color="primary"
                size={langCode === "en" ? "xxxl" : "xl"}
              >
                {translate("ks", "Ks")}
              </TextCom>
            </TextCom>
            {"\u00A0"}
            <TextCom color="primary_light2">
              {getProductById_data?.cap_data?.selected_variant_ids?.length == 2
                ? "+ " +
                  moneyFormat(
                    cap_price_calculate(getProductById_data, props.UOM_type),
                    langCode
                  ) +
                  " " +
                  translate("ks", "Ks") +
                  " " +
                  "(" +
                  getProductById_data?.cap_data[0]
                    ?.find(
                      (x: any) =>
                        x.id ===
                        getProductById_data?.cap_data?.selected_variant_ids[0]
                    )
                    ?.color_info.find(
                      (y: any) =>
                        y.id ===
                        getProductById_data?.cap_data?.selected_variant_ids[1]
                    )?.color +
                  " " +
                  getProductById_data?.cap_data[0]?.find(
                    (x: any) =>
                      x.id ===
                      getProductById_data?.cap_data?.selected_variant_ids[0]
                  )?.name +
                  ")"
                : ""}
            </TextCom>
          </div>
          {showuom && getProductById_data?.uom && (
            <div className="select-wrap uom">
              <BiChevronDown className="arrow" size={20} />
              <InputCom
                isValidate={false}
                input_as="select"
                onChange={(e: any) => {
                  handleUom(e.target.value, "uom");
                  handleQtyFun(getProductById_data.quantity_control_step ? getProductById_data.quantity_control_step : 1, Number(e.target.value.split(",")[0]));
                }}
                className="select-uom"
                style={{ marginTop: "8px" }}
              >
                {getProductById_data?.uom?.map(
                  (item: any, key: any) =>
                    item?.display === true &&
                    getProductById_data?.price?.find(
                      (x: any) => x?.uom == item?.name
                    ) != undefined && (
                      <option value={[item?.id, item?.ratio, item?.name]} key={key}>
                        {item?.name}
                      </option>
                    )
                )}
              </InputCom>
            </div>
          )}
        </div>
      )}
    </>
  );
};
