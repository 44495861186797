import styled from "styled-components";
import Image from "../../../asset/icon/pladco";

export const StyledHome = styled.div`
  padding-bottom: 70px;
  @media (max-width: 991px) {
    padding-bottom: 10px;
  }
  position: relative;

  .highlight-modal {
    .modaldialog {
      padding: 30px;
      @media (max-width: 768px) {
        width: 90vw;
        padding: 15px;
      }
    }
    .modal-close-btn {
      top: 15px;
      right: 10px;
      @media (max-width: 768px) {
        top: -3px;
        right: 3px;
      }
    }
    .modal-content {
      padding: 0;
    }
  }

  .highlight-wrap {
    width: 100%;
    top: 0;
    z-index: 9;
    left: 0;
    position: relative !important;
    @media (min-width: 769px) {
      animation: enter 2.5s;
      animation-iteration-count: 1;
      position: absolute !important;
    }
    animation-iteration-count: 1;
    transition: all 0.5s ease;
    .highlight-info {
      background: #1585b4;
      padding: 5px 20px;
      @media (min-width: 769px) {
        border-radius: 0 0 10px 10px;
      }
      @media (max-width: 768px) {
        border-radius: 0px;
        p {
          font-size: ${(props) => props?.theme?.fontSize?.xs || 12}px;
        }
      }
    }
  }
  @keyframes enter {
    0% {
      top: -100%;
    }
    100% {
      top: 0;
    }
  }

  .hero-slider {
    margin-bottom: 80px;
    @media (max-width: 991px) {
      margin-bottom: 50px;
    }
    .slick-slider {
      background: transparent;
      .slick-dots {
        bottom: -45px !important;
        @media (max-width: 1300px) {
          bottom: -40px !important;
        }
        @media (max-width: 991px) {
          bottom: -35px !important;
        }
        @media (max-width: 500px) {
          bottom: -30px !important;
        }
        li {
          width: 15px;
          height: 15px;
          border-radius: 15px;
          background: ${(props) => props?.theme?.color?.typo?.primary_light1};
          opacity: 1;
          transition: all 0.5s ease;
          @media (max-width: 1500px) {
            width: 12px;
            height: 12px;
          }
          @media (max-width: 991px) {
            width: 10px;
            height: 10px;
          }
          @media (max-width: 768px) {
            width: 6px;
            height: 6px;
          }
          @media (max-width: 500px) {
            margin: 2px;
          }
          button::before {
            color: transparent !important;
          }
          &.slick-active {
            width: 50px;
            background: #1585b4;
            @media (max-width: 1500px) {
              width: 30px;
            }
            @media (max-width: 991px) {
              width: 20px;
            }
          }
        }
      }
      .prev {
        left: 10px;
        @media (max-width: 425px) {
          left: 5px;
        }
      }
      .next {
        right: 10px;
        @media (max-width: 425px) {
          right: 5px;
        }
      }
      /* .prev{
            left: 40%!important;
            @media(max-width: 600px){
            left: 35%!important;
            }
            }
            
            .next{
            right: 40%!important;
            @media(max-width: 600px){
            right: 35%!important;
            }
            }
            .prev,.next{
                height: fit-content;
                top: 103%;
                svg{
                    @media(max-width: 991px){
                            width: 25px;
                            height: 25px;
                        }
                }
            } */
    }
    /* margin-bottom: 20px; */
  }

  .brand-list-sec {
    padding-block: 50px;
    .d-flex {
      row-gap: 1rem;
      gap: 32px;
    }
    img {
      max-width: 180px;
      border-radius: 15px;
      cursor: pointer;
      border: 1px solid ${(props) => props?.theme?.color?.card?.border2};
    }
    @media (max-width: 991px) {
      padding-bottom: 20px;
      img {
        max-width: 100px;
      }
      .d-flex {
        gap: 1rem;
      }
    }
    .prev {
      left: 5%;
    }
    .next {
      right: 5%;
    }
    .slick-slide {
      padding-inline: 10px;
      /* box-shadow: 0 5px 10px #333; */
      img {
        border-radius: 10px;
        overflow: hidden;
      }
    }
  }

  .category-slider {
    margin-bottom: 50px;
    /* @media(min-width: 1600px){
            margin-bottom: 30px;
        } */
    @media (max-width: 991px) {
      margin-block: 30px;
    }
    .title {
      @media (min-width: 991px) {
        margin-bottom: 50px;
      }
    }
    .slick-list {
      height: fit-content !important;
    }
    .prev,
    .next {
      top: -18%;
    }
    .prev {
      left: -3%;
    }
    .next {
      /* right: -3%; */
    }
    .slick-slide {
      img {
        width: 300px;
        height: 300px;
        border-radius: 300px;
        /* @media(min-width: 1400px){
                width: 300px;
                height: 300px;
              } */
        @media (max-width: 1399px) {
          width: 330px;
          height: 330px;
        }
        @media (max-width: 1199px) {
          width: 280px;
          height: 280px;
        }
        @media (max-width: 991px) {
          width: 200px;
          height: 200px;
        }
        @media (max-width: 768px) {
          width: 220px;
          height: 220px;
        }
        @media (max-width: 425px) {
          width: 140px;
          height: 140px;
        }
      }
    }
    .category {
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      p {
        line-height: 40px;
        @media (max-width: 1400px) {
          font-size: ${(props) => props?.theme?.fontSize?.xxxl}px;
        }
        @media (max-width: 991px) {
          font-size: ${(props) => props?.theme?.fontSize?.xxl}px;
          line-height: 30px;
        }
        @media (max-width: 768px) {
          font-size: ${(props) => props?.theme?.fontSize?.lg}px;
          line-height: 24px;
        }
      }
      hr {
        width: 2px;
        height: 4rem;
        background: ${(props) => props?.theme?.color?.typo?.primary_light2};
        opacity: 1;
        @media (max-width: 991px) {
          height: 2.5rem;
        }
        @media (max-width: 425px) {
          height: 1.5rem;
          margin: 0;
        }
      }
    }
  }
  .info-text {
    font-style: italic;
    padding: 50px 30px 60px 30px;
    a {
      color: ${(props) => props?.theme?.color?.typo?.primary_light2}!important;
      text-decoration: none !important;
    }
    @media (max-width: 991px) {
      padding: 30px 20px;
    }
    @media (max-width: 425px) {
      padding-top: 15px;
    }
    @media (min-width: 325px) and (max-width: 600px) {
      padding-inline: 40px;
    }
  }
  .banner-section {
    padding-top: 150px;
    padding-bottom: 100px;
    overflow: hidden;
    .title {
      @media (max-width: 991px) {
        display: none !important;
      }
    }
    a {
      text-decoration: none;
    }
    &.right {
      padding-top: 70px;
      padding-bottom: 150px;
      @media (max-width: 991px) {
        padding-block: 60px;
      }
      @media (max-width: 425px) {
        padding-block: 30px;
      }
    }
    .img-con {
      z-index: 2;
      position: relative;
    }
    img {
      width: 93% !important;
      height: auto !important;
      /* height: fit-content; */
      @media (max-width: 991px) {
        /* width: 90%!important; */
      }
    }
    @media (max-width: 991px) {
      .img-con {
        width: 100%;
        img {
          width: 100% !important;
        }
      }
      padding-block: 60px;
    }
    @media (max-width: 600px) {
      padding-top: 45px;
      padding-bottom: 30px;
    }
    .bg-text {
      position: absolute;
      font-size: 12vw;
      left: -10%;
      top: -14%;
      z-index: 1;
      opacity: 0.3;
      @media (max-width: 768px) {
        top: -5%;
      }
      @media (max-width: 500px) {
        /* top: -20%; */
      }
      &.right {
        top: 95%;
        left: 65%;
        @media (max-width: 425px) {
          /* top: 90%; */
          left: 70%;
        }
      }
    }
  }
  .group-con {
    button {
      width: fit-content;
      text-transform: initial;
      p {
        font-weight: ${(props) => props?.theme?.fontWeight?.md};
      }
    }
  }
`;
