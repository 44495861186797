import styled from "styled-components";

interface StyledFooterProps {
  props?: any;
  footerBgColor?: any;
}

export const StyledFooterCon = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledPrimaryFooterCon = styled.div<StyledFooterProps>`
  background: ${(props) =>
    props?.theme?.color?.footer?.[props?.footerBgColor] ||
    props?.footerBgColor ||
    props?.theme?.color?.footer?.dark};
  padding-top: 50px;
  padding-bottom: 20px;
  @media (max-width: 991px) {
    padding-top: 20px;
  }
`;

export const StyledPrimaryFooterContent = styled.div`
  a:hover,
  li p:hover {
    color: ${(props) => props?.theme?.color?.typo?.primary};
    p {
      color: ${(props) => props?.theme?.color?.typo?.primary};
    }
  }

  .arrow-wrap {
    .up-arrow {
      /* display: none; */
      position: fixed;
      bottom: 120px;
      right: 25px;
      z-index: 99;
      cursor: pointer;
      width: 60px;
      height: 60px;
      transition: all 0.4s ease-in-out;
      transform: translateY(15px);
      animation: bounce 2s infinite;
      color: #1585b4;
      z-index: 999999;
      @media (max-width: 768px) {
        right: 10px;
      }

      @keyframes bounce {
        0% {
          transform: translateY(20px);
        }
        60% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(20px);
        }
      }

      &:hover {
        /* transform: translateY(0)!important; */
        color: ${(props) => props?.theme?.color?.typo?.paragraph};
      }
    }
  }

  .row {
    align-items: flex-start;
    gap: 0;

    .useful-link {
      list-style: none;
      a {
        display: block;
        cursor: pointer;
        margin-bottom: 12px;
        @media (max-width: 991px) {
          margin-bottom: 10px;
        }
      }
    }
    p {
      margin-bottom: 12px;
      @media (max-width: 991px) {
        margin-bottom: 5px;
      }
    }
    a {
      text-decoration: none;
    }
    .logo {
      /* max-width: 70%;
      @media (max-width: 1199px) {
        max-width: 100%;
      } */
      @media (max-width: 991px) {
        /* max-width: 35%; */
        margin-bottom: 20px;
      }
    }
    .title {
      margin-bottom: 20px;
      @media (max-width: 991px) {
        margin-bottom: 10px;
      }
    }
    .company-info {
      @media (max-width: 991px) {
        margin-bottom: 25px;
      }
      img {
        &:not(.logo) {
          width: 32px;
          height: 32px !important;
        }
      }
      .title {
        @media (min-width: 992px) {
          height: 50px;
        }
      }
      a {
        display: block;
        margin-bottom: 12px;
      }
    }
    .contact {
      .wrap {
        gap: 15px;
        @media (max-width: 991px) {
          gap: 5px;
        }
      }
      .d-flex {
        gap: 10px;
      }
      p {
        margin: 0;
      }

      .img-wrap {
        width: 20px;
        img {
          width: 100%;
          @media (max-width: 768px) {
            width: 20px;
          }
        }
      }
      .add {
        .img-wrap {
          width: 38px;
          img {
            /* width: 90%; */
            @media (max-width: 991px) {
              width: 18px;
            }
          }
        }
      }
      .d-flex {
        /* margin-bottom: 15px; */
        .ph,
        .ml {
          margin-right: 5px;
          &:not(:last-child)::after {
            content: ",";
          }
        }
      }
    }
  }
`;

export const StyledSecondaryFooterCon = styled.div<StyledFooterProps>`
  background: ${(props) =>
    props?.theme?.color?.footer?.[props?.footerBgColor] ||
    props?.footerBgColor ||
    props?.theme?.color?.footer?.dark};
`;

export const StyledSecondaryFooterContent = styled.div`
  .container {
    border-top: 1px solid
      ${(props) => props?.theme?.color?.footer?.gray || "#ddd"};
    background: none;
    padding-block: 20px;
  }
  padding: 20px 0 20px 0;
  .copyright-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 991px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 30px;
    }
    img {
      max-width: 50px;
      margin-right: 8px;
      /* height: fit-content; */
      @media (max-width: 768px) {
        /* max-width: 60px; */
      }
    }
    .payment {
      display: flex;
      gap: 1rem;
      align-items: center;
      @media (max-width: 991px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0;
      }
      .d-flex {
        @media (max-width: 991px) {
          row-gap: 10px;
        }
      }
    }
  }
  a:hover {
    opacity: 1;
    color: ${(props) => props?.theme?.color?.typo?.primary};
  }
  p {
    /* text-align: right; */
    @media (max-width: 991px) {
      text-align: left;
    }
  }
`;
