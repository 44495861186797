import styled from "styled-components";
import Slider from "react-slick";

export const StyledRSSlider = styled(Slider)`
  display: block;
  .slick-list {
    /* padding-bottom: 40px; */
  }

  .slick-slide {
    cursor: pointer;
    position: relative;
  }

  .prev {
    left: -10px;
  }

  .next {
    right: -10px;
    top: 0;
  }

  .prev,
  .next {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    height: 100%;
    align-items: center;
    z-index: 99999;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;

    &:hover,
    &:focus {
      svg {
        opacity: 0.7;
      }
    }
    img {
      @media (max-width: 473px) {
        width: 20px;
      }
    }
  }

  .slick-arrow {
    &.slick-prev,
    &.slick-next {
      background-color: rgb(187, 184, 184);
      border-radius: 10px;

      &:hover,
      &:focus {
        background-color: rgb(187, 184, 184);
        border-radius: 10px;
      }
    }
  }

  &.slide-nav {
    .slick-list {
      padding-bottom: 0;
      margin: 0 13px;
      height: inherit !important;
    }

    .slick-slide {
      padding: 0 6px;
      cursor: pointer;

      img {
        border: 1px solid
          ${(props) => props?.theme?.color?.card?.bg1 || "transparent"};
        border-radius: ${(props) =>
          props?.theme?.RSVariable?.nav?.img_border_radius || 0}px;
      }
    }
  }
  .highlight-text {
    position: absolute;
    /* background: #00000078; */
    top: 0;
    bottom: 24%;
    z-index: 999;
    left: 0;
    /* right: 26%; */
    width: 100%;
    height: 100%;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding-inline: 30px;
    @media (max-width: 1600px) {
      h1 {
        font-size: 44px;
      }
    }
    h1 {
      line-height: 60px;
      @media (max-width: 1400px) {
        font-size: ${(props) => props?.theme?.fontSize?.xxxxl}px;
        line-height: 50px;
      }
      @media (max-width: 991px) {
        font-size: ${(props) => props?.theme?.fontSize?.xl}px;
        line-height: 20px;
      }
      @media (max-width: 600px) {
        font-size: ${(props) => props?.theme?.fontSize?.md}px;
        line-height: 18px;
      }
      @media (max-width: 425px) {
        font-size: ${(props) => props?.theme?.fontSize?.sm}px;
        line-height: 18px;
      }
    }
    p {
      height: (14 * 3) px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      @media (max-width: 600px) {
        font-size: ${(props) => props?.theme?.fontSize?.xs}px;
        line-height: 14px;
      }
    }
    @media (max-width: 1200px) {
      gap: 10px;
    }
    @media (max-width: 600px) {
      gap: 5px;
    }
  }
`;
