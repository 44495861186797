import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { TextCom, InputCom } from "components";
import { useHandleOther, useProduct } from "hook";
import Icon from "asset/icon/pladco";
import { StyledSearchCom } from "theme";
import { moneyFormat } from "utils";
type SearchComProps = {
  setDrawer?: any;
};

export const SearchCom: React.FC<SearchComProps> = ({
  setDrawer,
  ...props
}) => {
  const { translate, langStore } = useHandleOther();
  const { ProductAction, getProduct_data, dispatch } = useProduct();
  let [productName, setProductName] = useState("");
  const navigate = useNavigate();
  const langCode = langStore?.code;

  let [searching, setSearching] = useState(true);
  const handleSearch = (e: any) => {
    let req_obj = {
      lang: langCode,
      keywords: encodeURI(productName),
      searching: false,
    };

    productName && dispatch(ProductAction.getProduct(req_obj));
    productName !== "" && navigate(`/search/${encodeURI(productName)}`);
    productName && setShow(!show);
    setProductName("");
    setDrawer(false);
  };

  let [show, setShow] = useState(false);
  const handleSuggest = (e: any) => {
    setShow(true);
    let input: any = document.querySelector(".search-con input");
    let req_obj = {
      lang: langCode,
      page_number: 1,
      per_page: 10,
      keywords: e,
      sort_by: "",
      searching: true,
    };
    dispatch(ProductAction.getProduct(req_obj));
    input.addEventListener("blur", () => {
      setTimeout(() => {
        setShow(false);
      }, 1000);
    });
  };

  const handleCLick = (route: any) => {
    navigate(route);
  };

  return (
    <StyledSearchCom className="search-con">
      <div className={`search d-flex`}>
        <InputCom
          isValidate={false}
          placeholder={translate(
            "search-in-chainpro",
            "Search in Innovix Commerce"
          )}
          type="text"
          value={productName}
          onChange={(e: any) => {
            handleSuggest(e.target.value);
            setProductName(e.target.value);
          }}
          onKeyPress={(e: any) => e.key === "Enter" && handleSearch(e)}
        />
        {show && (
          <ul className="drop-down">
            {getProduct_data?.total_products > 0 ? (
              getProduct_data?.product_list?.map((item: any, key: any) => (
                <li
                  key={key}
                  className="d-flex justify-content-between align-items-center"
                  onClick={() => {
                    handleCLick(`/product/${item?.product_id}`);
                    setDrawer(false);
                  }}
                >
                  <TextCom size="sm" color="paragraph">
                    {item?.name}
                  </TextCom>
                  <TextCom size="sm" color="paragraph">
                    {moneyFormat(item?.price, langCode)} {translate("ks", "Ks")}
                  </TextCom>
                </li>
              ))
            ) : (
              <li>{translate("no-product", "No product found!")}</li>
            )}
          </ul>
        )}
        <div
          className="d-flex gap-2 icon-wrap"
          onClick={(e: any) => handleSearch(e)}
        >
          <img src={Icon.Search} alt="search-icon" className="img-fluid" />
          <TextCom color="light" style={{ minWidth: "fit-content" }}>
            {translate("search", "Search")}
          </TextCom>
        </div>
        {/* <FiSearch size={25} onClick={(e) => handleSearch(e)} /> */}
      </div>
    </StyledSearchCom>
  );
};
