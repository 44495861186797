import React from "react";
import { BsCalendarEvent } from "react-icons/bs";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import moment from "moment";
import { CardCom, TextCom } from "components";
import "./style.scss";
import { useHandleOther } from "hook";
import Icon from "../../../asset/icon/pladco";
type NewsCardProps = {
  data?: any;
  landscape?: boolean;
};

export const NewsCard: React.FC<NewsCardProps> = ({
  data,
  landscape,
  ...props
}) => {
  const { translate } = useHandleOther();

  return (
    <CardCom className={landscape ? "landscape" : "news-card"} {...props}>
      <a
        className="link"
        href={
          "/news_and_activity_detail/" +
          (data?.news_activity_id ? data?.news_activity_id : data?.id)
        }
      />
      <div className="img">
        <img
          className="img-fluid"
          src={data?.feature_image || Icon.NewDefaultImg}
          alt=""
        />
      </div>
      <div className="content">
        {data?.date !== false && (
          <div className="d-flex gap-2 align-items-center">
            <BsCalendarEvent />
            <TextCom>{moment(data?.date).format("DD/MM/YYYY")}</TextCom>
          </div>
        )}
        <TextCom weight="xxl" size="xl">
          {data?.title || data?.name}
        </TextCom>
        {!landscape && (
          <>
            <TextCom
              as="div"
              className="desc"
              dangerouslySetInnerHTML={{ __html: data?.description }}
            />
            <TextCom link color="primary" className="d-flex gap-2 readmore">
              {translate("read-more", "Read More")}
              <HiOutlineArrowNarrowRight color="#0491A4" />
            </TextCom>
          </>
        )}
      </div>
    </CardCom>
  );
};
