import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RiArrowUpCircleFill } from "react-icons/ri";

import { PrimaryFooterCom, TextCom } from "../../../common";
import {
  useOther,
  useHandleOther,
  useCart,
  useProduct,
  useHandleProduct,
  useAuth,
} from "hook";
import { IoLocationOutline, IoCallOutline } from "react-icons/io5";
import { HiOutlineMail } from "react-icons/hi";
import Icon from "asset/icon/pladco";
import Pdf from "asset/pdf/index";

type PrimaryFooterProps = {};
export const PrimaryFooter: React.FC<PrimaryFooterProps> = (props) => {
  const { getContactUsPage_data, getMetaData_data } = useOther();
  const { getPaymentList_data, dispatch, CartAction } = useCart();
  const { getProductCategory_data, getProductGroup_data, ProductAction } =
    useProduct();
  const { translate } = useHandleOther();
  const navigate = useNavigate();
  const location = useLocation();
  const { handleProductFilterChange } = useHandleProduct();
  const { getProfile_data } = useAuth();

  const [route, setRoute] = useState("");

  const handleRouteChange = (route: any) => {
    setRoute(route);
  };

  if (location.pathname === "/products" && location.search === "") {
    navigate(route, { replace: false });
  }

  useEffect(() => {
    dispatch(
      ProductAction.getProductGroup(
        "newarrival|bestseller|latestproduct|popularcategory"
      )
    );
    dispatch(CartAction.getPaymentList());
  }, []);

  let phone = null;
  let mail = null;
  if (getContactUsPage_data && getContactUsPage_data?.branches) {
    phone = getContactUsPage_data?.branches[0]?.phone.split(",");
    mail = getContactUsPage_data?.branches[0]?.email.split(",");
  }

  const onHandleChange = (filter_change_obj: any) => {
    handleProductFilterChange(filter_change_obj);
  };

  const handleCategoryChange = (item?: any) => {
    navigate(`/products?category_ids=${item.category_id}?page=1`, {
      replace: false,
      state: item.category_id,
    });
    window.location.reload();
    if (item?.sub_category?.length === 0) {
      onHandleChange({
        type: "category",
        key: item?.key,
        max_price: item?.price_filter_max,
      });
    }
  };

  let newarrival: any = null;
  let latestproduct: any = null;
  let bestseller: any = null;
  if (getProductGroup_data) {
    newarrival = {
      id: getProductGroup_data?.data?.filter(
        (item: any) => item?.code === "newarrival"
      )[0]?.id,
      name: getProductGroup_data?.data?.filter(
        (item: any) => item?.code === "newarrival"
      )[0]?.name,
      item: getProductGroup_data?.data?.filter(
        (item: any) => item?.code === "newarrival"
      )[0]?.item,
    };
    bestseller = {
      id: getProductGroup_data?.data?.filter(
        (item: any) => item?.code === "bestseller"
      )[0]?.id,
      name: getProductGroup_data?.data?.filter(
        (item: any) => item?.code === "bestseller"
      )[0]?.name,
      item: getProductGroup_data?.data?.filter(
        (item: any) => item?.code === "bestseller"
      )[0]?.item,
    };
    latestproduct = {
      id: getProductGroup_data?.data?.filter(
        (item: any) => item?.code === "latestproduct"
      )[0]?.id,
      name: getProductGroup_data?.data?.filter(
        (item: any) => item?.code === "latestproduct"
      )[0]?.name,
      item: getProductGroup_data?.data?.filter(
        (item: any) => item?.code === "latestproduct"
      )[0]?.item,
    };
  }

  // Scroll To Top
  let arrow_ref: any = useRef<HTMLDivElement>(null);
  if (arrow_ref.current) {
    arrow_ref.current.style.display = "none";
    var scrollFunction = function scrollFunction() {
      document?.documentElement?.scrollTop > 300
        ? (arrow_ref.current.style.display = "block")
        : (arrow_ref.current.style.display = "none");
    };
    var topFunction = function topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    };
    window.addEventListener("scroll", function (_) {
      scrollFunction();
    });
    arrow_ref.current.addEventListener("click", (_: any) => {
      topFunction();
    });
  }

  return (
    <PrimaryFooterCom>
      <div className="arrow-wrap" ref={arrow_ref}>
        <RiArrowUpCircleFill className="up-arrow" />
      </div>
      <div className="container">
        <div className="row">
          <div className="company-info col-xl-3  col-lg-4 col-12">
            <Link to="/">
              {getContactUsPage_data && getContactUsPage_data?.logo2 && (
                <img
                  src={getContactUsPage_data?.logo2}
                  className="logo w-100"
                  alt="logo"
                />
              )}
              {/* <TextCom size="xxxxl" weight="lg" color="primary_bold">
                chainpromm.com
              </TextCom> */}
            </Link>
            {getMetaData_data && getMetaData_data["footer-info-text"] && (
              <TextCom weight="lg">
                {getMetaData_data["footer-info-text"]?.text_one}
              </TextCom>
            )}
            {getMetaData_data && getMetaData_data["footer-info-text"] && (
              <div className="d-flex gap-2">
                <img src={Icon.Alert} />
                <TextCom weight="xl" color="danger2">
                  <span style={{ lineHeight: "2em" }}>
                    {getMetaData_data["footer-info-text"]?.text_two}
                  </span>
                </TextCom>
              </div>
            )}
          </div>
          <div className="useful-link col-xl-2 col-lg-2">
            <div className="title">
              <TextCom as="h1" size="xxxl" weight="lg">
                {translate("useful-link", "Useful Link")}
              </TextCom>
            </div>
            {getProductGroup_data && newarrival && (
              <TextCom
                as="a"
                family="dm"
                onClick={() =>
                  navigate(`/product_group/newarrival`, {
                    state: { name: newarrival?.name, item: newarrival?.item },
                  })
                }
              >
                {newarrival?.name}
              </TextCom>
            )}

            {getProductGroup_data && latestproduct && (
              <TextCom
                as="a"
                family="dm"
                onClick={() =>
                  navigate(`/product_group/bestseller`, {
                    state: {
                      name: latestproduct?.name,
                      item: latestproduct?.item,
                    },
                  })
                }
              >
                {latestproduct?.name}
              </TextCom>
            )}

            {getProductGroup_data && bestseller && (
              <TextCom
                as="a"
                family="dm"
                onClick={() =>
                  navigate(`/product_group/bestseller`, {
                    state: { name: bestseller?.name, item: bestseller?.item },
                  })
                }
              >
                {bestseller?.name}
              </TextCom>
            )}

            <TextCom as="a" href={"/promotion"} family="dm">
              {translate("promotion", "Promotion")}
            </TextCom>
            <TextCom as="a" href={"/aboutus"} family="dm">
              {translate("why-chainpro", "Why ChainPro")}
            </TextCom>
            {/* {!getProfile_data && (
              <>
              </>
            )} */}
          </div>
          <div className="company-info col-xl-3 col-lg-2">
            <div className="title">
              <TextCom as="h1" size="xxxl" family="dmserif">
                {translate("", "")}
              </TextCom>
            </div>
            {/* <TextCom as="a" href={"/page/delivery_and_payment"} family="dm">
              {translate("deliver-method", "Delivery & Payment Method")}
            </TextCom> */}
            <TextCom as="a" href={"/contactus"} family="dm">
              {translate("contact-us", "Contact Us")}
            </TextCom>
            <TextCom as="a" href={"/faq"} family="dm">
              {translate("faq", "FAQ")}
            </TextCom>
            <TextCom as="a" href={"/page/terms_and_condition"} family="dm">
              {translate("terms-and-cons", "Terms & Conditions")}
            </TextCom>
            <TextCom as="a" href={"/page/privacy_policy"} family="dm">
              {translate("privacy-policy", "Privacy & Policy")}
            </TextCom>
            <TextCom as="a" href={"/page/how_to_register"} family="dm">
              {translate("how-to-register", "How to Register")}
            </TextCom>
            <TextCom as="a" href={"/page/how_to_buy"} family="dm">
              {translate("how-to-buy", "How to Buy")}
            </TextCom>

            <TextCom
              as="a"
              href={Pdf.productCatalogue}
              family="dm"
              download={true}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Layer_1"
                data-name="Layer 1"
                viewBox="0 0 24 24"
                width="18"
                height="32"
                fill="#AE0002"
                style={{ marginBottom: "5px" }}
              >
                <path d="M14.41,0H5c-1.65,0-3,1.35-3,3V24H22V7.59L14.41,0Zm.59,3.41l3.59,3.59h-3.59V3.41Zm5,18.59H4V3c0-.55,.45-1,1-1H13v7h7v13ZM6.97,13h-1.97v6.07h1.25v-2.1h.72c1.11,0,2.01-.89,2.01-1.99s-.9-1.98-2.01-1.98Zm0,2.72h-.71s0-1.47,0-1.47h.71c.41,0,.76,.34,.76,.73s-.35,.73-.76,.73Zm5.03-2.65c-.27,0-.52,0-.75,0h-1.25s0,6,0,6h1.25c.23,0,.48,0,.75,0h0c1.11,0,2-.89,2-1.98v-2.03c0-1.09-.89-1.98-2-1.98h0Zm.75,2v2c0,.45-.36,.75-.75,.75-.14,0-.43,0-.75,0v-3.5c.32,0,.61,0,.75,0,.39,0,.75,.3,.75,.75Zm3.5,4h-1.25v-6.07h4v1.25h-2.75v1.47s1.75,0,1.75,0v1.25h-1.75v2.1Z" />
              </svg>{" "}
              {translate("product-catalogue", "Product Catalogue")}
            </TextCom>
          </div>
          {/* <div className="Support col-xl-2 col-lg-2">
            <div className="title">
              <TextCom as="h1" size="xxxl" family="dmserif">
                {translate("support", "Support")}
              </TextCom>
            </div>
            <Link to="#">
              <TextCom
                onClick={() => handleRouteChange("/aboutus")}
                family="dm"
              >
                {translate("faq", "FAQ")}
              </TextCom>
            </Link>
            <Link to="/contactus">
              <TextCom
                onClick={() => handleRouteChange("/contactus")}
                family="dm"
              >
                {translate("contact-us", "Contact us")}
              </TextCom>
            </Link>
            <Link to="/page/terms_and_condition">
              <TextCom
                onClick={() => handleRouteChange("/page/terms_and_condition")}
                family="dm"
              >
                {translate("terms-conditions", "Terms & Conditions")}
              </TextCom>
            </Link>
            <Link to="/page/privacy_policy">
              <TextCom
                onClick={() => handleRouteChange("/page/privacy_policy")}
                family="dm"
              >
                {translate("privacy_policy", "Privacy policy")}
              </TextCom>
            </Link>
            <Link to="/our_store">
              <TextCom
                onClick={() => handleRouteChange("/our_store")}
                family="dm"
              >
                {translate("find-a-store", "Find a store")}
              </TextCom>
            </Link>
          </div> */}
          <div className="contact col-xl-4 col-lg-4">
            <div className="title">
              <TextCom as="h1" size="xxxl" weight="lg">
                {translate("contact-us", "Contact Us")}
              </TextCom>
            </div>
            {getContactUsPage_data?.branches?.length > 0 && (
              <div className="d-flex flex-column wrap">
                <div className="d-flex">
                  <div className="img-wrap col-1">
                    <img src={Icon.map} />
                  </div>
                  <TextCom className="col-11" family="dm">
                    {getContactUsPage_data?.branches[0]?.address}
                  </TextCom>
                </div>
                {phone && (
                  <div className="d-flex">
                    <div className="img-wrap col-1">
                      <img src={Icon.phone} />
                    </div>
                    <div className="d-flex col-11">
                      {phone?.map((ph: any, key: any) => (
                        <TextCom
                          as="a"
                          href={`tel:${ph}`}
                          className="ph"
                          family="dm"
                          key={key}
                        >
                          {ph}
                        </TextCom>
                      ))}
                    </div>
                  </div>
                )}
                {mail && (
                  <div className="d-flex">
                    <div className="img-wrap col-1">
                      <img src={Icon.mail} />
                    </div>
                    <div className="col-11">
                      {mail?.map((ml: any, key: any) => (
                        <TextCom
                          as="a"
                          href={`mailto:${ml}`}
                          className="ml"
                          family="dm"
                          key={key}
                        >
                          {ml}
                        </TextCom>
                      ))}
                    </div>
                  </div>
                )}
                {/* <div className="d-flex">
                  <img className="col-1" style={{width: 24}} src={Icon.Globe} />
                  <TextCom
                    as="a"
                    href="http://chainproshop.com"
                    family="dm"
                    target="_blank"
                    className="col-11"
                  >
                    chainproshop.com
                  </TextCom>
                </div>
                <div className="d-flex">
                  <img className="col-1" style={{width: 24}} src={Icon.Globe} />
                  <TextCom
                    as="a"
                    href="http://chainpromm.com"
                    family="dm"
                    target="_blank"
                    className="col-11"
                  >
                    chainpromm.com
                  </TextCom>
                </div> */}
              </div>
            )}
            {getContactUsPage_data &&
              getContactUsPage_data?.social_links?.length > 0 && (
                <div className="d-flex gap-2 mt-3">
                  <TextCom style={{ marginRight: "10px" }}>
                    {translate("follow-us", "Follow Us")} :
                  </TextCom>
                  {getContactUsPage_data?.social_links?.map(
                    (social: any, key: any) => (
                      <a href={social?.link} key={key} target="_blank">
                        <img src={social?.social_image} />
                      </a>
                    )
                  )}
                </div>
              )}
          </div>
        </div>
      </div>
    </PrimaryFooterCom>
  );
};
