import React from "react";

import { SecondaryFooterCom, TextCom } from "../../../common";
import { LangSwitch } from "../header/languageSwitch";
import { useHandleOther, useCart } from "hook";
import { Container } from "components";

type SecondaryFooterProps = {};
export const SecondaryFooter: React.FC<SecondaryFooterProps> = (props) => {
  const { translate } = useHandleOther();
  const { getPaymentList_data } = useCart();
  const current_year = document.querySelector(".current_year");

  if (current_year) {
    current_year.innerHTML = " " + new Date().getFullYear();
  }

  return (
    <SecondaryFooterCom>
      <Container className="container">
        <div className="copyright-block">
          {/* <LangSwitch selectStyle /> */}
          {getPaymentList_data && (
            <div className="payment">
              <TextCom size="lg" weight="lg" className="mb-2">
                {translate("payment-types", "Payment Types")}
              </TextCom>
              <div className="d-flex flex-wrap">
                {getPaymentList_data?.data?.map((pay: any, key: any) => (
                  <img src={pay?.image} key={key} />
                ))}
              </div>
            </div>
          )}
          <TextCom family="dm">
            Copyright © Innovix Commerce <span className="current_year"> </span>
            . All right reserved. Powered by{" "}
            <TextCom
              as="a"
              href="https://www.innovixdigital.com/"
              target="_blank"
              color="text"
              weight="lg"
            >
              Innovix Digital.
            </TextCom>
          </TextCom>
        </div>
      </Container>
    </SecondaryFooterCom>
  );
};
